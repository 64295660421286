import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import AnalyticsService from '../../../RestSevices/analyticsService';
import { toast } from 'react-toastify';
import useAuth from '../../../Redux/Actions/authActions';
import CameraService from '../../../RestSevices/cameraService';
import { MapBox } from '../../../Layouts/style';

const MapView = (props) => {
  const [analyticsList, setAnalyticsList] = useState([]);
  const [cameraList, setCameraList] = useState([]);
  const [markersList, setMarkersList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedAnalytics, setSelectedAnalytics] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [cameraInfo, setCameraInfo] = useState(null);
  const imagePath = process.env.REACT_APP_IMAGE_URL;

  const { logout } = useAuth();
  
  const handleAnalyticsChange = (event) => {
    setSelectedAnalytics(event.target.value);
  };

  const fetchData = async () => {
    try {
      const analyticsResponse = await AnalyticsService.getAllAnalyticsList();
      setAnalyticsList(analyticsResponse.data || []);
      getCameraDetails();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getCameraDetails = async () => {
    try {
      const cameraResponse =
        await CameraService.getMapCameraDetails(selectedAnalytics);
      setCameraList(cameraResponse.data.result || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const processMarkers = () => {
    if (Object.keys(cameraList).length > 0) {
      const locationArray = Object.keys(cameraList).map((location) => {
        const cameras = cameraList[location];

        return {
          position: {
            lat: parseFloat(location.split(',')[0]),
            lng: parseFloat(location.split(',')[1]),
          },
          cameras: cameras,
          hasAlert: cameras.length > 0 ? true : false,
        };
      });

      setMarkersList(locationArray);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getCameraDetails();
  }, [selectedAnalytics]);

  useEffect(() => {
    processMarkers();
    setActiveMarker(null);
    setCameraInfo(null);
  }, [cameraList]);

  const handleMarkerClick = (props, marker, e, markerData) => {
    setActiveMarker(marker);
    setCameraInfo(markerData);
  };

  const handleInfoWindowClose = () => {
    setActiveMarker(null);
  };
  console.log(markersList, 'markersList');
  return (
    <Card style={{ height: 'calc(100vh - 11.875rem)' }}>
      <CardHeader
        title={
          <Box>
            <Grid container spacing={3} justifyContent={'space-between'}>
              <Grid item lg={4} md={12}>
                <Typography variant="h4">Maps</Typography>
              </Grid>
              <Grid item lg={5} md={12}>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={12}>
                    <Select
                      sx={{ width: '100%' }}
                      value={selectedAnalytics}
                      onChange={handleAnalyticsChange}
                      displayEmpty
                    >
                      <MenuItem key={'none'} value={null}>
                        None
                      </MenuItem>
                      {analyticsList.map((analytic) => (
                        <MenuItem
                          key={analytic.hash_id}
                          value={analytic.analytic_name}
                        >
                          {analytic.analytic_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {/* Add another Select component here if needed */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        }
      />
      <CardContent
        style={{
          position: 'relative',
          height: 'calc(100% - 90px)',
          paddingBottom: '16px',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            borderRadius: '15px',
            overflow: 'hidden',
          }}
        >
          <MapBox>
            {loaded && (
              <Map
                google={props.google}
                zoom={10}
                initialCenter={
                  markersList.length > 0 ? markersList[0]?.position : {}
                }
                loaded={loaded}
              >
                {markersList.map((markerData, index) => (
                  <Marker
                    icon={{
                      url: markerData.hasAlert
                        ? `${process.env.PUBLIC_URL}/images/active-marker.png`
                        : `${process.env.PUBLIC_URL}/images/not-active-marker.png`,
                    }}
                    key={index}
                    position={markerData.position}
                    title={`Location: ${markerData.position.lat}, ${markerData.position.lng}`}
                    onClick={(props, marker, e) =>
                      markerData.hasAlert
                        ? handleMarkerClick(
                            props,
                            marker,
                            e,
                            markerData.cameras
                          )
                        : null
                    }
                  />
                ))}

                <InfoWindow
                  marker={activeMarker}
                  visible={!!activeMarker}
                  onClose={handleInfoWindowClose}
                >
                  {cameraInfo &&
                    cameraInfo.map((cameraData, index) => (
                      <div className="info_inner">
                        <h3>{cameraData?.camera_name}</h3>
                        <p>
                          Alerts in last 1 hour :{' '}
                          {cameraData.alertData?.totalCount || 0}
                        </p>
                        <img
                          src={`${imagePath}/${cameraData.alertData?.lastAlert.image}`}
                          alt=""
                        />
                      </div>
                    ))}
                </InfoWindow>
              </Map>
            )}
          </MapBox>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GoogleApiWrapper({
  // apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY, // Replace with your actual API key
  apiKey: 'AIzaSyCIoG0aS1v6spS5xyjqEvxLP5M-5iRhKUM', // Replace with your actual API key
})(MapView);
