import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Autocomplete,
  MenuItem,
  Box,
  Grid,
  Card,
  CircularProgress,
} from '@mui/material';
import AnalyticsService from '../../../RestSevices/analyticsService';
import CameraService from '../../../RestSevices/cameraService';
import AlertsService from '../../../RestSevices/alertService';
import { toast } from 'react-toastify';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import useAuth from '../../../Redux/Actions/authActions';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const commonFields = [
  'thumb_url',
  'camera_name',
  'zone_name',
  'timestamp',
  'classes',
];

const imagePath = process.env.REACT_APP_IMAGE_URL;

const ReportForm = ({}) => {
  const [analyticsList, setAnalyticsList] = useState([]);
  const [analytic, setAnalytic] = useState('');
  const [cameraSelection, setCameraSelection] = useState([]);
  const [columnsToDisplay, setColumnsToDisplay] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [camerasByAnalytics, setCamerasByAnalytics] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterDateStart, setFilterDateStart] = useState(null);
  const [filterDateEnd, setFilterDateEnd] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { logout } = useAuth();

  const fetchAnalytics = () => {
    AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setAnalyticsList(resp.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  const fetchAlerts = (exportType) => {
    setIsLoading(true);
    const search = null;
    const selectedClasses = [];
    let dateFilter = null;
    if (
      filterDateStart &&
      filterDateEnd &&
      dayjs(filterDateStart).isBefore(filterDateEnd) &&
      analytic
    ) {
      dateFilter = {
        start: filterDateStart ? new Date(filterDateStart).getTime() : null,
        end: filterDateEnd ? new Date(filterDateEnd).getTime() : null,
      };
    }

    const filters = {
      analyticsSelection: analytic,
      cameraSelection: cameraSelection,
      dateFilter: dateFilter,
    };
    if (exportType === 'pdf') {
      AlertsService.getAlertsReportsPdf({
        analyticsSelection: analytic,
        selectedClasses,
        cameraSelection,
        page: 'all',
        dateFilter: dateFilter,
        search,
        filters,
        selectedColumns,
      })
        .then((resp) => {
          // Create a Blob from the Uint8Array
          const blob = new Blob([resp], { type: 'application/pdf' });

          // Create a temporary anchor element and trigger download
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'alert-report.pdf');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching alerts:', error);
          setIsLoading(false);
        });
    } else {
      AlertsService.getAlertsReportsExcel({
        analyticsSelection: analytic,
        selectedClasses,
        cameraSelection,
        page: 'all',
        dateFilter: dateFilter,
        search,
        filters,
        selectedColumns,
      })
        .then((resp) => {
          // Create a Blob from the Uint8Array
          const blob = new Blob([resp], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          // Create a link element
          const link = document.createElement('a');

          // Create a URL for the Blob object
          const url = window.URL.createObjectURL(blob);

          // Set the link's href to the Blob URL
          link.href = url;

          // Set the download attribute with a default file name
          link.download = 'report.xlsx';

          // Append the link to the document
          document.body.appendChild(link);

          // Programmatically trigger a click on the link to start the download
          link.click();

          // Clean up by removing the link and revoking the Blob URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          setIsLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching alerts:', error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  useEffect(() => {
    if (filterDateStart > filterDateEnd) {
      toast.error('Start date cannot be greater than end date');
      setFilterDateEnd(null);
    }
  }, [filterDateEnd]);

  useEffect(() => {
    if (analytic) {
      AnalyticsService.getAlertColumnsBasedOnAnalytics(analytic)
        .then((resp) => {
          if (resp.status === 200) {
            const columns = resp.data.postFields.map(
              (column) => column.field_name
            );
            const allColumns = Array.from(
              new Set([...columns, ...commonFields])
            );
            setColumnsToDisplay(allColumns);
            setSelectedColumns(allColumns);
          }
        })
        .catch((error) => {
          console.log('error-in-getting-columns', error);
          toast.error('No columns for analytics found', {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSelectedColumns([]);
          setColumnsToDisplay([]);
        });
    }
  }, [analytic]);

  const handleAnalyticChange = (event) => {
    setAnalytic(event.target.value);
    setCameraSelection([]);
    // Get analytic id by camera name
    const analytic_id = analyticsList.find(
      (analytic) => analytic.analytic_name === event.target.value
    )?.analytic_id;
    CameraService.getCameraByAnalytics(analytic_id).then((resp) => {
      setCamerasByAnalytics(resp.data);
      const allCameras = resp.data.map((camera) => camera.camera_name);
      setCameraSelection(allCameras);
    });
  };

  const handleCameraSearchChange = (value) => {
    setCameraSelection(value);
  };

  const handleColumnChange = (event, value) => {
    setSelectedColumns(value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : null;
    setFilterDateStart(formattedDate);
    setToDate(null);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : null;
    setFilterDateEnd(formattedDate);
  };

  const handleSubmit = (event, exportType) => {
    setIsLoading(true);
    event.preventDefault();
    // setExportType(exportType);
    fetchAlerts(exportType);
  };

  const TimestampToDate = ({ timestamp }) => {
    // Convert the timestamp to milliseconds
    const date = new Date(timestamp * 1000);

    // Extract local date components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed in JavaScript
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Format hours to 12-hour format with AM/PM
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Add leading zeros to minutes and seconds if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    // Format the date string
    const formattedDate = `${day}/${month}/${year}, ${hours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

    return formattedDate;
  };

  return (
    <form>
      <TextField
        select
        label="Analytic"
        value={analytic}
        onChange={handleAnalyticChange}
        fullWidth
        margin="normal"
      >
        {analyticsList.map((analytics) => (
          <MenuItem key={analytics.analytic_id} value={analytics.analytic_name}>
            {analytics.analytic_name}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        value={cameraSelection}
        onChange={(event, value) => handleCameraSearchChange(value)}
        options={camerasByAnalytics.map((item) => item.camera_name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Camera"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
        disabled={!analytic}
      />

      <Autocomplete
        multiple
        value={selectedColumns}
        onChange={handleColumnChange}
        options={columnsToDisplay}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Columns to Display"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        )}
        disabled={!analytic}
      />

      <Box
        sx={{
          mt: '1rem',
          display: 'flex',
          gap: '1rem',
          '@media(max-width: 767px)': {
            flexDirection: 'column',
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker
              label="From Date"
              value={fromDate}
              onChange={handleFromDateChange}
              disabled={!analytic}
              disableFuture
            />
          </DemoContainer>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker
              label="To Date"
              value={toDate}
              onChange={handleToDateChange}
              minDate={fromDate ? dayjs(fromDate) : null}
              disabled={!fromDate}
              disableFuture
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>

      <Box
        sx={{
          mt: '1rem',
          display: 'flex',
          gap: '1rem',
          '@media(max-width: 767px)': {
            flexDirection: 'column',
          },
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={!analytic || columnsToDisplay.length === 0}
              onClick={(e) => handleSubmit(e, 'pdf')}
            >
              Export PDF
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={!analytic || columnsToDisplay.length === 0}
              onClick={(e) => handleSubmit(e, 'xls')}
            >
              Export XLSX
            </Button>
          </>
        )}
      </Box>
    </form>
  );
};

export default ReportForm;
