import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { AddIcon } from '../../../Icons/SVGContainer';
import {
  Card,
  CardHeader,
  Grid,
  Typography,
  Box,
  Button,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { DatatableHeadingStyle, TabButton } from '../../../Layouts/style';
import VmsThirdPartyService from '../../../RestSevices/vmsThirdPartyService';
import { Link } from 'react-router-dom';
import { EditIcon } from '../../../Icons/SVGContainer';
import { DeleteIcon } from '../../../Icons/SVGContainer';

function DeleteUserDialog({ isOpen, onClose, onDelete }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Delete User?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ color: '#fff' }}>
          Are you sure you want to delete this config?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function VmsThirdPartyConfigList() {
  const [isOpen, setIsOpen] = useState(false);
  const [vmsConfigList, setVmsConfigList] = useState([]);
  const [thirdPartyConfigList, setThirdPartyConfigList] = useState([]);
  const [configToDelete, setConfigToDelete] = useState('');
  const [vmsList, setVmsList] = useState(true);
  const [thirdPartyList, setThirdPartyList] = useState(false);

  const handleVmsList = () => {
    setVmsList(true);
    setThirdPartyList(false);
  };

  const handleThirdPartyList = () => {
    setThirdPartyList(true);
    setVmsList(false);
  };

  const navigate = useNavigate();
  const getVmsConfigList = async () => {
    const response = await VmsThirdPartyService.getVmsConfigList();
    setVmsConfigList(response?.data?.resultVms);
    setThirdPartyConfigList(response?.data?.resultThirdParty);
  };
  useEffect(() => {
    getVmsConfigList();
  }, []);

  const handleEditForm = (configId) => {
    navigate(`/vms-and-third-party-integration/edit/${configId}`);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    VmsThirdPartyService.deleteVmsConfig(configToDelete).then((resp) => {
      if (resp?.data) {
        getVmsConfigList();
        setIsOpen(false);
      }
    });
  };
  const vmsConlumns = [
    'analyticName',
    'connectionType',
    'ip',
    'port',
    'content',
    'action',
  ];
  const thirdPartyColumns = [
    'analyticName',
    'connectionType',
    'loginUrl',
    'username',
    'password',
    'authType',
    'postUrl',
    'content',
    'action',
  ];
  const vmsHeadCells = [
    {
      id: 'analyticName',
      numeric: false,
      disablePadding: true,
      label: 'Analytic Name',
    },
    {
      id: 'connectionType',
      numeric: false,
      disablePadding: true,
      label: 'Connection Type',
    },
    {
      id: 'ipAddress',
      numeric: false,
      disablePadding: true,
      label: 'IP Address',
    },
    {
      id: 'port',
      numeric: false,
      disablePadding: true,
      label: 'Port',
    },
    {
      id: 'content',
      numeric: false,
      disablePadding: true,
      label: 'Content',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];
  const thirdPartyHeadCells = [
    {
      id: 'analyticName',
      numeric: false,
      disablePadding: true,
      label: 'Analytic Name',
    },
    {
      id: 'connectionType',
      numeric: false,
      disablePadding: true,
      label: 'Connection Type',
    },
    {
      id: 'loginUrl',
      numeric: false,
      disablePadding: true,
      label: 'Login Url',
    },
    {
      id: 'username',
      numeric: false,
      disablePadding: true,
      label: 'Username',
    },
    {
      id: 'password',
      numeric: false,
      disablePadding: true,
      label: 'Password',
    },
    {
      id: 'authType',
      numeric: false,
      disablePadding: true,
      label: 'Auth Type',
    },
    {
      id: 'postUrl',
      numeric: false,
      disablePadding: true,
      label: 'Post Url',
    },
    {
      id: 'content',
      numeric: false,
      disablePadding: true,
      label: 'Content',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];

  function EnhancedTableHead(props) {
    const { headCells } = props;
    return (
      <TableHead>
        <TableRow className="table_head">
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  return (
    <>
      <Helmet>
        <title>Mirasys: VMS-ThirdParty-Configs</title>
      </Helmet>

      <Box>
        <Grid container spacing={3} justifyContent={'space-between'}>
          <Grid item md={6}>
            <Typography variant="h4">VMS & Third Party Config List</Typography>
          </Grid>
          <Grid item md={6} className="vms-add-btn">
            <Button
              component={Link}
              to="/vms-and-third-party-integration/add"
              variant="contained"
              color="primary"
            >
              <AddIcon /> Add
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: '1.5rem', mb: '2.5rem', display: 'flex' }}>
        <TabButton
          className={`${vmsList ? 'active' : ''}`}
          onClick={handleVmsList}
        >
          VMS Config List
        </TabButton>
        <TabButton
          sx={{ marginLeft: '30px' }}
          className={`${thirdPartyList ? 'active' : ''}`}
          onClick={handleThirdPartyList}
        >
          Third-Party Config List
        </TabButton>
      </Box>

      {vmsList && (
        <Box>
          {vmsConfigList.length === 0 ? (
            <Typography
              variant="h4"
              component="div"
              style={{ textAlign: 'center' }}
            >
              No vms config found
            </Typography>
          ) : (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
                className="datatable_common"
              >
                <EnhancedTableHead
                  rowCount={vmsConfigList.length}
                  headCells={vmsHeadCells}
                />
                <TableBody>
                  {vmsConfigList &&
                    vmsConfigList?.map((row) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          sx={{ cursor: 'pointer' }}
                        >
                          {vmsConlumns.map((col, index) => (
                            <TableCell
                              align={index === 0 ? 'left' : 'right'}
                              key={col}
                            >
                              {col === 'action' ? (
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <IconButton
                                    onClick={() => handleEditForm(row.id)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      setConfigToDelete(row.id);
                                      setIsOpen(true);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              ) : col === 'content' ? (
                                <Typography>{JSON.parse(row[col])}</Typography>
                              ) : (
                                <Typography>{row[col]}</Typography>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
      {thirdPartyList && (
        <Box>
          {thirdPartyConfigList.length === 0 ? (
            <Typography
              variant="h4"
              component="div"
              style={{ textAlign: 'center' }}
            >
              No third party config found
            </Typography>
          ) : (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
                className="datatable_common"
              >
                <EnhancedTableHead
                  rowCount={thirdPartyConfigList.length}
                  headCells={thirdPartyHeadCells}
                />
                <TableBody>
                  {thirdPartyConfigList &&
                    thirdPartyConfigList?.map((row) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          sx={{ cursor: 'pointer' }}
                        >
                          {thirdPartyColumns.map((col, index) => (
                            <TableCell
                              align={index === 0 ? 'left' : 'right'}
                              key={col}
                            >
                              {col === 'action' ? (
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <IconButton
                                    onClick={() => handleEditForm(row.id)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      setConfigToDelete(row.id);
                                      setIsOpen(true);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              ) : (
                                <Typography>{row[col]}</Typography>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      )}
      <DeleteUserDialog
        isOpen={isOpen}
        onClose={handleClose}
        onDelete={handleDelete}
      />
    </>
  );
}
