import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  Typography,
  MenuItem,
  Select,
  FormGroup,
  Checkbox,
  ListItemText,
  Box,
  Grid,
} from '@mui/material';
import { Button } from '../../../Layouts/style';
import CameraService from '../../../RestSevices/cameraService';
import { addCriticalAlertSettingsValidator } from '../../../Utils/validators';

const CriticalAlertSettingsForm = ({
  form,
  forms,
  index,
  analyticsList = [],
  handleSubmit,
  handleDeleteConfirm,
}) => {
  const [mappedCameraList, setMappedCameraList] = useState([]);
  console.log(form, 'form');
  const getCameraNames = (selectedIds) => {
    if (!selectedIds || selectedIds.length === 0) {
      return <em>None</em>; // Placeholder text
    }
    return selectedIds
      .map(
        (id) =>
          mappedCameraList.find((camera) => camera.id == id)?.camera_name || ' '
      )
      .join(', ');
  };

  const handleAnalyticsChange = (value) => {
    if (value) {
      CameraService.getAllMappedCameraListByAnalytics(value)
        .then((resp) => {
          setMappedCameraList(resp.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (form.analytics_id) {
      handleAnalyticsChange(form.analytics_id);
    }
  }, []);

  const checkIsDisabled = (analyticId) => {
    // Check if the analytic ID is selected in any other form
    return forms.some(
      (otherForm, otherIndex) =>
        otherIndex != index && otherForm.analytics_id == analyticId
    );
  };
  console.log(form, 'formform');
  return (
    <Formik
      initialValues={form}
      onSubmit={(values, { resetForm }) => {
        console.log(values, 'values');
        handleSubmit(values, { resetForm }, index);
      }}
      validationSchema={addCriticalAlertSettingsValidator}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, handleChange }) => (
        <Form>
          <FormGroup>
            <Typography sx={{ mb: '1rem' }}>Select Analytics</Typography>
            <Field
              as={Select}
              value={values.analytics_id}
              name="analytics_id"
              onChange={(e) => {
                handleChange(e);
                setFieldValue('camera_ids', []);
                handleAnalyticsChange(e.target.value);
              }}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {analyticsList.map(({ analytic_id, analytic_name }) => (
                <MenuItem
                  key={analytic_id}
                  value={analytic_id}
                  disabled={checkIsDisabled(analytic_id)}
                >
                  {analytic_name}
                </MenuItem>
              ))}
            </Field>
            <ErrorMessage
              name="analytics_id"
              component="div"
              style={{ color: 'red' }}
            />
          </FormGroup>

          <FormGroup>
            <Typography sx={{ mb: '1rem' }}>Select Cameras</Typography>
            <Field
              as={Select}
              multiple
              displayEmpty
              value={values.camera_ids}
              name="camera_ids"
              onChange={handleChange}
              renderValue={(selected) => getCameraNames(selected)}
            >
              <MenuItem disabled value="">
                <em>Select cameras</em>
              </MenuItem>
              {mappedCameraList.map(({ id, camera_name }) => (
                <MenuItem key={id} value={id.toString()}>
                  <Checkbox
                    checked={values.camera_ids.indexOf(id.toString()) > -1}
                  />
                  <ListItemText primary={camera_name} />
                </MenuItem>
              ))}
            </Field>
            <ErrorMessage
              name="camera_ids"
              component="div"
              style={{ color: 'red' }}
            />
          </FormGroup>

          {form.id ? (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  type="button"
                  onClick={() => handleDeleteConfirm(form.id)}
                >
                  Delete
                </Button>
              </Grid>
              <Grid item>
                <Button type="submit">Update</Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button type="submit">Create</Button>
              </Grid>
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CriticalAlertSettingsForm;
