import { Hidden, styled } from '@mui/material';
import { Dialog } from '@mui/material';
import { heIL } from '@mui/x-date-pickers';

export const AuthStyle = styled('div')(({ theme }) => ({
  a: {
    textDecoration: 'none',

    '&:hover': {
      color: `${theme.colors?.palatte?.primary} !important`,
    },
  },
  '.MuiInputBase-root': {
    minHeight: '0 !important',
    minWidth: '0 !important',
  },
  '.signup_area': {
    background: theme.colors.color.color3,
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    '.signup_left': {
      height: '100%',
      padding: '10rem',
      flex: 1,
      background: theme.colors.color.color6,
      position: 'relative',
      '.logo': {
        position: 'absolute',
        top: '1.25rem',
        left: '2.5rem',
      },
      '.signup_left_pic': {
        width: '100%',
        height: '100%',
        background: theme.colors.color.color3,
      },
    },
    '.signup_right': {
      height: '100%',
      padding: '9.375rem 8.75rem',
      flex: 1,
      overflow: 'auto',
      position: 'relative',
      '.sign_toplink': {
        position: 'absolute',
        right: '8.75rem',
        top: '3.125rem',
        '& a': {
          color: theme.colors.invertColor.color1,
          fontWeight: 600,
        },
      },
      '.sign_heading': {
        textAlign: 'center',
        paddingBottom: '5rem',
        '& h2': {
          fontSize: '1.875rem',
          fontWeight: 600,
          marginBottom: '0.4375rem',
        },
        '& p': {
          fontSize: '1.5rem',
        },
      },
      '.form_box_grid': {
        display: 'flex',
        alignItems: 'start',
        gap: '1.5625rem',
      },
      '.form_box': {
        marginBottom: '1.5625rem',
        flex: 1,
        '& label': {
          fontSize: '1.125rem',
          marginBottom: '0.375rem',
          display: 'block',
          paddingLeft: '0.3125rem',
        },
        '.MuiInputBase-root': {
          background: theme.colors.color.color6,
        },
        '& input': {
          background: theme.colors.color.color6,
          height: '2.1875rem',
          borderRadius: '0.625rem',
        },
        '.MuiSelect-select': {
          background: theme.colors.color.color6,
          height: '2.1875rem',
          borderRadius: '0.625rem',
          display: 'flex',
          alignItems: 'center',
        },
        '.MuiSelect-icon': {
          color: theme.colors.invertColor.color1,
        },
        '.check_con': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: '0.625rem',
        },
        '.checkbox_con': {
          display: 'flex',
          alignItems: 'center',
          '& label': {
            margin: 0,
          },
          '& input': {
            height: 'auto',
            marginRight: '0.3125rem',
          },
        },

        '.forget_pass': {
          display: 'flex',
          justifyContent: 'flex-end',
          paddingTop: '0.625rem',
          '& a': {
            color: theme.colors.invertColor.color1,
          },
        },
        '& button.submitBtn': {
          width: '100%',
          background: theme.colors.invertColor.color2,
          color: theme.colors.color.color18,
          position: 'relative',
          height: '3.75rem',
          borderRadius: '0.625rem',
          marginTop: '1.5625rem',
          fontSize: '1.25rem',
          '& svg': {
            position: 'absolute',
            right: '1.25rem',
            top: '1.125rem',
          },
        },
        '& button.googleBtn': {
          width: '100%',
          background: theme.colors.color.color18,
          color: theme.colors.invertColor.color1,
          position: 'relative',
          height: '3.75rem',
          borderRadius: '0.625rem',
          fontSize: '1.25rem',
          '& img': {
            marginRight: '0.625rem',
          },
          '& svg': {
            position: 'absolute',
            right: '1.25rem',
            top: '1.125rem',
          },
        },
      },
      '.form_checkbox': {
        marginTop: '1.25rem',
        '& label': {
          display: 'flex',
          alignItems: 'start',
          gap: '0.625rem',
          fontSize: '0.875rem',
          '& span': {
            padding: 0,
            '& svg': {
              width: '1.625rem',
              height: '1.625rem',
              color: theme.colors.invertColor.color1,
            },
          },
        },
      },
      '.streanth_info_area': {
        paddingTop: '0.625rem',
        '.streanth_box': {
          display: 'flex',
          alignItems: 'center',
          gap: '0.625rem',
          '& div': {
            borderRadius: '2.125rem',
            border: '0.0625rem solid rgba(255, 255, 255, 0.25)',
            background: theme.colors.color.color6,
            width: '8.75rem',
            height: '0.4375rem',
          },
        },
        '.week_pass_msg': {
          display: 'block',
          color: theme.colors.error,
          marginTop: '0.625rem',
          fontStyle: 'italic',
        },
        '.streanth_info_area': {
          '& ul': {
            padding: '0 0 0 1rem',
            margin: 0,
            '& li': {
              marginTop: '0.625rem',
              fontStyle: 'italic',
            },
          },
        },
      },
    },
  },
  '@media (max-width: 1600px)': {
    '.signup_area': {
      '.signup_right': {
        padding: '7.5rem 6.25rem',
        '.sign_toplink': {
          right: '6.25rem',
        },
        '.sign_heading': {
          paddingBottom: '3.125rem',
        },
      },
      '.signup_left': {
        padding: '7.5rem 6.25rem',
      },
    },
  },
  '@media (max-width: 1200px)': {
    '.signup_area': {
      '.signup_right': {
        padding: '7.5rem 3.75rem',
        '.form_box_grid': {
          flexDirection: 'column',
          gap: 0,
          '& div': {
            flex: 1,
            minWidth: '100%',
          },
        },
        '.sign_toplink': {
          right: '3.75rem',
        },
      },
      '.signup_left': {
        padding: '7.5rem 3.75rem',
      },
    },
  },
  '@media (max-width: 992px)': {
    '.sign_heading': {
      '& h2': {
        fontSize: '1.5rem !important',
      },
      '& p': {
        fontSize: '1.125rem !important',
      },
    },
    '.sign_toplink': {
      right: '1.875rem !important',
    },
    '.signup_area': {
      '.signup_right': {
        padding: '7.5rem 1.875rem',
      },
      '.signup_left': {
        padding: '7.5rem 3.125rem',
      },
    },
    '& button.googleBtn': {
      fontSize: '1.125rem !important',
      '& svg': {
        width: '1rem',
      },
    },
    '& button.submitBtn': {
      fontSize: '1.125rem !important',
      '& svg': {
        width: '1rem',
      },
    },
  },
  '@media (max-width: 767px)': {
    '.signup_area': {
      '.signup_right': {
        padding: '5rem 1.875rem 2.5rem',
        '.sign_heading': {
          paddingBottom: '1.875rem',
        },
      },
      '.signup_left': {
        display: 'none',
      },
    },
    '.sign_toplink': {
      top: '1.875rem !important',
    },
    '& input': {
      height: '1.5625rem !important',
    },
    '.MuiSelect-select': {
      height: '1.5625rem !important',
    },
  },
}));

export const ForgotPasswordStyle = styled('div')(({ theme }) => ({
  a: {
    textDecoration: 'none',
  },
  '.MuiInputBase-root': {
    minHeight: '0 !important',
    minWidth: '0 !important',
  },
  '.forgot_pass_area': {
    background: theme.colors.color.color3,
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    paddingTop: '3.75rem',
    paddingBottom: '1.875rem',
    overflow: 'auto',
    justifyContent: 'center',
    '.forgot_box_con': {
      width: '46.875rem',
      borderRadius: '1.5625rem',
      background: theme.colors.color.color6,
      padding: '2.5rem 4.375rem 4.375rem',
    },
    '.forgot_heading': {
      textAlign: 'center',
      paddingBottom: '2.8125rem',
      '& h2': {
        fontSize: '1.75rem',
        fontWeight: 600,
        marginBottom: '0.4375rem',
        marginTop: '2.8125rem',
      },
      '& p': {
        fontSize: '1.25rem',
      },
    },
    '.form_box': {
      marginBottom: '1.5625rem',
      flex: 1,
      '& label': {
        fontSize: '1.125rem',
        marginBottom: '0.375rem',
        display: 'block',
        paddingLeft: '0.3125rem',
      },
      '& input': {
        background: theme.colors.color.color3,
        height: '2.1875rem',
        borderRadius: '0.625rem',
      },
      '.MuiInputBase-root': {
        background: theme.colors.color.color3,
      },
      '& button.submitBtn': {
        width: '100%',
        background: theme.colors.invertColor.color1,
        color: theme.colors.color.color18,
        position: 'relative',
        height: '3.75rem',
        borderRadius: '0.625rem',
        marginTop: '0.625rem',
        fontSize: '1.25rem',
        '& svg': {
          position: 'absolute',
          right: '1.25rem',
          top: '1.125rem',
        },
      },
    },
    '.forgot_botinfo2': {
      '& p': {
        textAlign: 'center',
        fontSize: '1.375rem',
        '& a': {
          fontWeight: 700,
          color: theme.colors.invertColor.color1,
        },
      },
    },
    '.forgot_botinfo': {
      paddingTop: '1.875rem',
      '& p': {
        textAlign: 'center',
        fontSize: '1.375rem',
        '& a': {
          fontWeight: 700,
          color: theme.colors.invertColor.color1,
        },
      },
    },
  },
  '@media (max-width: 992px)': {
    '.forgot_pass_area': {
      '.forgot_box_con': {
        width: '40.625rem',
        padding: '2.5rem',
      },
      '.forgot_heading': {
        paddingBottom: '1.875rem',
        '& h2': {
          fontSize: '1.5rem',
          marginTop: '1.875rem',
        },
        '& p': {
          fontSize: '1.125rem',
        },
      },
      '.form_box': {
        marginBottom: '0.9375rem',
      },
      '.forgot_botinfo2': {
        '& p': {
          fontSize: '1.25rem',
        },
      },
      '.forgot_botinfo': {
        paddingTop: '1.25rem',
        '& p': {
          fontSize: '1.25rem',
        },
      },
      'button.submitBtn': {
        '& svg': {
          width: '1.125rem',
        },
      },
    },
  },
  '@media (max-width: 767px)': {
    '.forgot_pass_area': {
      '.forgot_box_con': {
        width: 'calc(100% - 2.5rem)',
        padding: '1.875rem',
      },
      '.form_box': {
        '& input': {
          height: '1.5625rem',
        },
        'button.submitBtn': {
          height: '3.25rem',
          '& svg': {
            top: '0.9375rem',
          },
        },
      },
    },
  },
}));

export const HeaderStyle = styled('div')(({ theme }) => ({
  '.header_area': {
    background: theme.colors.color.color1,
    padding: '1.25rem 3.75rem 1.25rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid 0.0625rem',
    borderColor: theme.colors.invertColor.color1,
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 9,
    '.header_logo': {
      width: '7.5rem',
      display: 'flex',
      justifyContent: 'center',
    },
    '.header_left': {
      display: 'flex',
      alignItems: 'center',
      '.header_date': {
        paddingLeft: '1.5625rem',
        '& h2': {
          fontSize: '1.5rem',
          fontWeight: 700,
        },
      },
    },
    '.header_right': {
      display: 'flex',
      alignItems: 'center',
      gap: '1.25rem',
      '.head_user_name': {
        display: 'flex',
        alignItems: 'center',
        gap: '0.4375rem',
        '.user_pic': {
          width: '2.8125rem',
          height: '2.8125rem',
          borderRadius: '50%',
          overflow: 'hidden',
          '& img': {
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          },
        },
        '& h4': {
          fontSize: '0.875rem',
        },
      },
      '.header_drop_icon': {
        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: theme.colors.color.color4,
      },
    },
  },
  '@media (max-width: 1300px)': {
    '.header_area': {
      padding: '1.25rem 1.875rem 1.25rem 0',
    },
  },
  '@media (max-width: 992px)': {
    '.header_left': {
      '.header_date': {
        '& h2': {
          fontSize: '1.25rem',
        },
      },
    },
    '.head_user_name': {
      '& h4': {
        display: 'none',
      },
      '& h3': {
        display: 'none',
      },
    },
    '.header_right': {
      gap: '0.75rem',
    },
  },
  '@media (max-width: 767px)': {
    '.header_logo': {
      paddingLeft: '4.375rem',
    },
    '.header_date': {
      display: 'none',
    },
    '.header_area': {
      padding: '1.25rem 1.25rem 1.25rem 0',
    },
  },
}));

export const SidebarStyle = styled('div')(({ theme }) => ({
  a: {
    textDecoration: 'none',
  },
  '.sidebar': {
    width: '7.5rem',
    background: theme.colors.color.color4,
    height: 'calc(100vh - 6.375rem)',
    overflow: 'auto',
    borderRight: 'solid 0.0625rem',
    borderColor: theme.colors.invertColor.color1,
    position: 'fixed',
    top: '6.375rem',
    left: 0,
    '& ul': {
      margin: 0,
      padding: '1.875rem 1.375rem',
      '& li': {
        display: 'block',
        textAlign: 'center',
        fontSize: '0.75rem',
        margin: '0.625rem 0',
        '& a': {
          color: theme.colors.invertColor.color1,
          '& div': {
            width: '3.125rem',
            height: '3.125rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto 0.9375rem',
            borderRadius: '0.625rem',
            '& svg': {
              filter: 'grayscale(1) invert(1)',
            },
          },
          '&.active': {
            '& div': {
              background: theme.colors.color.color12,
            },
          },
        },
        '&:hover': {
          '& a': {
            '& div': {
              background: theme.colors.color.color12,
            },
          },
        },
      },
    },
  },
  '.menu_toggle_btn': {
    display: 'none',
    zIndex: 9,
    background: theme.colors.invertColor.color1,
    top: '1.875rem',
    left: '1.25rem',
    width: '2.5rem',
    height: '2.5rem',
    position: 'fixed',
  },
  '@media (max-width: 767px)': {
    '.menu_toggle_btn': {
      display: 'block',
    },
    '.sidebar': {
      left: '-7.5rem',
      transition: 'all 500ms ease-in-out 0ms',
      zIndex: 9,
    },
    '.sidebar.sidebar_open': {
      left: '0',
      transition: 'all 500ms ease-in-out 0ms',
    },
  },
}));

export const DatatableHeadingStyle = styled('div')(({ theme }) => ({
  '.user_heading': {
    paddingBottom: '1.5625rem', // 1.5625rem converted to rem
    '& h2': {
      fontSize: '1.5rem', // 1.5rem converted to rem
      marginBottom: '1.25rem', // 1.25rem converted to rem
    },
    '.user_head_search': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '.MuiInputBase-root': {
        background: theme.colors.color.color6,
      },
      '& input': {
        height: '2.1875rem', // 2.1875rem converted to rem
        borderRadius: '0.625rem', // 0.625rem converted to rem
        padding: '0.9375rem', // 0.9375rem converted to rem
        width: '17.5rem', // 17.5rem converted to rem
      },

      '.MuiSelect-select': {
        background: theme.colors.color.color6,
        height: '1.25rem', // 1.25rem converted to rem
        borderRadius: '0.625rem', // 0.625rem converted to rem
        display: 'flex',
        alignItems: 'center',
        width: '8.75rem', // 8.75rem converted to rem
        overflow: 'hidden',
      },
      '.MuiSelect-icon': {
        color: theme.colors.invertColor.color1,
      },
      '& button': {
        background: theme.colors.color.color2,
        padding: '0.75rem 1.25rem', // 0.75rem 1.25rem converted to rem
        borderRadius: '0.625rem', // 0.625rem converted to rem
        color: theme.colors.invertColor.color1,
        fontSize: '1rem', // 1rem converted to rem
        height: '3.5rem', // 3.5rem converted to rem
        '& svg': {
          marginRight: '0.3125rem', // 0.3125rem converted to rem
        },
        '& img': {
          width: '1.125rem', // 1.125rem converted to rem
          marginRight: '0.4375rem', // 0.4375rem converted to rem
          filter: 'grayscale(1) invert(1)',
        },
      },
      '.table_head_btn_con': {
        display: 'flex',
        alignItems: 'end',
        gap: '0.625rem', // 0.625rem converted to rem
      },
    },
    '.page_search': {
      '& button': {
        padding: 0,
        background: 'none',
        position: 'absolute',
        right: '0.625rem', // 0.625rem converted to rem
      },
    },
    '@media (max-width: 1300px)': {
      // 81.25rem converted to rem
      '.user_head_search': {
        flexDirection: 'column',
        alignItems: 'start',
        gap: '1.25rem', // 1.25rem converted to rem
        '.table_head_btn_con': {
          width: '100%',
          justifyContent: 'end',
        },
        '.MuiSelect-select': {
          width: '6.25rem', // 6.25rem converted to rem
        },
        '& button': {
          padding: '0.75rem 0.625rem', // 0.75rem 0.625rem converted to rem
        },
      },
    },
    '@media (max-width: 767px)': {
      // 47.9375rem converted to rem
      '.user_head_search': {
        flexDirection: 'column',
        alignItems: 'start',
        gap: '0.9375rem', // 0.9375rem converted to rem
        '& div': {
          width: '100%',
        },
        '& input': {
          width: '100%',
        },
        '.table_head_btn_con': {
          flexWrap: 'wrap',
          gap: '0.9375rem', // 0.9375rem converted to rem
        },
      },
    },
  },
  '.object_table_head_btn_con': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '.object_table_head_left': {
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  '.business_heading': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h2': {
      margin: 0,
    },
    '& button': {
      background: theme.colors.invertColor.color1,
      padding: '0.75rem 2.1875rem', // 0.75rem 2.1875rem converted to rem
      borderRadius: '0.625rem', // 0.625rem converted to rem
      color: theme.colors.color.color2,
      fontSize: '1rem', // 1rem converted to rem
      height: '3.5rem', // 3.5rem converted to rem
      '& svg': {
        marginRight: '0.3125rem', // 0.3125rem converted to rem
      },
      '& img': {
        width: '1.125rem', // 1.125rem converted to rem
        marginRight: '0.4375rem', // 0.4375rem converted to rem
        filter: 'grayscale(1) invert(1)',
      },
    },
  },
  '.object_table_head_main': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.object_table_head_left': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.9375rem', // 0.9375rem converted to rem
    paddingBottom: '0.9375rem', // 0.9375rem converted to rem
    flexWrap: 'wrap',

    '.alert_search_classes': {
      width: '300px',

      '.MuiInputBase-root': {
        minHeight: '60px'
      },

      '.MuiFormControl-root': {
        width: '100%'
      }
    },

    '.MuiAutocomplete-root': {
      width: '300px',

      '.MuiInputBase-root': {
        minHeight: '60px'
      },

      '.MuiFormControl-root': {
        width: '100%'
      }
    },

    '.res_from_date_picker': {
      display: 'none',

      '.MuiInputBase-root': {
        minHeight: '60px'
      },
      
      '.MuiStack-root': {
        paddingTop: '0',
        overflow: 'inherit',
      }
    },

    '.res_to_date_picker': {
      display: 'none',

      '.MuiInputBase-root': {
        minHeight: '60px'
      },

      '.MuiStack-root': {
        paddingTop: '0',
        overflow: 'inherit',
      }
    },

    '.MuiInputBase-root': {
      background: theme.colors.color.color6,
    },
    '& input': {
      height: '2.1875rem', // 2.1875rem converted to rem
      padding: '0.6875rem 0.9375rem', // 0.6875rem 0.9375rem converted to rem
      width: 'calc(100% - 2rem)', // 8.75rem converted to rem
    },
    '.MuiTextField-root': {
      '& label': {
        color: theme.colors.invertColor.color1,
        width: '6.25rem', // 6.25rem converted to rem
        background: theme.colors.color.color6,
      },
    },
    '.MuiTextField-root.search': {
      width: '17.5rem', // 17.5rem converted to rem
      '& input': {
        width: '100%',
      },
    },
    '.MuiSelect-select': {
      background: theme.colors.color.color6,
      height: '1.25rem', // 1.25rem converted to rem
      borderRadius: '0.625rem', // 0.625rem converted to rem
      display: 'flex',
      alignItems: 'center',
      width: '8.75rem', // 8.75rem converted to rem
    },
    '.MuiSelect-icon': {
      color: theme.colors.invertColor.color1,
    },

    '@media (max-width: 2400px)': {
      width: '100% !important',
      gap: '0', 
      justifyContent: 'space-between',

      '.alert_search_classes, .MuiAutocomplete-root': {
        width: '32.5%',
        marginBottom: '0.5rem'
      },
    },

    '@media (max-width: 1700px)': {
      width: '100% !important',
      gap: '0', 
      justifyContent: 'space-between',
      paddingBottom: '0',

      '.alert_search_classes, .MuiAutocomplete-root': {
        width: '32%',
        marginBottom: '1.25rem'
      },

      '.res_from_date_picker, .res_to_date_picker': {
        display: 'block',
        width: '49%',
        marginBottom: '1.25rem'
      }
    },

    '@media (max-width: 1299px)': {

      '.res_from_date_picker, .res_to_date_picker': {
        width: '49%',
      }
    }, 

    '@media (max-width: 768px)': {
      '.alert_search_classes, .MuiAutocomplete-root': {
        width: '100%',
      },
      '.res_from_date_picker, .res_to_date_picker': {
        width: '100%',
      }
    }
  },
  '.object_table_head_right': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.9375rem', // 0.9375rem converted to rem
    paddingBottom: '0.9375rem', // 0.9375rem converted to rem
    
    '.search': {
      '.MuiInputBase-root': {
        minHeight: '60px'
      }
    },

    '.from_date_picker, .to_date_picker': {
       '.MuiStack-root': {
        overflow: 'inherit',
       }
    },

    '.MuiInputBase-root': {
      background: theme.colors.color.color6,
    },
    '& input': {
      height: '2.1875rem', // 2.1875rem converted to rem
      padding: '0.6875rem 0.9375rem', // 0.6875rem 0.9375rem converted to rem
      width: 'calc(100% - 2rem)', // 8.75rem converted to rem
    },
    '.MuiTextField-root': {
      '& label': {
        color: theme.colors.invertColor.color1,
        width: '6.25rem', // 6.25rem converted to rem
        background: theme.colors.color.color6,
      },
    },
    '.MuiTextField-root.search': {
      width: '17.5rem', // 17.5rem converted to rem
      '& input': {
        width: '100%',
      },
    },
    '.MuiSelect-select': {
      background: theme.colors.color.color6,
      height: '1.25rem', // 1.25rem converted to rem
      borderRadius: '0.625rem', // 0.625rem converted to rem
      display: 'flex',
      alignItems: 'center',
      width: '8.75rem', // 8.75rem converted to rem
    },
    '.MuiSelect-icon': {
      color: theme.colors.invertColor.color1,
    },

    '@media (max-width: 2400px)': {
      width: '100% !important',
      justifyContent: 'space-between',

      '.search': {
        width: '49% !important'
      },

      '.from_date_picker, .to_date_picker': {
        width: '22%'
      },
    },

    '@media (max-width: 1700px)': {
      width: '100% !important',
      justifyContent: 'space-between',

      '.from_date_picker, .to_date_picker': {
        display: 'none',
      },
      '.search': {
        width: 'calc(100% - 90px) !important'
      }
    },
  },
}));

export const UserStyle = styled('div')(({ theme }) => ({}));
export const DialogStyle = styled(Dialog)(({ theme }) => {
  return {
    '.modal-head': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      height: '4.5rem',
      padding: '1.25rem 2.1875rem', // 1.25rem 2.1875rem converted to rem
      backgroundColor: theme.colors.color.color5,

      '.global_modal_title': {
        fontSize: '1.375rem',
        fontWeight: 700,
        textTransform: 'capitalize',
      },

      '.btn_sec': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',

        '.expand_btn': {
          marginRight: '1.5rem'
        }
      },

      svg: {
        fontSize: '2rem', // 2rem converted to rem
      },
    },

    '.modal-body': {
      overflow: 'auto',
      height: 'calc(100% - 4.5rem)',
      '.body': {
        padding: '2.1875rem', // 2.1875rem converted to rem
      },
      '.dashboard_chart_con': {
        background: theme.colors.bodyBgColor,
        padding: '1.875rem 1.5625rem',
        // borderRadius: '0.9375rem',
        // flex: 1,
        '@media (max-width: 1199px)': {
          width: '100%',
          padding: '1.25rem',
        },
        '.dashboard_chart_head': {
          position: 'relative',
          '& button': {
            position: 'absolute',
            top: '-0.625rem',
            right: '0',
            '& img': {
              filter: 'grayscale(1) invert(1)',
              width: '1.75rem',
            },
          },
        },
        '& h3': {
          fontSize: '1.25rem',
          color: theme.colors.loginButtonBgColor,
          fontWeight: 600,
          marginBottom: '1.25rem',
        },
      },
      '.action': {
        padding: '1.25rem 2.1875rem', // 1.25rem 2.1875rem converted to rem
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        gap: '0.9375rem', // 0.9375rem converted to rem

        '.cancle': {
          fontSize: '2.2rem', // 2.1875rem converted to rem
          fontWeight: 500,
          letterSpacing: '0.00625rem', // 0.00625rem converted to rem

          svg: {
            fontSize: '2.2rem', // 2.1875rem converted to rem
          },

          '&:hover': {
            background: 'none',
          },
        },

        '.save': {
          fontSize: '2.2rem', // 2.1875rem converted to rem
          fontWeight: 500,
          letterSpacing: '0.00625rem', // 0.00625rem converted to rem
          padding: '0.1875rem 2.5rem', // 0.1875rem 2.5rem converted to rem
        },
      },
      '.table_head th': {
        borderBottom: `solid 0.0625rem ${theme.colors.invertColor.color3}`, // 0.0625rem converted to rem
      },
    },
    '@media (max-width: 767px)': {
      // 47.9375rem converted to rem
      '.modal-head': {
        padding: '1.25rem', // 1.25rem converted to rem
        '.global_modal_title': {
          fontSize: '1.375rem', // 1.375rem converted to rem
        },
      },
      '.form_field_area': {
        padding: '1.5625rem 1.25rem', // 1.5625rem 1.25rem converted to rem
      },
      '.add_btn': {
        padding: '1.25rem', // 1.25rem converted to rem
      },
    },
  };
});

export const ModalLayoutStyle = styled('div')(({ theme }) => ({
  '.modal_form_body': {
    'form': {
      position: 'relative',
    },
    '.form_field_area': {
      padding: '1.875rem 2.5rem',
      backgroundColor: theme.colors.modalComponentBgColor,

      '&.zone_form_area': {
        paddingBottom: '4.5rem',
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
      },

      '@media (max-width: 767px)': {
        padding: '1.5625rem 1.25rem',
      },
      '&.analytics_form': {
        maxHeight: '28.125rem',
        overflow: 'auto',
      },
      '.form_box_hold': {
        display: 'flex',
        alignItems: 'start',
        gap: '1.25rem',
        '@media (max-width: 1299px)': {
          flexDirection: 'column',
          gap: 0,
        },
        '& h3': {
          color: theme.colors.invertColor.color1,
          fontSize: '1.625rem',
          margin: '0.9375rem 0',
        },
        '.form_box_con': {
          flex: 1,
          width: '100%',
          '& div': {
            width: '100%',
          },
          '& button': {
            padding: 0,
            minWidth: 0,
            background: 'none',
          },
        },
      },

      '.MuiAutocomplete-root': {
        '.MuiInputBase-root': {
          width: '100%',
          padding: 0,
        },

        '.MuiAutocomplete-endAdornment': {
          width: 'unset !important',

          svg: {
            color: theme.colors.invertColor.color1,
          },
        },
      },
      '.MuiAlert-root': {
        minWidth: '100% !important',
        marginTop: '0.5rem',
        fontSize: '1rem!important',
        '.MuiAlert-icon': {
          fontSize: '1.5625rem',
        },
      },
    },
    '& label': {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.25rem',
      marginBottom: '0.625rem',
      display: 'block',
    },
    '& input': {
      height: '2.5rem',
      padding: '0.5rem 0.9375rem',
      fontSize: '1rem',
      borderRadius: 'inherit',
      border: 'solid 0.0625rem',
      borderColor: theme.colors.invertColor.color3,
      '&:disabled': {
        color: theme.colors.color.color17,
        '-webkitTextFillColor': theme.colors.color.color17,
      },
    },
    '.MuiInputBase-multiline': {
      padding: '0.5rem 0.9375rem',
      fontSize: '1rem',
      borderRadius: 'inherit',
      border: 'solid 0.0625rem',
      borderColor: theme.colors.invertColor.color3,
      '&:disabled': {
        color: theme.colors.color.color17,
        '-webkitTextFillColor': theme.colors.color.color17,
      },
    },
    '.MuiInputBase-root': {
      width: '100%',
    },
    '.MuiSelect-select': {
      height: '1.5625rem',
      borderRadius: '0.625rem',
      display: 'flex',
      alignItems: 'center',
      borderColor: theme.colors.invertColor.color3,
    },
    '.MuiSelect-icon': {
      color: theme.colors.invertColor.color1,
    },
    '.form_box_con': {
      marginBottom: '0.6rem',
    },
    '.add_icon': {
      color: theme.colors.invertColor.color1,
      fontSize: '1.5rem',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    '.add_btn': {
      display: 'flex',
      justifyContent: 'end',
      padding: '1.5625rem 2.5rem' /* 25px 40px */,
      backgroundColor: theme.colors.color.color5,
      gap: '0.9375rem' /* 15px */,
      '@media (max-width: 767px)': {
        padding: '1.5625rem 1.5625rem' /* 25px 25px */,
      },
      '& button': {
        width: '11.875rem' /* 190px */,
        height: '3.375rem' /* 54px */,
        fontSize: '1.125rem' /* 18px */,
        fontWeight: 600,
        background: theme.colors.palatte.white,
        color: theme.colors.color.color5,
      },
      '.cancel_btn': {
        color: theme.colors.palatte.white,
        background: theme.colors.color.color6,
      },
    },
    '.zone_btn_sec': {
      position: 'absolute',
      maxWidth: '28.125rem',
      left: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      padding: '1.5625rem 2.5rem',
      backgroundColor: theme.colors.color.color3,
      gap: '0.9375rem',
      '@media (max-width: 767px)': {
        padding: '1.5625rem 1.5625rem',
      },
      '& button': {
        width: '11.875rem',
        height: '3.375rem',
        fontSize: '1.125rem',
        fontWeight: 600,
        background: theme.colors.palatte.white,
        color: theme.colors.color.color5,
      },
      '.cancel_btn': {
        color: theme.colors.palatte.white,
        background: theme.colors.color.color6,
      },
    },
    '.camera_info_area': {
      padding: '1.875rem 2.5rem',
      '.camera_preview_area': {
        display: 'flex',
        alignItems: 'start',
        '& div': {
          flex: 1,
          '& img': {
            width: '100%',
            minHeight: '14.375rem',
            objectFit: 'cover',
            borderRadius: '0.3125rem',
          },
        },
        '.preview_info': {
          paddingLeft: '1.875rem',
        },
      },
    },
    '.camera_info': {
      marginBottom: '0.9375rem',
      '& h4': {
        color: theme.colors.lightTextColor,
        fontSize: '0.8125rem',
        fontWeight: 600,
        textTransform: 'uppercase',
        marginBottom: 0,
      },
      '& h3': {
        color: theme.colors.invertColor.color1,
        fontSize: '1.0625rem',
        wordBreak: 'break-all',
      },
    },
    '@media (max-width: 767px)': {
      '.form_box_hold': {
        flexDirection: 'column',
        gap: '0 !important',
      },
    },
  },

  '.camera-preview-body': {
    position: 'relative',

    '.camera-infos': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '16.5rem',
      height: '100%',
      opacity: '0.2',
      backgroundColor: theme.colors.color.color5,
      padding: '1rem',
      transition: 'opacity 0.3s linear',

      '&:hover': {
        opacity: '1',
      },

      '.camera_info': {
        marginTop: '1rem',
      },
    },

    '.grid-item': {
      '.canvas-parent': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        hight: '100%',
        canvas: { display: 'block !important' },
      },
    },
  },
}));

export const ChangePasswordStyle = styled('div')(({ theme }) => ({
  '.heading': {
    paddingBottom: '1.5625rem',
    '& h2': {
      fontSize: '1.5rem',
      marginBottom: '1.25rem',
    },
  },
  '.change_pass_con': {
    width: '43.75rem',
    padding: '2.5rem',
    background: theme.colors.color.color2,
    borderRadius: '1.25rem',
    margin: '0 auto',
    '.form_box': {
      marginBottom: '1.25rem',
      flex: 1,
      '& label': {
        fontSize: '1.125rem',
        marginBottom: '0.375rem',
        display: 'block',
        paddingLeft: '0.3125rem',
      },
      '.MuiInputBase-root': {
        background: theme.colors.color.color6,
      },
      '& input': {
        background: theme.colors.color.color6,
        height: '2.1875rem',
        borderRadius: '0.625rem',
      },
      '& button.submitBtn': {
        width: '100%',
        background: theme.colors.invertColor.color2,
        color: theme.colors.color.color18,
        position: 'relative',
        height: '3.75rem',
        borderRadius: '0.625rem',
        marginTop: '1.5625rem',
        fontSize: '1.25rem',
      },
    },
  },
  '@media (max-width: 1300px)': {
    '.change_pass_con': {
      width: '37.5rem',
      padding: '1.875rem',
    },
  },
  '@media (max-width: 767px)': {
    '.change_pass_con': {
      width: '100%',
      padding: '1.875rem',
    },
  },
}));

export const MainLayoutStyle = styled('div')(({ theme }) => ({
  '.main_area': {
    overflow: 'hidden',
    background: theme.colors.color.color3,
    minHeight: '100vh',
    width: '100%',
    padding: '8.125rem 2rem 0 9.375rem',
  },
  '.page_heading': {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    h3: {
      fontSize: '1.5625rem',
    },

    '.csv_export': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',

      label: {
        fontSize: '1rem',
        marginRight: '1.25rem',
      },
      '.MuiInputBase-root': {
        position: 'relative',
        width: '12.5rem',
      },
    },
  },
  '.form_field_area': {
    position: 'relative',
    marginTop: '35px',
    width: '450px',

    '&.zone_form_area': {
      width: '100%',
      maxWidth: '28.125rem',
    },

    h5: {
      position: 'relative',
      width: 'max-content',
      fontSize: '1.25rem',
      paddingBottom: '0.5rem',
      borderBottom: '0.0625rem solid',
      borderColor: theme.colors.invertColor.color4,
      marginBottom: '1.5625rem',
    },

    '.form_box_hold': {
      position: 'relative',
      marginTop: '0.9375rem',
      display: 'flex',
      flexDirection: 'column',

      '.form_box_con': {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        label: {
          position: 'relative',
          marginBottom: '0.625rem',
        },
      },

      button: {
        position: 'relative',
        width: '100%',
        background: theme.colors.invertColor.color2,
        color: theme.colors.color.color18,
        height: '3.75rem',
        borderRadius: '0.625rem',
        marginTop: '1.5625rem',
        fontSize: '1.25rem',
      },
    },
  },

  '@media (max-width: 1300px)': {
    '.main_area': {
      padding: '8.125rem 1.875rem 0 9.375rem',
    },
  },
  '@media (max-width: 767px)': {
    '.main_area': {
      padding: '8.125rem 1.25rem 0 1.25rem',
    },
  },
}));

export const PermissionStyle = styled('div')(({ theme }) => ({
  '.page_heading': {
    '& button': {
      color: theme.colors.invertColor.color1,
      fontSize: '1.375rem',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      gap: '0.9375rem',
    },
  },
  '.permission_heading': {
    display: 'flex',
    alignItems: 'center',
    background: theme.colors.color.color6,
    margin: '1.5625rem 0 0.9375rem',
    borderRadius: '0.625rem',
    '.permission_name_hold': {
      width: '14.6875rem',
      padding: '1.25rem',
      borderRight: `solid 0.0625rem ${theme.colors.invertColor.color1}`,
      '& h3': {
        fontSize: '1.25rem',
        fontWeight: '500',
      },
    },
    '.permission_name_hold_right': {
      width: 'calc(100% - 14.6875rem)',
      padding: '1.25rem',
      '& h3': {
        fontSize: '1.25rem',
        fontWeight: '500',
      },
    },
  },
  '.permission_box_con': {
    display: 'flex',
    alignItems: 'start',
    padding: '0.9375rem 0 0 0',
    position: 'relative',
    '&:last-child': {
      border: 'none',
    },
    '.permission_name': {
      width: '14.688rem',
      background: theme.colors.color.color6,
      padding: '1.25rem',
      borderRadius: '0.625rem 0 0 0.625rem',
      borderRight: `solid 0.0625rem ${theme.colors.invertColor.color1}`,
      height: 'calc(100% - 0.9375rem)',
      position: 'absolute',
      '& h3': {
        fontSize: '1.25rem',
        fontWeight: '500',
      },
    },
    '.permission_box': {
      width: 'calc(100% - 14.688rem)',
      marginLeft: '14.688rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.875rem',
      '.permission_box_con': {
        background: theme.colors.color.color6,
        padding: '1.25rem',
        '.MuiFormGroup-root': {
          margin: 0,
          padding: 0,
        },
      },
      '& h4': {
        color: ' #64748B',
        fontSize: '1.25rem',
        fontWeight: 300,
      },
      '.MuiFormGroup-root': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1.875rem 6.25rem',
        paddingTop: '0.4375rem',
        '& label': {
          display: 'flex',
          alignItems: 'center',
          gap: '0.625rem',
          margin: '0 0 0 -0.125rem',
        },
      },
    },
  },
  '.add_btn': {
    display: 'flex',
    justifyContent: 'end',
    padding: '1.5625rem 2.5rem' /* 25px 40px */,
    backgroundColor: theme.colors.color.color5,
    gap: '0.9375rem' /* 15px */,
    '@media (max-width: 767px)': {
      padding: '1.5625rem 1.5625rem' /* 25px 25px */,
    },
    '& button': {
      width: '11.875rem' /* 190px */,
      height: '3.375rem' /* 54px */,
      fontSize: '1.125rem' /* 18px */,
      fontWeight: 600,
      background: theme.colors.palatte.white,
      color: theme.colors.color.color5,
    },
    '.cancel_btn': {
      color: theme.colors.palatte.white,
      background: theme.colors.color.color6,
    },
  },
  '.add_btn': {
    display: 'flex',
    justifyContent: 'end',
    padding: '2.5rem 0',
    gap: '0.9375rem',
    '& button': {
      width: '11.875rem',
      height: '3.375rem',
      fontSize: '1.125rem',
      fontWeight: 600,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '1.125rem',
      background: theme.colors.color.color2,
    },
  },
  '@media (max-width: 992px)': {
    '.permission_name': {
      width: '15.625rem !important',
    },
    '.permission_box': {
      width: 'calc(100% - 15.625rem) !important',
      marginLeft: '15.625rem !important',
    },
    '.MuiFormGroup-root': {
      gap: '1.25rem 3.75rem !important',
    },
    '.permission_heading': {
      '.permission_name_hold': {
        width: '16.5625rem',
      },
      '.permission_name_hold_right': {
        width: 'calc(100% - 16.5625rem)',
      },
    },
  },
  '@media (max-width: 767px)': {
    '.permission_box_con': {
      flexDirection: 'column',
      '.permission_name': {
        width: '100% !important',
        borderRadius: '0.625rem',
        borderRight: 'none',
        borderBottom: `solid 0.0625rem ${theme.colors.invertColor.color1}`,
        height: 'auto',
        position: 'relative',
        marginBottom: '0.9375rem',
        '& h3': {
          fontSize: '1.125rem',
          fontWeight: '500',
        },
      },
      '.permission_box': {
        width: '100% !important',
        marginLeft: '0 !important',
        gap: '1.25rem',
        '.permission_box_con': {
          background: theme.colors.color.color6,
          padding: '1.25rem',
          '.MuiFormGroup-root': {
            margin: 0,
            padding: 0,
          },
        },
        '& h4': {
          fontSize: '1.125rem',
        },
        '.MuiFormGroup-root': {
          gap: '1.25rem !important',
        },
      },
    },
  },
  '@media (max-width: 600px)': {
    '.permission_heading': {
      flexDirection: 'column',
      '.permission_name_hold': {
        width: '100%',
        borderRight: 'none',
        borderBottom: `solid 0.0625rem ${theme.colors.invertColor.color1}`,
      },
      '.permission_name_hold_right': {
        width: '100%',
      },
    },
  },
}));

export const ApplicationStyle = styled('div')(({ theme }) => ({
  '.application_page_heading': {
    width: 'calc(100% + 3.75rem)',
    padding: '1.5625rem 1.875rem',
    background: theme.colors.color.color15,
    margin: '-1.6875rem 0 1.875rem -1.875rem',
    alignItems: 'center',
    '@media (max-width: 1300px)': {
      padding: '1.5625rem 1.875rem',
    },
    '@media (max-width: 767px)': {},
    '& h2': {
      color: theme.colors.invertColor.color1,
      fontSize: '1.375rem',
      fontWeight: 500,
    },
    '& button': {
      background: theme.colors.color.color2,
      padding: '0.75rem 1.25rem',
      borderRadius: '0.625rem',
      color: theme.colors.invertColor.color1,
      fontSize: '1rem',
      height: '3.5rem',
    },
  },
  '.apply_box_area': {
    display: 'flex',
    alignItems: 'start',
    gap: '1.875rem',
    flexWrap: 'wrap',
    width: 'calc(100% + 1.875rem)',
    '@media (max-width: 992px)': {
      gap: '1.25rem 0',
      width: '100%',
    },
    '.apply_box': {
      background: theme.colors.color.color6,
      width: 'calc(25% - 1.875rem)',
      borderRadius: '0.625rem',
      '.apply_box_heading': {
        padding: '0.9375rem 4.0625rem 0.9375rem 1.25rem',
        borderBottom: `solid 0.0625rem ${theme.colors.lightBorderColor}`,
        position: 'relative',
      },
      '.application_menu': {
        position: 'absolute',
        right: '1.875rem',
        top: '1.875rem',
      },
      '& h4': {
        color: theme.colors.lightTextColor,
        fontSize: '0.8125rem',
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      '& h3': {
        color: theme.colors.invertColor.color1,
        fontSize: '1.25rem',
        wordBreak: 'break-all',
      },
      '.apply_box_info': {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.9375rem',
        padding: '0.9375rem 1.25rem',
      },
      '@media (max-width: 992px)': {
        width: '100% !important ',
      },
    },
    '@media (max-width: 1500px)': {
      '.apply_box': {
        width: 'calc(33.33% - 1.875rem)',
      },
    },
    '@media (max-width: 1299px)': {
      '.apply_box': {
        width: 'calc(50% - 1.875rem)',
      },
    },
  },
  '.action_camera_icon': {
    '& svg': {
      height: '1.125rem',
      width: '1.125rem',
    },
    '& button.Mui-disabled': {
      opacity: '.6',
      cursor: 'copy',
    },
  },
  '.applydropbox': {
    position: 'absolute',
    top: '3.75rem',
    right: '1.875rem',
    width: '10rem',
    padding: '0.625rem',
    display: 'none',
    background: theme.colors.color.color13,
    '&.show': {
      display: 'block',
    },
    '& button': {
      width: '100%',
      border: 'none',
      padding: '0.375rem 0.9375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.colors.invertColor.color1,
      background: theme.colors.color.color13,
      fontSize: '1rem',
      '&.Mui-disabled': {
        opacity: '.7',
      },
      '&:hover': {
        background: theme.colors.color.color6,
      },
    },
  },
  '.tab_toggle_area': {
    padding: '0 1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.page_search': {
      '.MuiInputBase-root': {
        border: `solid 0.0625rem ${theme.colors.invertColor.color1}`,
      },
      '& input': {
        width: '18.75rem',
      },
    },
    '@media (max-width: 992px)': {
      flexDirection: 'column',
      alignItems: 'end',
      gap: '0.9375rem',
      '& input': {
        width: '100%',
      },
      '.page_search': {
        width: '100%',
        '& div': {
          width: '100%',
        },
      },
    },
  },
  '.page_search': {
    '& input': {
      paddingLeft: '0.9375rem',
    },
  },
}));

export const ZoneStyle = styled('perm_areadiv')(({ theme }) => ({
  '.canvas_area': {
    display: 'flex',
    alignItems: 'start',
    gap: '1.5625rem',
    '@media (max-width: 992px)': {
      flexDirection: 'column',
      gap: 0,
    },
    '.canvas_left': {
      width: '60%',
      '@media (max-width: 1199px)': {
        width: '50%',
      },
      '@media (max-width: 992px)': {
        width: '100%',
      },
      '.canvas_con': {
        width: '100%',
        // background: theme.colors.canvasBgColor,
        borderRadius: '0.9375rem',
        position: 'relative',
        height: '380px',
        overflow: 'hidden',
        '& canvas': {
          position: 'absolute',
          // width: "100%",
          // height: "100%",
          top: 0,
          left: 0,
          maxWidth: '100%',
          height: '100%',
        },
      },
      '.selected': {
        border: '2px solid #fff',
      },
      '.canvas_bot_info': {
        display: 'flex',
        alignItems: 'start',
        flexWrap: 'wrap',
        padding: '1.25rem 0',
        gap: '1.25rem',
        '.canvas_bot_info_con': {
          background: theme.colors.color.color8,
          borderRadius: '0.625rem',
          padding: '0.625rem 0.9375rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '17.1875rem',
          '.canvas_bot_info_hold': {
            display: 'flex',
            alignItems: 'center',
            gap: '0.625rem',
          },

          '.color_use': {
            background: theme.colors.canvasBgColor,
            width: '1.25rem',
            height: '1.25rem',
            borderRadius: '50%',
            '&.blue': {
              background: theme.colors.blueChartColor,
            },
          },
          '& p': {
            color: theme.colors.invertColor.color1,
            fontSize: '0.75rem',
          },
          '& button': {
            background: theme.colors.color.color1,
            borderRadius: '0.625rem',
            height: '1.875rem',
            '& svg': {
              width: '0.9375rem',
            },
          },
        },
      },
    },
    '.canvas_right': {
      width: '40%',
      '@media (max-width: 1199px)': {
        width: '50%',
      },
      '@media (max-width: 992px)': {
        width: '100%',
      },
      '.canvas_right_default': {
        background: theme.colors.canvasRightPanelBgColor,
        padding: '1.5625rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30rem',
        borderRadius: '0.9375rem',
        '&.hide': {
          display: 'none',
        },
        '.canvas_right_default_step': {
          width: '70%',
          textAlign: 'center',
          '@media (max-width: 992px)': {
            width: '85%',
          },
          '@media (max-width: 767px)': {
            width: '100%',
          },
        },
        '& h2': {
          fontSize: '1.875rem',
          color: theme.colors.invertColor.color1,
          margin: '0.625rem 0',
        },
        '& p': {
          fontSize: '1.25rem',
          color: theme.colors.invertColor.color1,
          margin: '0 0 1.875rem',
        },
        '& button': {
          fontSize: '0.9375rem',
          color: theme.colors.invertColor.color1,
          fontWeight: 600,
          background: theme.colors.color.color18,
          gap: '0.9375rem',
          borderRadius: '0.625rem',
          padding: '0.9375rem 1.875rem',
        },
      },
      '.canvas_right_form': {
        display: 'none',
        '&.show': {
          display: 'block',
        },
        '.canvas_right_form_con': {
          background: theme.colors.canvasRightPanelBgColor,
          padding: '0.9375rem 0',
          borderRadius: '0.9375rem',
        },
        '& input': {
          background: theme.colors.color.color10,
          border: `solid 0.0625rem ${theme.colors.color.color13}`,
        },
        '.MuiSelect-outlined': {
          background: theme.colors.color.color10,
          border: `solid 0.0625rem ${theme.colors.color.color13}`,
        },
        '.add_btn': {
          paddingTop: 0,
          background: 'none',
        },
        '& input[type=color]': {
          width: '6.25rem',
          padding: '0.25rem',
          borderRadius: '0.3125rem',
        },
        '.color_area': {
          width: '100%',
          gap: '1.5625rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '@media (max-width: 1499px)': {
            alignItems: 'start',
            flexDirection: 'column',
          },
          '.color_field_area': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1.25rem',
            '@media (max-width: 767px)': {
              gap: '0.625rem',
            },
          },
          '.color_field': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '1.25rem',
            '@media (max-width: 767px)': {
              gap: '0.625rem',
            },
            '.color_name': {
              '.MuiInputBase-root': {
                minWidth: '5.625rem',
              },
            },
            '.MuiInputBase-root': {
              width: 'auto',
              border: 'none !important',
              '.MuiSelect-select': {
                background: 'none',
                border: 'none !important',
                padding: 0,
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
              },
            },
            '.color_input': {
              width: '100%',
              background: theme.colors.color.color10,
              border: `solid 0.0625rem ${theme.colors.color.color13}`,
              borderRadius: '0.625rem',
              height: '3.4375rem',
              display: 'flex',
              alignItems: 'center',
              '& input': {
                flex: 1,
                border: 'none',
                padding: '0 0.625rem',
                textAlign: 'center',
                borderRight: `solid 0.0625rem ${theme.colors.invertColor.color1}`,
                borderRadius: 0,
              },
              '& > div:last-child': {
                '& input': {
                  border: 'none !important',
                },
              },
            },
          },
        },
      },
      '.canvas_right_bot': {
        padding: '2.5rem 0',
        '& p': {
          color: theme.colors.invertColor.color1,
          fontSize: '1rem',
          marginBottom: '0.625rem',
        },
        '.canvas_btn_con': {
          display: 'flex',
          alignItems: 'center',
          gap: '1.25rem',
          '@media (max-width: 767px)': {
            gap: '0.625rem',
          },
          '& div': {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '0.5rem',
            overflow: 'hidden',
            '& button': {
              borderRadius: 0,
              '@media (max-width: 1399px)': {
                '& img': {
                  width: '1.875rem',
                },
              },
              '@media (max-width: 767px)': {
                minWidth: '1.5625rem',
                '& img': {
                  width: '1.25rem',
                },
              },
            },
          },
          '& button': {
            background: theme.colors.invertColor.color1,
            padding: '0.5rem 0.625rem',
            display: 'flex',
            alignItems: 'center',
            gap: '0.4375rem',
            borderRadius: '0.5rem',
            border: 'none',
            height: '3.75rem',
            color: theme.colors.color.color18,
            minWidth: 0,
            lineHeight: '1.25rem',
            '@media (max-width: 1399px)': {
              '& img': {
                width: '3.125rem',
              },
            },
            '&:hover': {
              background: theme.colors.canvasBgColor,
              color: theme.colors.invertColor.color1,
              '& img': {
                filter: 'brightness(2)',
              },
            },
            '&.reset_btn': {
              width: '8.125rem',
            },
            '&.Mui-disabled': {
              opacity: '.7',
            },
          },
          '.active': {
            background: theme.colors.canvasBgColor,
            color: theme.colors.loginButtonBgColor,
            '& img': {
              filter: 'brightness(2)',
            },
          },
        },
      },
    },
  },
}));

export const VideoUploadStyle = styled('div')(({ theme }) => ({
  '.video_upload_area': {
    display: 'flex',
    alignItems: 'start',
    marginTop: '-1.875rem',
    marginLeft: '-1.875rem',
    width: 'calc(100% + 5.625rem)',
    overflow: 'hidden',
    position: 'relative',
    height: 'calc(100vh - 6.25rem)',
    '@media (max-width: 767px)': {
      width: 'calc(100% + 3.125rem)',
      height: 'calc(100vh - 5.0625rem)',
    },
    '.video_upload_form': {
      minWidth: 0,
      height: '100%',
      overflow: 'auto',
      padding: '1.875rem',
      width: '45%',
      background: theme.colors.color.color3,
      position: 'absolute',
      left: '-45%',
      top: 0,
      transition: 'all 500ms ease-in-out 0ms',
      '@media (max-width: 992px)': {
        width: '60%',
        left: '-60%',
      },
      '@media (max-width: 767px)': {
        width: '100%',
        left: '-100%',
        padding: '1.25rem 1.25rem 1.25rem 1.875rem',
      },
      '&.show': {
        left: 0,
        transition: 'all 500ms ease-in-out 0ms',
      },
      '.video_upload_form_con': {
        borderRadius: '0.9375rem',
        background: theme.colors.color.color6,
        '& h3': {
          fontSize: '1.0625rem',
          fontWeight: 600,
          marginBottom: '1.25rem',
        },
        '.custom_upload': {
          background: theme.colors.canvasRightPanelBgColor,
          height: '15.625rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '1.25rem',
          cursor: 'pointer',
          '& label': {
            cursor: 'pointer',
          },
          '.MuiTextField-root': {
            display: 'none',
          },
          '.custom_upload_info': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '1.25rem',
            padding: '0.625rem',
            textAlign: 'center',
            '& p': {
              fontSize: '0.875rem',
            },
          },
        },
        '& input': {
          border: `solid 0.125rem ${theme.colors.canvasRightPanelBgColor}`,
        },
        '.MuiInputBase-multiline': {
          border: `solid 0.125rem ${theme.colors.canvasRightPanelBgColor}`,
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '.add_btn': {
          background: 'none',
          paddingTop: 0,
          paddingBottom: '3.75rem',
          button: {
            background: theme.colors.color.color18,
            color: theme.colors.invertColor.color1,
            '&.cancel_btn': {
              color: theme.colors.palatte.white,
              background: theme.colors.canvasRightPanelBgColor,
            },
          },
        },
      },
    },
    '.video_upload_list': {
      width: '100%',
      background: theme.colors.color.color6,
      height: '100%',
      overflow: 'auto',
      padding: '1.875rem 3.125rem 1.875rem 1.5625rem',
      transition: 'all 500ms ease-in-out 0ms',
      '@media (max-width: 1300px)': {
        padding: '1.875rem 1.875rem 1.875rem 1.5625rem',
      },
      '@media (max-width: 767px)': {
        padding: '1.875rem 1.25rem 1.875rem 1.875rem',
      },
      '&.small': {
        paddingLeft: 'calc(45% + 1.25rem)',
        transition: 'all 500ms ease-in-out 0ms',
        '@media (max-width: 992px)': {
          paddingLeft: 'calc(60% + 1.25rem)',
        },
        '@media (max-width: 767px)': {
          paddingLeft: 0,
        },
      },
      '.video_upload_btn': {
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: '0.9375rem',
        '&.hide': {
          display: 'none',
        },
        button: {
          background: theme.colors.color.color18,
          color: theme.colors.invertColor.color1,
        },
      },
      '& th': {
        background: theme.colors.canvasRightPanelBgColor,
      },
      '& td': {
        background: theme.colors.canvasRightPanelBgColor,
      },
    },
  },
}));
export const DashboardStyle = styled('div')(({ theme }) => ({
  '.dashboard_area': {
    display: 'flex',
    alignItems: 'start',
    gap: '1.25rem',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      paddingBottom: '1.875rem',
    },
    '.dashboard_left': {
      background: theme.colors.color.color6,
      padding: '1.875rem 1.5625rem',
      borderRadius: '0.9375rem',
      width: 'calc(100% - 15rem)',
      height: 'calc(100vh - 9.375rem)',
      overflow: 'auto',
      '@media (max-width: 767px)': {
        width: '100%',
        height: 'auto',
        padding: '0.9375rem',
      },
      '.dashboard_heading': {
        paddingBottom: '1.5625rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.625rem',
        '@media (max-width: 1100px)': {
          flexDirection: 'column',
          alignItems: 'start',
          width: '100%',
          '.MuiInputBase-root': {
            width: '100%',
          },
        },
        '.dash_select_area': {
          display: 'flex',
          alignItems: 'center',
          gap: '0.9375rem',

          '.MuiInputBase-root': {
            width: '12rem',
          },

          '@media (max-width: 1100px)': {
            width: '100%',
          },
          '@media (max-width: 940px)': {
            flexDirection: 'column',
            position: 'relative',
            '& button': {
              position: 'absolute',
              top: '-3.4375rem',
              right: '-0.3125rem',
            },
          },
          '& img': {
            width: '2.125rem',
            filter: 'grayscale(1) invert(1)',
            marginLeft: '-0.625rem',
          },
        },
        '& h2': {
          fontSize: '1.5rem',
        },
        '.MuiSelect-select': {
          background: theme.colors.color.color6,
          height: '1.25rem',
          borderRadius: '0.625rem',
          display: 'flex',
          alignItems: 'center',
          paddingRight: 0,
          overflow: 'hidden',
          '@media (max-width: 900px)': {
            width: '100%',
          },
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.lightBorderColor,
        },
        '.MuiSelect-icon': {
          color: theme.colors.invertColor.color1,
        },
      },
      '.dashboard_top_info': {
        display: 'flex',
        alignItems: 'center',
        gap: '1.25rem',
        flexWrap: 'wrap',
        '@media (max-width: 1199px)': {
          gap: '0.9375rem',
        },
        '@media (max-width: 992px)': {
          flexDirection: 'column',
        },
        '.dashboard_top_info_box': {
          background: theme.colors.color.color3,
          padding: '1.25rem',
          borderRadius: '0.625rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '0.9375rem',
          width: 'calc(33.33% - 0.9375rem)',
          '@media (max-width: 1500px)': {
            height: '8.75rem',
          },
          '@media (max-width: 1199px)': {
            flexDirection: 'column',
            alignItems: 'center',
            height: '14.688rem',
            '& div': {
              textAlign: 'center',
            },
          },
          '@media (max-width: 992px)': {
            flexDirection: 'row',
            alignItems: 'center',
            height: '8.75rem',
            width: '100%',
            '& div': {
              textAlign: 'left',
            },
          },
          '& p': {
            fontSize: '1.125rem',
            color: theme.colors.invertColor.color1,
            '@media (max-width: 1650px)': {
              fontSize: '1rem',
            },
            '@media (max-width: 1199px)': {
              lineHeight: '1.25rem',
            },
          },
          '& h2': {
            fontSize: '2.5rem',
            color: theme.colors.invertColor.color1,
            fontWeight: 700,
            marginTop: '0.3125rem',
            '@media (max-width: 1650px)': {
              fontSize: '2.125rem',
              marginTop: 0,
            },
            '@media (max-width: 1199px)': {
              fontSize: '1.75rem',
              marginTop: 0,
            },
          },
          '.dashboard_top_info_icon': {
            width: '4.375rem',
            height: '4.375rem',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.colors.color.color6,
            '@media (max-width: 1650px)': {
              width: '3.75rem',
              height: '3.75rem',
              minWidth: '3.75rem',
            },
            '@media (max-width: 1199px)': {
              minHeight: '3.75rem',
            },
          },
        },
      },
      '.dashboard_chart_area': {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'space-between',
        // gap: '1.25rem',
        paddingTop: '1.25rem',
        // '@media (max-width: 1199px)': {
        //   flexDirection: 'column',
        // },
        '.dashboard_chart_con': {
          background: theme.colors.color.color3,
          padding: '1.875rem 1.5625rem',
          borderRadius: '0.9375rem',
          flex: 1,
          // flex: 1,
          '@media (max-width: 1199px)': {
            width: '100%',
            padding: '1.25rem',
          },
          '.dashboard_chart_head': {
            position: 'relative',
            '.dashboard_chart_head_btn': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: '0.625rem',
              '@media (max-width: 1199px)': {
                width: '100%',
              },
            },
            '& button': {
              background: '#364030',
              width: '2.25rem',
              height: '2.25rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '& img': {
                filter: 'grayscale(1) invert(1)',
                width: '1.25rem',
              },
              '& svg': {
                filter: 'grayscale(1) invert(1)',
              },
            },
          },
          '& h3': {
            fontSize: '1.25rem',
            color: theme.colors.invertColor.color1,
            fontWeight: 600,
            marginBottom: '1.25rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '0.9375rem',
            '@media (max-width: 1199px)': {
              flexDirection: 'column',
              alignItems: 'start',
            },
          },
        },
      },
      '.chart_con': {
        '&.hide': {
          display: 'none',
        },
      },
      '.dashboard_camera_pic': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1.25rem',
        paddingTop: '1.875rem',
        '@media (max-width: 1199px)': {
          flexDirection: 'column',
          gap: '0.9375rem',
        },
        '& > div': {
          background: theme.colors.color.color3,
          padding: '1.875rem 1.5625rem',
          borderRadius: '0.9375rem',
          flex: 1,
          '@media (max-width: 1199px)': {
            padding: '1.25rem',
          },
          '& h3': {
            fontSize: '1.25rem',
            color: theme.colors.invertColor.color1,
            fontWeight: 600,
            marginBottom: '1.25rem',
          },
          '& img': {
            width: '100%',
          },
        },
      },
    },
    '.dashboard_right': {
      background: theme.colors.color.color6,
      padding: '1.875rem 1.5625rem',
      borderRadius: '0.9375rem',
      width: '15rem',
      height: 'calc(100vh - 9.375rem)',
      overflow: 'auto',
      '@media (max-width: 767px)': {
        width: '100%',
        height: 'auto',
        padding: '0.9375rem',
      },
      '& h3': {
        fontSize: '1.25rem',
        color: theme.colors.invertColor.color1,
        fontWeight: 600,
        marginBottom: '1.25rem',
        '@media (max-width: 767px)': {
          marginBottom: '0.9375rem',
        },
      },
      '& ul': {
        padding: '0 0 0 1.25rem',
        margin: 0,
        '& li': {
          fontSize: '1.125rem',
          color: theme.colors.invertColor.color1,
          fontWeight: 600,
          marginBottom: '1.25rem',
          position: 'relative',
          listStyle: 'disc',
          '@media (max-width: 767px)': {
            marginBottom: '0.625rem',
          },
        },
      },
      '.critical_alert_li': {
        cursor: 'pointer',
      }
    },
  },
}));

export const DashboardForm = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 'calc(100vh - 9.375rem)',
  background: theme.colors.color.color6,
  borderRadius: '0.9375rem',
  padding: '1.875rem 3.125rem',
  paddingRight: '0',

  '.inner-content': {
    position: 'relative',
    width: '100%',
    height: '100%',
    paddingRight: '3.125rem',
    overflow: 'auto',

    '.select-top': {
      position: 'relative',
      width: '100%',
      padding: '0rem 6.25rem',
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        fontSize: '1.125rem',
        paddingLeft: '0.625rem',
        color: theme.colors.invertColor.color1,

        '@media (max-width: 1460px)': {
          paddingLeft: '2.5rem',
        },

        '@media (max-width: 991px)': {
          paddingLeft: '1.875rem',
        },
      },

      '.select-box': {
        position: 'relative',
        width: '28.125rem',
        height: '3.125rem',
        margin: '0',
        marginTop: '0.625rem',

        '.MuiInputBase-root': {
          borderRadius: '0.5625rem',
          border: '0.125rem solid',
          borderColor: theme.colors.color.color11,
          background: theme.colors.color.color14,

          svg: {
            color: theme.colors.invertColor.color1,
          },
        },

        '.css-nu8v1e-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline':
          {
            borderColor: theme.colors.color.color11,
          },

        '@media (max-width: 1460px)': {
          width: '50%',
          padding: '0rem 1.25rem',
        },

        '@media (max-width: 991px)': {
          padding: '0rem 0.9375rem',
        },

        '@media (max-width: 840px)': {
          width: '100%',
        },
      },

      '@media (max-width: 1460px)': {
        padding: '0rem 3.75rem',
      },

      '@media (max-width: 1199px)': {
        padding: '0rem 1.875rem',
      },

      '@media (max-width: 991px)': {
        padding: '0rem 1.25rem',
      },

      '@media (max-width: 499px)': {
        padding: '0rem 0rem',
      },
    },

    '.add-form': {
      position: 'relative',
      width: '100%',
      marginTop: '1.5625rem',
      marginBottom: '1.875rem',
      display: 'flex',
      flexDirection: 'column',

      '& p': {
        position: 'relative',
        padding: '0rem 6.5625rem',
        fontSize: '1.3125rem',

        '&.Mui-error': {
          fontSize: '0.9rem',
          padding: '0 1rem'
        },

        '@media (max-width: 1460px)': {
          padding: '0rem 5.3125rem',
        },

        '@media (max-width: 1199px)': {
          padding: '0rem 3.75rem',
        },

        '@media (max-width: 991px)': {
          padding: '0rem 2.8125rem',
          fontSize: '1.1875rem',
        },

        '@media (max-width: 499px)': {
          padding: '0rem 1.25rem',
          fontSize: '1.125rem',
        },
      },
    },

    '@media (max-width: 499px)': {
      paddingRight: '1.5625rem',
    },
  },

  '@media (max-width: 499px)': {
    padding: '1.875rem 1.5625rem',
    paddingRight: '0',
  },
}));

export const AddForm = styled('form')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  marginTop: '0.625rem',
  padding: '1.875rem 5.25rem',
  background: theme.colors.color.color3,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',

  '.css-1nraur4': {
    width: '49.5%'
  },

  '@media (max-width: 1460px)': {
    padding: '1.5625rem 3.75rem',
  },
  '@media (max-width: 1199px)': {
    padding: '1.5625rem 1.875rem',
  },
  '@media (max-width: 991px)': {
    padding: '1.5625rem 1.25rem',
  },
  '.action': {
    display: 'flex',
    width: '100%',
    gap: '1.25rem',
    justifyContent: 'flex-end',
  },

  '.form_btn_sec': {
    width: '100%',
    margin: '0',
    marginTop: '1rem',
    padding: '0 1rem',

    '@media (max-width: 575px)': {
      '.MuiGrid-item': {
        width: '100%',
        paddingLeft: '0',

        'button': {
          width: '100%',
        }
      }
    },

  }
}));

export const InputBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '50%',
  margin: '0.625rem 0',
  padding: '0 1rem',
  display: 'flex',
  flexDirection: 'column',

  ':nth-of-type(even)': {
    marginRight: '0rem',
  },

  '& span': {
    position: 'relative',
    paddingLeft: '0.9375rem',
    fontSize: '1.125rem',
    color: theme.colors.invertColor.color1,
    marginBottom: '0.625rem',
  },

  '.input': {
    position: 'relative',
    width: '100%',
    height: '3.125rem',
    border: '0.125rem solid',
    borderColor: theme.colors.color.color14,
    background: theme.colors.color.color14,
    borderRadius: '0.5rem',
  },


  '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '@media (max-width: 1460px)': {
    width: '50%',
    padding: '0.625rem 1.25rem',
    margin: '0rem',
  },

  '@media (max-width: 991px)': {
    padding: '0.625rem 0.9375rem',
  },

  '@media (max-width: 840px)': {
    width: '100%',
  },

  '@media (max-width: 499px)': {
    padding: '0.625rem 0rem',
  },
}));

export const ButtonBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '59.375rem',
  marginTop: '0.625rem',
  marginBottom: '1.25rem',
  display: 'flex',
  justifyContent: 'flex-end',

  '@media (max-width: 1460px)': {
    width: '100%',
    padding: '0rem 1.5625rem',
  },
  '@media (max-width: 991px)': {
    width: '100%',
    padding: '0rem 0.9375rem',
  },
  '@media (max-width: 840px)': {
    marginTop: '1.5625rem',
  },
  '@media (max-width: 499px)': {
    padding: '0rem',
  },
}));
export const Button = styled('button')(({ theme }) => ({
  position: 'relative',
  width: '12.5rem',
  height: '3.125rem',
  borderRadius: '0.5rem',
  fontSize: '1rem',
  color: theme.colors.color.color18,
  background: theme.colors.invertColor.color1,
  cursor: 'pointer',
  border: 'none',

  '@media (max-width: 499px)': {
    width: '100%',
  },
}));

export const TabButton = styled('button')(({ theme }) => ({
  position: 'relative',
  fontFamily: `'Mulish',sans-serif`,
  padding: '0.75rem 1.875rem',
  background: 'transparent',
  fontSize: '1rem',
  color: theme.colors.invertColor.color1,
  border: 'none',
  // borderBottom: '0.1875rem solid #03A415',
  cursor: 'pointer',

  '&.active': {
    borderBottom: '0.1875rem solid #03A415',
  },
}));

export const CsvUploadPage = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const CsvFormBox = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: '3.125rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  '.MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },

  'input[type="file"]': {
    display: 'none',
  },
}));

export const FieldSelect = styled('div')(({ theme }) => ({
  position: 'relative',
  width: 'max-content',
  marginBottom: '1.5625rem',

  'label.Mui-focused': {
    padding: '0rem 0.5rem',
    fontSize: '1.0625rem',
    backgroundColor: '#252C20 !important',
    color: theme.colors.invertColor.color1,
  },

  '.MuiInputBase-root': {
    position: 'relative',
    width: '28.125rem',
    border: 'solid 0.0625rem',
    borderColor: theme.colors.invertColor.color3,

    'input[type="text"]': {
      position: 'relative',
    },
  },
}));

export const FieldBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: 'max-content',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',

  button: {
    position: 'relative',
    marginLeft: '1.5625rem',
    width: '11.875rem',
    height: '3.5rem',
    fontSize: '1.125rem',
    color: theme.colors.invertColor.color1,
    background: theme.colors.color.color18,
    cursor: 'pointer',
    border: 'none',
    borderRadius: '0.25rem',
    fontWeight: '600',

    '&:hover': {
      color: theme.colors.invertColor.color1,
      background: theme.colors.color.color18,
    },
  },
}));

export const FieldInput = styled('div')(({ theme }) => ({
  position: 'relative',
  width: 'max-content',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',

  'input[type="text"]': {
    position: 'relative',
    width: '28.125rem',
    height: '3.5rem',
    padding: '0.5rem 0.9375rem',
    paddingRight: '8rem',
    fontSize: '1rem',
    borderRadius: '0.5rem',
    border: 'solid 0.0625rem',
    borderColor: theme.colors.invertColor.color3,
    boxSizing: 'border-box',
    '&:disabled': {
      color: theme.colors.color.color17,
      '-webkitTextFillColor': theme.colors.color.color17,
    },
  },

  button: {
    position: 'absolute',
    top: '0.53125rem',
    right: '0.625rem',
    marginLeft: '1.5625rem',
    width: '7rem',
    height: '2.5rem',
    fontSize: '1.125rem',
    color: theme.colors.color.color18,
    background: theme.colors.invertColor.color1,
    cursor: 'pointer',
    border: 'none',
    borderRadius: '0.25rem',
    fontWeight: '600',

    '&:hover': {
      color: theme.colors.color.color18,
      background: theme.colors.invertColor.color1,
    },
  },
}));

export const MapBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  '.gm-style-iw': {
    backgroundColor: '#252C20',
    padding: '1.25rem 1.875rem !important',
    paddingTop: '0.375rem !important',
    paddingRight: '0.9375rem !important',

    '.gm-style-iw-d': {
      marginTop: '1.5625rem !important',
      paddingRight: '0.75rem !important',
      overflowX: 'hidden !important',
      overflowY: 'auto !important',
      scrollbarWidth: 'thin !important',
      scrollbarColor: '#aeaeae transparent !important',
    },

    '.info_inner': {
      position: 'relative',
      width: '20rem',
      marginBottom: '0.75rem',

      h3: {
        position: 'relative',
        margin: '0',
        marginBottom: '0.5rem',
        fontSize: '1.0625rem',
        color: theme.colors.invertColor.color1,
      },

      p: {
        position: 'relative',
        margin: '0',
        fontSize: '0.875rem',
        letterSpacing: '0.04125rem',
        color: theme.colors.invertColor.color2,
      },

      img: {
        position: 'relative',
        marginTop: '0.9375rem',
        width: 'calc(100% - 1rem)',
        height: 'auto',
      },
    },

    button: {
      top: '0.25rem !important',
      right: '0.3125rem !important',

      span: {
        backgroundColor: theme.colors.invertColor.color1,
      },
    },
  },

  '.gm-style-iw-tc': {
    '&::after': {
      background: '#252C20 !important',
    },
  },

  '.gm-style-iw-d::-webkit-scrollbar': {
    width: '1.2rem',
  },

  '.gm-style-iw-d::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },

  '.gm-style-iw-d::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '1rem',
  },

  '.gm-style-iw-d::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },

  '.gm-style-iw-d': {
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #f1f1f1',
  },
}));
