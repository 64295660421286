import axios from '../Utils/api';

const AnalyticsService = {
  getAnalyticsList: (page, search, sort) => {
    return axios.get(
      `/analytics/get-analytics?page=${page}&search=${search}&sort=${sort}`,
      {
        headers: {
          globalcheck: 'local',
        },
      }
    );
  },

  getAllAnalyticsList: (globalcheck = 'local') => {
    return axios.get('/analytics/get-analytics-list', {
      headers: {
        globalcheck: globalcheck,
      },
    });
  },

  getAnalyticsPostFields: (analytic_id) => {
    return axios.get(`/analytics/get-analytics-post-fields/${analytic_id}`);
  },

  checkAnalyticsViolation: (analytic_id) => {
    return axios.get(`/analytics/check-analytics-violation/${analytic_id}`);
  },

  getAnalyticsBelongsToVS: () => {
    return axios.get('/analytics/get-analytics-list-belongs-to-vs');
  },

  getAlertColumnsBasedOnAnalytics: (analytic_name) => {
    return axios.get(
      `/analytics/get-alert-columns-based-on-analytics/${analytic_name}`
    );
  },

  updateAnalytics: (hashid, analyticData) => {
    return axios.put('/analytics/update-analytic', analyticData, {
      headers: {
        analytichashid: hashid,
      },
    });
  },

  deleteAnalytics: (hashid) => {
    return axios.post(
      '/analytics/delete-analytic',
      {},
      {
        headers: {
          analytichashid: hashid,
        },
      }
    );
  },
};

export default AnalyticsService;
