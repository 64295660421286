const calculateAverage = (count, range) => {
  switch (range) {
    case '24hours':
      return count / 24;
    case 'week':
    case 'last7days':
      return count / 7;
    case 'month':
    case 'last30days':
      return count / 30;
    case 'lastYear':
    case 'thisYear':
      return count / 12;
    default:
      return count;
  }
};

export const  formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  let dateString = date.toLocaleString('en-IN', options);

  // Manually convert the AM/PM part to uppercase
  dateString = dateString.replace(/(am|pm)$/, (match) => match.toUpperCase());

  return dateString;
}


const formatData = (name, data, type, statsType) => ({
  name,
  data,
  type,
  statsType,
});

export const formatNochart = (settings, alertsList, range) => {
  let alerts =
    settings.parameter !== 'none'
      ? alertsList.filter((alert) =>
          alert.classes.split(',').includes(settings.parameter)
        )
      : alertsList;

  const { title } = settings.statsType;

  if (title === 'Total no') {
    const data =
      alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k';
    return formatData(settings.name, data, 'no_chart', title);
  }

  if (title === 'Average') {
    let count = alerts.length;
    if (count > 0) {
      count = calculateAverage(count, range);
    }
    return formatData(settings.name, Math.round(count), 'no_chart', title);
  }

  if (title === 'Total no per hour') {
    let count = alerts.length;
    if (count > 0) {
      if (range === '24hours') {
        count /= 24;
      } else if (range === 'week') {
        count /= 7;
      } else if (range === 'month') {
        count /= 30;
      }
    }
    return formatData(settings.name, count, 'no_chart', title);
  }
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const dateRange = [
  {
    label: 'Last 24 hours',
    value: '24hours',
  },
  {
    label: 'Last 7 days',
    value: 'last7days',
  },
  {
    label: 'Last 30 days',
    value: 'last30days',
  },
  {
    label: 'This Week',
    value: 'week',
  },
  {
    label: 'This Month',
    value: 'month',
  },
  {
    label: 'Last One Year',
    value: 'lastYear',
  },
  {
    label: 'This Year',
    value: 'thisYear',
  },
];

export const formatBarChart = (settings, alertsList, range) => {
  let alerts = alertsList;
  if (settings.parameter !== 'none') {
    alerts = alertsList.filter((alert) => {
      return alert.classes.split(',').includes(settings.parameter);
    });
  }
  const x_axisObject = constructXaxis(range);

  const y_axises = constructYaxis(settings, alerts, x_axisObject, range);
  return y_axises;
};

export const formatLineChart = (settings, alertsList, range) => {
  let alerts = alertsList;
  if (settings.parameter !== 'none') {
    alerts = alertsList.filter((alert) => {
      return alert.classes.split(',').includes(settings.parameter);
    });
  }
  const x_axisObject = constructXaxis(range);

  const y_axises = constructLineYaxis(settings, alerts, x_axisObject, range);
  return y_axises;
};

export const formatPieChart = (settings, alertsList, range) => {
  let alerts = alertsList;
  if (settings.parameter !== 'none') {
    alerts = alertsList.filter((alert) => {
      return alert.classes.split(',').includes(settings.parameter);
    });
  }
  const data = constructPieChartData(settings, alerts, range);
  return data;
};

const getDate = (baseDate, offset, unit) => {
  const date = new Date(baseDate.getTime());
  if (unit === 'hours') {
    date.setHours(date.getHours() + offset);
  } else if (unit === 'days') {
    date.setDate(date.getDate() + offset);
  } else if (unit === 'months') {
    date.setMonth(date.getMonth() + offset);
  }
  return date;
};

const formatDate = (date, format) => {
  if (format === 'hour') {
    return date.toLocaleString('en-US', { hour: 'numeric', hour12: true });
  } else if (format === 'day') {
    return `${date.getDate()}/${date.getMonth() + 1}`;
  } else if (format === 'month') {
    return `${date.toLocaleString('default', {
      month: 'long',
    })} ${date.getFullYear()}`;
  }
};

const createXAxis = (start, end, unit, format) => {
  const xAxis = [];
  for (
    let time = start.getTime();
    time <= end.getTime();
    time += unit === 'hours' ? 60 * 60 * 1000 : 24 * 60 * 60 * 1000
  ) {
    const date = new Date(time);
    xAxis.push({ datetime: date, x_axis: formatDate(date, format) });
  }
  return xAxis;
};

const constructXaxis = (range) => {
  const now = new Date();
  let xAxis;

  switch (range) {
    case '24hours':
      const twentyFourHoursAgo = getDate(now, -24, 'hours');
      xAxis = createXAxis(twentyFourHoursAgo, now, 'hours', 'hour');
      break;
    case 'last7days':
      const sevenDaysAgo = getDate(now, -6, 'days'); // Start from 6 days ago (inclusive of today)
      xAxis = createXAxis(sevenDaysAgo, now, 'days', 'day').reverse();
      break;
    case 'week':
      const startOfWeek = getDate(now, -now.getDay(), 'days');
      xAxis = createXAxis(
        startOfWeek,
        getDate(startOfWeek, 6, 'days'),
        'days',
        'day'
      );
      break;
    case 'last30days':
      const thirtyDaysAgo = getDate(now, -29, 'days'); // Start from 29 days ago (inclusive of today)
      xAxis = createXAxis(thirtyDaysAgo, now, 'days', 'day').reverse();
      break;
    case 'month':
      const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      xAxis = createXAxis(firstDayOfMonth, lastDayOfMonth, 'days', 'day');
      break;
    case 'lastYear':
      xAxis = [];
      for (let month = now.getMonth() - 11; month <= now.getMonth(); month++) {
        const date = new Date(now.getFullYear(), month, 1);
        xAxis.push({ datetime: date, x_axis: formatDate(date, 'month') });
      }
      break;
    case 'thisYear':
      xAxis = [];
      for (let month = 0; month < 12; month++) {
        const date = new Date(now.getFullYear(), month, 1);
        xAxis.push({ datetime: date, x_axis: formatDate(date, 'month') });
      }
      break;
    default:
      xAxis = [];
  }

  return xAxis;
};

const setTimestamps = (datetime, startHourSettings, endHourSettings) => {
  const startHour = new Date(datetime);
  startHour.setHours(startHourSettings.hours);
  startHour.setMinutes(startHourSettings.minutes);
  startHour.setSeconds(startHourSettings.seconds);
  startHour.setMilliseconds(startHourSettings.milliseconds);
  const startTimestamp = startHour.getTime();

  const endHour = new Date(datetime);
  endHour.setHours(endHourSettings.hours);
  endHour.setMinutes(endHourSettings.minutes);
  endHour.setSeconds(endHourSettings.seconds);
  endHour.setMilliseconds(endHourSettings.milliseconds);
  const endTimestamp = endHour.getTime();

  return { startTimestamp, endTimestamp };
};

const filterAlerts = (alertsList, startTimestamp, endTimestamp) => {
  return alertsList.filter((alert) => {
    const alertTimestamp = alert.timestamp * 1000;
    return alertTimestamp >= startTimestamp && alertTimestamp <= endTimestamp;
  });
};

const mapXAxisObjects = (
  x_axisObjects,
  alertsList,
  settings,
  startHourSettings,
  endHourSettings,
  x_axis_label
) => {
  return x_axisObjects.map((x_axisObject) => {
    const datetime = new Date(x_axisObject.datetime);
    const { startTimestamp, endTimestamp } = setTimestamps(
      datetime,
      startHourSettings,
      endHourSettings
    );
    const alerts = filterAlerts(alertsList, startTimestamp, endTimestamp);

    return {
      data: alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',
      type: 'bar_chart',
      x_axis: x_axisObject.x_axis,
      statsType: settings.statsType.title,
      x_axis_label: x_axis_label,
    };
  });
};

const constructYaxis = (settings, alertsList, x_axisObjects, range) => {
  const commonStart = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };
  const commonEnd = { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 };

  switch (range) {
    case '24hours':
      return mapXAxisObjects(
        x_axisObjects,
        alertsList,
        settings,
        commonStart,
        commonEnd,
        'Hour'
      );
    case 'last7days':
    case 'week':
    case 'last30days':
    case 'month':
    case 'lastYear':
    case 'thisYear':
      return mapXAxisObjects(
        x_axisObjects,
        alertsList,
        settings,
        commonStart,
        commonEnd,
        'Date'
      );
    default:
      return [];
  }
};

const getTimestampsForRange = (datetime, range) => {
  const startDate = new Date(datetime);
  const endDate = new Date(datetime);

  switch (range) {
    case '24hours':
      startDate.setMinutes(0, 0, 0);
      endDate.setMinutes(59, 59, 999);
      break;
    case 'last7days':
    case 'week':
    case 'last30days':
    case 'month':
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      break;
    case 'lastYear':
    case 'thisYear':
      startDate.setDate(1);
      startDate.setHours(0, 0, 0, 0);
      endDate.setMonth(endDate.getMonth() + 1, 0);
      endDate.setHours(23, 59, 59, 999);
      break;
    default:
      throw new Error('Unsupported range');
  }

  return {
    startTimestamp: startDate.getTime(),
    endTimestamp: endDate.getTime(),
  };
};

const filterAlert = (alertsList, startTimestamp, endTimestamp, seriesName) => {
  return alertsList.filter((alert) => {
    const alertTimestamp = alert.timestamp * 1000;
    return (
      alertTimestamp >= startTimestamp &&
      alertTimestamp <= endTimestamp &&
      (seriesName === null || alert.classes.split(',').includes(seriesName))
    );
  });
};

const getDataForXAxisObjects = (
  x_axisObjects,
  alertsList,
  settings,
  range,
  seriesName
) => {
  return x_axisObjects.map((x_axisObject) => {
    const { startTimestamp, endTimestamp } = getTimestampsForRange(
      new Date(x_axisObject.datetime),
      range
    );

    const alerts = filterAlert(
      alertsList,
      startTimestamp,
      endTimestamp,
      seriesName
    );

    return {
      data: alerts.length < 1000 ? alerts.length : alerts.length / 1000 + 'k',
      type: 'line_chart',
      x_axis: x_axisObject.x_axis,
      statsType: settings.statsType.title,
      x_axis_label: range === '24hours' ? 'Hour' : 'Date',
    };
  });
};

const constructSeriesData = (settings, alertsList, x_axisObjects, range) => {
  const series = settings.chart_parameters;
  if (!series.includes('none')) {
    return series.map((val) => ({
      series_name: val,
      data: getDataForXAxisObjects(
        x_axisObjects,
        alertsList,
        settings,
        range,
        val
      ),
      color: getRandomColor(),
    }));
  } else {
    return [
      {
        series_name: settings.statsType.title,
        data: getDataForXAxisObjects(
          x_axisObjects,
          alertsList,
          settings,
          range,
          null
        ),
        color: getRandomColor(),
      },
    ];
  }
};

const constructLineYaxis = (settings, alertsList, x_axisObjects, range) => {
  return constructSeriesData(settings, alertsList, x_axisObjects, range);
};

const constructPieChartData = (settings, alertsList, range) => {
  const series = settings.chart_parameters;

  const data = series.map((val) => {
    const alerts = alertsList.filter((alert) => {
      return alert.classes.split(',').includes(val);
    });

    let count = alerts.length;
    if (count > 0 && settings.statsType.title == 'Average') {
      if (range == '24hour') {
        count = count / 24;
      }
      if (range == 'week' || range == 'last7days') {
        count = count / 7;
      }
      if (range == 'month' || range == 'last30days') {
        count = count / 30;
      }
      if (range == 'lastYear' || range == 'thisYear') {
        count = count / 12;
      }
    }
    return {
      name: val,
      data: Math.round(count),
      color: getRandomColor(),
    };
  });

  return data;
};
