import {
  Box,
  MenuItem,
  Select,
  Typography,
  TextField,
  FormHelperText,
  Grid,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React, { useState, useEffect } from 'react';
import {
  DashboardForm,
  AddForm,
  InputBox,
  ButtonBox,
  Button,
} from '../../../Layouts/style';
import {
  getAllSettingsByAnalytics,
  getAllStatsType,
} from '../../../graphQL/Dashboard/query';
import { Formik } from 'formik';
import AnalyticsService from '../../../RestSevices/analyticsService';
import { dashboardSettingsValidationSchema } from '../../../Utils/validators';
import {
  createDashboardSettings,
  deleteDashboardSettings,
  updateDashboardSettings,
} from '../../../graphQL/Dashboard/mutation';
import DeleteDialog from '../../../Components/deleteDialog';
import useAuth from '../../../Redux/Actions/authActions';
import { toast } from 'react-toastify';
import { checkPermission } from '../../../Utils/checkPermission';
import { useNavigate } from 'react-router-dom';

const DashboardSettings = () => {
  const [analytics, setAnalytics] = useState('');
  const [analyticsList, setAnalyticsList] = useState([]);
  const [statsTypes, setStatsTypes] = useState([]);
  const [classList, setClassList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [settingsId, setSettingsId] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleChange = (event, index, frmkProps) => {
    const { name, value } = event.target;
    setForms(
      forms.map((form, index2) => {
        if (index2 == index) {
          form[name] = value;
          if (name == 'chart') {
            form['chart_parameters'] = [];
            frmkProps.setFieldValue('chart_parameters', []);
            form['x_axis'] = '';
            frmkProps.setFieldValue('x_axis', '');
            form['y_axis'] = '';
            frmkProps.setFieldValue('y_axis', '');
            if (value == 'pie_chart') {
              form['parameter'] = 'none';
              frmkProps.setFieldValue('parameter', 'none');
            }
          }
          return form;
        } else {
          return form;
        }
      })
    );
    frmkProps.handleChange(event);
  };

  const chart_types = [
    { title: 'No chart', slug: 'no_chart' },
    { title: 'Bar chart', slug: 'bar_chart' },
    { title: 'Pie chart', slug: 'pie_chart' },
    { title: 'Line chart', slug: 'line_chart' },
  ];

  const formTemplate = {
    id: '',
    name: '',
    parameter: 'none',
    stats_type: '',
    chart: 'no_chart',
    x_axis: '',
    y_axis: '',
    chart_parameters: [],
    analytics_id: analytics,
  };

  const [forms, setForms] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const addForm = () => {
    setForms([...forms, formTemplate]);
  };
  const isDisabled = () => {
    return forms.some((form) => form.id === '');
  }

  const fetchSettings = async () => {
    const settingsresp = await getAllSettingsByAnalytics(analytics);
    if ('errors' in settingsresp) {
      if (settingsresp?.errors[0].message == 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }

    const settingsData =
      settingsresp?.data?.getAllDashboardSettingsByAnalytic?.dashboardSettings?.map(
        (forms) => {
          return {
            ...forms,
            chart_parameters: forms.chart_parameters
              ? forms.chart_parameters.split(',')
              : [],
          };
        }
      );
    setForms(settingsData || []);
  };

  useEffect(() => {
    if (analytics) {
      fetchSettings();
    } else {
      setForms([]);
    }
  }, [analytics]);

  const fetchData = async () => {
    const statsTypeResp = await getAllStatsType();
    if ('errors' in statsTypeResp) {
      // if (statsTypeResp?.errors[0].message == 'Authentication failed') {
      //   logout();
      //   toast.error('Session Expired!', {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }
    }
    setStatsTypes(statsTypeResp.data?.getAllStatsType?.StatsTypes || []);

    await AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setAnalyticsList(resp.data || []);
      })
      .catch((err) => {
        if (err.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const handleAnalyticsChange = (event) => {
    setAnalytics(event.target.value);
    if (event.target.value) {
      analyticsList.map((analytic) => {
        if (analytic.hash_id == event.target.value) {
          analytic.analyticfields.map((field) => {
            if (field.field_name == 'frontend_classes') {
              setClassList(field.field_value);
            }
          });
        }
      });
    }
  };
  console.log(submitting, "submitting");
  const handleSubmit = (values, { resetForm }, ind) => {
    if (submitting) return;
    setSubmitting(true);
    const submittedForm = forms[ind];
    setTimeout(() => {
      if (submittedForm.id) {
        updateDashboardSettings(submittedForm)
          .then((resp) => { setSubmitting(false) })
          .catch((err) => { console.log(err); setSubmitting(false) });
      } else {
        createDashboardSettings(submittedForm)
          .then((resp) => {
            setSubmitting(false)
            if (resp.data?.createDashboardSettings?.success) {
              setForms(
                forms.map((form, index) => {
                  if (index == ind) {
                    form.id = resp.data?.createDashboardSettings?.data?.id;
                    return form;
                  }
                  return form;
                })
              );
            }
          })
          .catch((err) => { console.log(err); setSubmitting(false) });
      }
    }, 100);

  };

  const handleDelete = () => {
    deleteDashboardSettings(settingsId)
      .then((resp) => {
        setForms(forms.filter((form) => form.id !== settingsId));
        setSettingsId(false);
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteConfirm = (id) => {
    setIsOpen(true);
    setSettingsId(id);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSettingsId(false);
  };

  const redirectToPriviousPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    checkPermission('edit_dashboard') ? fetchData() : redirectToPriviousPage();
  }, []);

  return (
    <DashboardForm>
      <Box className="inner-content">
        <Box className="select-top">
          <Typography component="p">Select analytics</Typography>
          <FormControl className="select-box">
            <Select
              className="select"
              value={analytics}
              name="analytics"
              onChange={handleAnalyticsChange}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {analyticsList.map((analytic) => (
                <MenuItem value={analytic.hash_id}>
                  {analytic.analytic_name}
                </MenuItem>
              ))}
              {/*               
              <MenuItem value={2}>Person Atributes 2</MenuItem>
              <MenuItem value={3}>Person Atributes 3</MenuItem> */}
            </Select>
          </FormControl>
        </Box>

        {forms.map((formdata, index) => (
          <Formik
            initialValues={formdata}
            validationSchema={dashboardSettingsValidationSchema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, { resetForm }, index)
            }
            enableReinitialize
            key={index}
          >
            {(props) => (
              // <form>
              <Box className="add-form" key={index}>
                <Typography component="p">{`Add stats +(${index + 1})`}</Typography>
                <AddForm className="formMain" onSubmit={props.handleSubmit}>
                  <InputBox>
                    <Typography component="span">Stats name</Typography>
                    <TextField
                      id="outlined-basic"
                      className="input"
                      name="name"
                      value={formdata.name}
                      onChange={(e) => handleChange(e, index, props)}
                    />
                    <FormHelperText
                      error={props.touched.name && !!props.errors.name}
                    >
                      {props.touched.name ? props.errors.name : ''}
                    </FormHelperText>
                  </InputBox>
                  <InputBox>
                    <Typography component="span">Chart type</Typography>
                    <Select
                      className="select"
                      name="chart"
                      value={formdata.chart}
                      onChange={(e) => { handleChange(e, index, props) }}
                      displayEmpty
                    >
                      {chart_types.map((type) => (
                        <MenuItem value={type.slug}>{type.title}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      error={props.touched.chart && !!props.errors.chart}
                    >
                      {props.touched.chart ? props.errors.chart : ''}
                    </FormHelperText>
                  </InputBox>
                  {formdata.chart !== 'pie_chart' && (
                    <InputBox>
                      <Typography component="span">Stats parameter</Typography>
                      <Select
                        className="select"
                        name="parameter"
                        value={formdata.parameter}
                        onChange={(e) => handleChange(e, index, props)}
                        displayEmpty
                      >
                        <MenuItem value="none">
                          <em>None</em>
                        </MenuItem>
                        {classList.map((type) => (
                          <MenuItem value={type}>{type}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        error={
                          props.touched.parameter && !!props.errors.parameter
                        }
                      >
                        {props.touched.parameter ? props.errors.parameter : ''}
                      </FormHelperText>
                    </InputBox>
                  )}
                  <InputBox>
                    <Typography component="span">Type of stats</Typography>
                    <Select
                      className="select"
                      name="stats_type"
                      value={formdata.stats_type}
                      onChange={(e) => handleChange(e, index, props)}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {statsTypes.map((type) => (
                        <MenuItem value={type.id}>{type.title}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      error={
                        props.touched.stats_type && !!props.errors.stats_type
                      }
                    >
                      {props.touched.stats_type ? props.errors.stats_type : ''}
                    </FormHelperText>
                  </InputBox>

                  {formdata.chart == 'bar_chart' && (
                    <>
                      <InputBox>
                        <Typography component="span">Select X axis</Typography>
                        <Select
                          className="select"
                          name="x_axis"
                          value={formdata.x_axis}
                          onChange={(e) => handleChange(e, index, props)}
                          displayEmpty
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={'time'}>Time</MenuItem>
                        </Select>
                        <FormHelperText
                          error={props.touched.x_axis && !!props.errors.x_axis}
                        >
                          {props.touched.x_axis ? props.errors.x_axis : ''}
                        </FormHelperText>
                      </InputBox>
                      <InputBox>
                        <Typography component="span">Select Y axis</Typography>
                        <Select
                          className="select"
                          name="y_axis"
                          value={formdata.y_axis}
                          onChange={(e) => handleChange(e, index, props)}
                          displayEmpty
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={'total_count'}>Total Count</MenuItem>
                        </Select>
                        <FormHelperText
                          error={props.touched.y_axis && !!props.errors.y_axis}
                        >
                          {props.touched.y_axis ? props.errors.y_axis : ''}
                        </FormHelperText>
                      </InputBox>
                    </>
                  )}
                  {(formdata.chart == 'pie_chart' ||
                    formdata.chart == 'line_chart') && (
                      <>
                        <InputBox>
                          <Typography component="span">
                            Chart parameters
                          </Typography>
                          <Select
                            className="select"
                            name="chart_parameters"
                            value={formdata.chart_parameters}
                            onChange={(e) => handleChange(e, index, props)}
                            displayEmpty
                            multiline={true}
                            multiple={true}
                          >
                            <MenuItem
                              value="none"
                              disabled={
                                (formdata.chart_parameters.length &&
                                  !formdata.chart_parameters.includes('none')) ||
                                formdata.chart == 'pie_chart'
                              }
                            >
                              <em>None</em>
                            </MenuItem>
                            {classList.map(
                              (type) =>
                                type !== formdata.parameter && (
                                  <MenuItem
                                    value={type}
                                    disabled={formdata.chart_parameters.includes(
                                      'none'
                                    )}
                                  >
                                    {type}
                                  </MenuItem>
                                )
                            )}
                          </Select>
                          <FormHelperText
                            error={
                              props.touched.chart_parameters &&
                              !!props.errors.chart_parameters
                            }
                          >
                            {props.touched.chart_parameters
                              ? props.errors.chart_parameters
                              : ''}
                          </FormHelperText>
                        </InputBox>
                      </>
                    )}
                  {formdata.id ? (
                    <Grid container spacing={2} justifyContent="flex-end">
                      <Grid item>
                        <Button
                          type="button"
                          onClick={() => handleDeleteConfirm(formdata.id)}
                        >
                          Delete
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button type="submit">Update</Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Button type="submit">Create</Button>
                      </Grid>
                    </Grid>
                  )}
                </AddForm>
              </Box>
              // </form>
            )}
          </Formik>
        ))}
        <DeleteDialog
          title={'Delete settings?'}
          message={'Are you sure you want to delete this settings?'}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClose={handleClose}
          onDelete={handleDelete}
        />
        <Box
          className="add-more"
          sx={{
            padding: '10px 100px',
            '@media (max-width: 1460px)': {
              padding: '10px 80px',
            },
            '@media (max-width: 1199px)': {
              padding: '10px 55px',
            },
            '@media (max-width: 991px)': {
              padding: '10px 35px',
            },
            '@media (max-width: 499px)': {
              padding: '10px 20px',
            },
          }}
        >
          {analytics && <Button className={isDisabled() ? "disabled" : ""} onClick={addForm} disabled={isDisabled()}>Add More +</Button>}
        </Box>
      </Box>
    </DashboardForm>
  );
};

export default DashboardSettings;
