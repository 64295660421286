import React, { useEffect, useState } from 'react';
import { HeaderStyle } from '../Layouts/style';
import { Box, Badge, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { BellIcon, DownArrow } from '../Icons/SVGContainer';
import HeaderDateInfo from './HeaderDateInfo';
import { Link, useLocation } from 'react-router-dom';
import useAuth from '../Redux/Actions/authActions';
import AlertsService from '../RestSevices/alertService';
import { toast } from 'react-toastify';
import io from 'socket.io-client';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [alarmList, setAlarmList] = useState([]);
  const [newAlartCount, setNewAlartCount] = useState(0);
  const [prevAlertCount, setPrevAlertCount] = useState(0);
  const [fetchTimeout, setFetchTimeout] = useState(null); // State to store the timeout reference
  const [isFetching, setIsFetching] = useState(false); // State to track if fetching is scheduled
  const location = useLocation();
  const { user, logout } = useAuth();
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchAlarms = async (refresh = false) => {
    try {
      const alarmListResp = await AlertsService.getDashboardAlarms();
      setAlarmList(alarmListResp.data || []);
      const alertCount = alarmListResp.data?.length || 0;
      setNewAlartCount(alertCount);

      // Show toast only if new alerts are greater than previous alerts
      if (location.pathname !== '/dashboard' && alertCount && alertCount !== prevAlertCount && refresh) {
        toast.info(`You have ${alertCount} new alarms`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      // Update the previous alert count state
      setPrevAlertCount(alertCount);
    } catch (error) {
      if (error.response?.data?.error === 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      console.log(error);
    }
  };

  const alarmSeen = () => {
    const alertIds = alarmList?.map((data) => data._id);
    AlertsService.seenAlerts(alertIds).then((res) => {
      setAlarmList([]);
      setNewAlartCount(0);
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    console.log('socketUrl', process.env.REACT_APP_API_SOCKET_URL);
    const socketUrl = process.env.REACT_APP_API_SOCKET_URL;
    const socket = io(socketUrl);

    socket.on('connect', () => {
      console.log('Socket connected');
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    socket.on('alert_created', (data) => {
      if (!isFetching) {
        setIsFetching(true); // Set the flag to true
        const timeout = setTimeout(() => {
          fetchAlarms(true);
          setIsFetching(false); // Reset the flag after fetching
        }, 5000); // Wait for 5 seconds before fetching
        setFetchTimeout(timeout); // Store the timeout reference
      } else {
        clearTimeout(fetchTimeout); // Clear the previous timeout
        const timeout = setTimeout(() => {
          fetchAlarms(true);
          setIsFetching(false); // Reset the flag after fetching
        }, 5000); // Wait for 5 seconds before fetching
        setFetchTimeout(timeout); // Store the timeout reference
      }
    });

    return () => {
      console.log('Socket disconnect');
      socket.disconnect();
      clearTimeout(fetchTimeout); // Clear timeout on unmount
    };
  }, [fetchTimeout, isFetching]); // Add fetchTimeout and isFetching as dependencies

  useEffect(() => {
    fetchAlarms();
  }, [location.pathname]); // Adding location.pathname as a dependency

  return (
    <HeaderStyle>
      <Box className="header_area">
        <Box className="header_left">
          <Box className="header_logo">
            <img src="/images/logo_white.svg" alt="Logo" />
          </Box>
          <HeaderDateInfo user={user} />
        </Box>
        <Box
          sx={{
            '.MuiBadge-badge': {
              top: '0.5rem',
              right: '0.5rem',
              width: '2rem',
              height: '2rem',
              borderRadius: '100%',
            },
          }}
          className="header_right"
        >
          <Badge color="primary" badgeContent={newAlartCount}>
            <IconButton onClick={alarmSeen}>
              <BellIcon />
            </IconButton>
          </Badge>
          <Box className="head_user_name">
            <Box>
              <Typography component="h3">
                {user?.firstname + ' ' + user?.lastname}
              </Typography>
              <Typography component="h4">{user?.userType}</Typography>
            </Box>
          </Box>
          <IconButton className="header_drop_icon" onClick={handleClick}>
            <DownArrow />
          </IconButton>
          <Menu
            id="basic-menu"
            className="profile_toggle"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>
              <Link to="/user-password-change">Change Password</Link>
            </MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </Box>
      </Box>
    </HeaderStyle>
  );
};

export default Header;
