import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { ZoneStyle } from '../../../Layouts/style';
import ZoneConfigurationHeading from './ZoneConfigurationHeading';
import { AddIcon } from '../../../Icons/SVGContainer';
import AddZoneForm from '../../../Components/Form/AddZoneForm';
import ZoneService from '../../../RestSevices/zoneService';
import StreamingService from '../../../RestSevices/streamService';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../../Redux/Actions/authActions';
import { loadPlayer } from 'rtsp-relay/browser';
import CameraPreview from '../../../Components/CameraPreview';
import CameraService from '../../../RestSevices/cameraService';

function DeleteZoneDialog({ deleteIsOpen, deleteOnClose, onDelete }) {
  return (
    <Dialog
      open={deleteIsOpen}
      onClose={deleteOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Delete Zone?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this zone?

        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteOnClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const ZoneConfigurationView = () => {
  const [zoneForm, setZoneForm] = useState(false);
  const [zones, setZones] = useState([]);
  const handleZoneForm = () => {
    if (zoneForm && selectedZone) {
      // onRestartDrawing();
      setSelectedZone(null);
    }
    setZoneForm(!zoneForm);
    setSelectedZoneHashId(null);
  };

  const { camerahashid } = useParams();
  const canvasRef = useRef(null);
  const videoPreviewRef1 = useRef(null);
  const canvas_area_ref = useRef(null);
  const videoRef = useRef(null);
  let player;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [context, setContext] = useState(null);
  const [drawingType, setDrawingType] = useState('');
  const polygons = useRef([]);
  const currentPolygon = useRef([]);
  const [isDragging, setIsDragging] = useState(false);
  const [polygonDragIndex, setPolygonDragIndex] = useState([]);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [zoneColor, setZoneColor] = useState('black');
  const [analyticsFields, setAnalyticsFields] = useState([]);
  const [existingZones, setExistingZones] = useState([]);
  const [cameraDetails, setCameraDetails] = useState(null);
  const [selectedZoneHashId, setSelectedZoneHashId] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [camera, setCamera] = useState(null)
  const url = 'rtsp://172.19.0.1:8554/test'

  const { logout } = useAuth();

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    setCamera({
      rstp_url: url,
      hash_id: camerahashid
    })
  }, [url, camerahashid])


  useEffect(() => {
    if (!camerahashid) return;

    const connectPlayer = async () => {
      try {
        let stream_url = process.env.REACT_APP_STREAM_URL;
        const resp = await CameraService.getCameraDetailsByHash(camerahashid)
        setCameraDetails(resp.data.data);
        const demoUrl = resp?.data?.data?.rstp_url

        if (!demoUrl || !stream_url) return;

        stream_url = stream_url.replace(/^https?:\/\//, '');

        StreamingService.getCameraPreview(demoUrl, camerahashid);

        const playerInstance = await loadPlayer({
          url: `ws://${stream_url}/dev/api/service/streaming/feed/${camerahashid}`,
          canvas: videoPreviewRef1.current,
          disconnectThreshold: 150000,
          onDisconnect: () => {
            console.log('Connection lost.');
          },
          onPlay: () => {
            console.log('Video is playing')

            setTimeout(() => {
              if (canvasRef.current) {
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');

                // Save current content before resizing
                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

                // Set the new canvas dimensions
               canvasRef.current.width = videoPreviewRef1.current.clientWidth;
               canvasRef.current.height = videoPreviewRef1.current.clientHeight;

                // Restore the saved content
                context.putImageData(imageData, 0, 0);
              }
            }, 10000)
          }
        });
        console.log('Connected', playerInstance);
        player = playerInstance;

        if (videoPreviewRef1.current) {
          setTimeout(() => {
            videoPreviewRef1.current.style.display = 'block';
          }, 2000)
        }
      } catch (error) {
        console.log(error);
      }
    };

    connectPlayer();

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, [camerahashid]);

  const fetchZones = () => {
    ZoneService.getZones(camerahashid)
      .then((resp) => {
        if (resp.data.length) {
          let zoneArray = resp.data[0].zonelist.replace(/\\/g, '');
          zoneArray = zoneArray.replace(/"\[/g, '[');
          zoneArray = zoneArray.replace(/"\{/g, '{');
          zoneArray = zoneArray.replace(/]\"/g, ']');
          zoneArray = zoneArray.replace(/}\"/g, '}');
          let zoneArr = JSON.parse(zoneArray);
          setExistingZones(zoneArr);
        } else {
          setExistingZones([]);
        }
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  //get canvas area hight width
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    setContext(ctx);
  }, []);

  useEffect(() => {
    setWidth(canvas_area_ref.current.offsetWidth);
    setHeight(canvas_area_ref.current.offsetHeight);
  }, [canvas_area_ref]);

  const handleZoneClick = (event, hashid) => {
    event.stopPropagation();
    setSelectedZoneHashId(hashid);
    existingZones.forEach((zone) => {
      if (zone.hash_id === hashid) {
        setSelectedZone(zone);
      }
    });
    if (!zoneForm) setZoneForm(!zoneForm);
  };

  const handleDeleteZone = (e) => {
    e.stopPropagation();
    setDeleteIsOpen(true);
  };

  const handleDelete = () => {
    ZoneService.deleteZone(selectedZoneHashId, camerahashid)
      .then((res) => {
        toast.success('Zone deleted successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        onRestartDrawing();
        fetchZones();
        setSelectedZoneHashId(null);
        setDeleteIsOpen(false);
        if (zoneForm) handleZoneForm();
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error('Error while deleting zone!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const handleZoneSubmit = (formValues) => {
    const formData = {
      ...formValues,
      zone_x_coordinate: 0,
      zone_y_coordinate: 0,
      status: 1,
      coordinates: zones,
      analyticfield: analyticsFields,
      annotation_type: drawingType,
    };
    if (selectedZone) {
      formData.hash_id = selectedZone.hash_id;
      formData.coordinates = selectedZone.coordinates;
      ZoneService.updateZone(formData, camerahashid)
        .then((res) => {
          toast.success('Zone updated successfully!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleZoneForm();
          onRestartDrawing();
          setDrawingType('');
          fetchZones();
          setSelectedZone(null);
        })
        .catch((error) => {
          if (error.response?.data?.error === 'Authentication failed') {
            logout();
            toast.error('Session Expired!', {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error('Error while updating zone!', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } else {
      ZoneService.createZone(formData, camerahashid)
        .then((res) => {
          toast.success('Zone created successfully!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          handleZoneForm();
          onRestartDrawing();
          setDrawingType('');
          fetchZones();
        })
        .catch((error) => {
          if (error.response?.data?.error === 'Authentication failed') {
            logout();
            toast.error('Session Expired!', {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error('Error while creating zone!', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  };

  const drawExistingLines = (points, fillColor) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
      ctx.globalAlpha = 1;
      ctx.lineTo(points[i].x, points[i].y);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'yellow';
    }

    ctx.stroke();
    ctx.fillStyle = fillColor;
    ctx.globalAlpha = 0.2;
    ctx.fill();
    ctx.closePath();
  };

  const prepareExistingLines = (hash_id) => {
    if (existingZones.length) {
      for (let i = 0; i < existingZones.length; i++) {
        drawExistingLines(existingZones[i].coordinates, existingZones[i].color);
      }
    }
  };

  useEffect(() => {
    onRestartDrawing();
  }, [existingZones]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    setTimeout(() => {
      canvas.style['z-index'] = 1
    }, 2000)

    const drawLine = (
      ctx,
      lastPointX,
      lastPointY,
      currentPointX,
      currentPointY,
      startX,
      startY
    ) => {
      if (!ctx) return;
      ctx.globalAlpha = 1;
      ctx.lineTo(currentPointX, currentPointY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'yellow';
      ctx.stroke();

      if (
        Math.abs(currentPointX - startX) < 8 &&
        Math.abs(currentPointY - startY) < 8
      ) {
        // Close the path
        ctx.closePath();
        // Fill the area with color
        ctx.fillStyle = zoneColor;
        ctx.globalAlpha = 0.2;
        ctx.fill();
        const currentZone = currentPolygon.current.map((val) => {
          return { x: Math.round(val[0]), y: Math.round(val[1]) };
        });
        setZones(currentZone);
        currentPolygon.current = [];
      } else {
        ctx.arc(currentPointX, currentPointY, 1, 0, 2 * Math.PI);
        setZones([]);
        if (currentPolygon.current.length == 2) {
          const currentZone = currentPolygon.current.map((val) => {
            return { x: Math.round(val[0]), y: Math.round(val[1]) };
          });
          setZones(currentZone);
        }
      }
    };

    const handleMouseDown = (event) => {
      if (drawingType !== 'line') return;
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      currentPolygon.current.push([x, y]);
      handleDrawingType(drawingType);

      if (drawingType === 'line' && currentPolygon.current.length == 1) {
        ctx.beginPath();
        ctx.arc(
          currentPolygon.current[0][0],
          currentPolygon.current[0][1],
          2,
          0,
          2 * Math.PI
        );
        ctx.moveTo(currentPolygon.current[0][0], currentPolygon.current[0][1]);
        ctx.strokeStyle = 'yellow';
        ctx.stroke();
      }

      if (
        drawingType === 'line' &&
        currentPolygon.current.length > 1 &&
        currentPolygon.current.length > 1
      ) {
        drawLine(
          ctx,
          currentPolygon.current[currentPolygon.current.length - 2][0],
          currentPolygon.current[currentPolygon.current.length - 2][1],
          x,
          y,
          currentPolygon.current[0][0],
          currentPolygon.current[0][1]
        );
      }
    };

    canvas.addEventListener('mousedown', handleMouseDown);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
    };
  }, [drawingType]);

  const handleDrawingType = useCallback(
    (type) => {
      setDrawingType(type);
    },
    [setDrawingType]
  );

  const clearAllDrawings = (canvas, xCord = 0, yCord = 0) => {
    const width = canvasRef.current.width;
    const height = canvasRef.current.height;
    const context2D = canvasRef.current.getContext('2d');
    if (context2D) {
      context2D.clearRect(xCord, yCord, width, height);
    }
  };

  const selectWholeArea = () => {
    const array = [
      { x: 1, y: 1 },
      { x: width - 1, y: 1 },
      { x: width - 1, y: height - 7 },
      { x: 0, y: height - 7 },
      { x: 1, y: 1 },
    ];
    onRestartDrawing();
    array.forEach((obj, index, arr) => {
      setPolygonCoordinates((prevCoordinates) => [...prevCoordinates, obj]);
    });
  };

  const onRestartDrawing = () => {
    const canvas = canvasRef.current;
    const context2D = canvas.getContext('2d');
    resetDrawing(context2D, true);
    context2D.clearRect(0, 0, canvas.width, canvas.height);
    setZones([]);
    setPolygonCoordinates([]);
    currentPolygon.current = [];
    polygons.current = [];
    prepareExistingLines();
  };

  const resetDrawing = (canvas, restart = false) => {
    clearAllDrawings(canvas, 0, 0);
  };

  // whole area codes==============================================

  // draw whole area on button click
  useEffect(() => {
    if (drawingType === 'whole_area' && polygonCoordinates.length) {
      const canvas = canvasRef.current;
      const context2D = canvas.getContext('2d');
      drawWholeArea(context2D);
    }
  }, [drawingType, polygonCoordinates]);

  // function to draw whole area canvas
  const drawWholeArea = (closepath = false, fillShape = true) => {
    if (!context || polygonCoordinates.length === 0) return;

    context.globalAlpha = 0.1;
    context.beginPath();
    context.moveTo(polygonCoordinates[0].x, polygonCoordinates[0].y);

    for (let i = 1; i < polygonCoordinates.length; i++) {
      context.lineTo(polygonCoordinates[i].x, polygonCoordinates[i].y);
      context.lineWidth = 2;
      context.strokeStyle = 'yellow';
      context.stroke();
      context.arc(
        polygonCoordinates[i].x,
        polygonCoordinates[i].y,
        2,
        0,
        2 * Math.PI
      );
    }
    if (closepath) {
      context.closePath();
      if (fillShape) {
        context.fillStyle = zoneColor;
        context.fill();
      }
    }
    context.stroke();
    setZones(polygonCoordinates);
  };

  useEffect(() => {
    if (!isDragging || polygonDragIndex.length == 0) return;

    const canvas = canvasRef.current;
    const context2D = canvas.getContext('2d');
    const newCoOrdinates = polygonCoordinates.map((value, index) => {
      if (polygonDragIndex.includes(index)) {
        return { x: offset.x, y: offset.y };
      }
      return value;
    });
    setPolygonCoordinates(newCoOrdinates);
    // Clear canvas
    clearAllDrawings(canvas, 0, 0);
    prepareExistingLines();

    // Draw whole area
    drawWholeArea(context2D);
  }, [isDragging, offset]);

  //On mouse click if draw type is whole area then set which point is clicked to drag
  const handleMDown = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const newIndexes = [];
    // finding index of coordinates in polygon coirdinates array
    polygonCoordinates.forEach((value, index) => {
      if (Math.abs(value.x - x) < 15 && Math.abs(value.y - y) < 15) {
        prepareExistingLines();

        newIndexes.push(index);
        setIsDragging(true);
      }
    });
    setPolygonDragIndex(newIndexes);
  };

  // dragging the point of whole area on mouse move
  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;
    setOffset({ x: offsetX, y: offsetY });
  };

  // on mouse up stop dragging
  const handleMouseUp = (event) => {
    setIsDragging(false);
    setPolygonDragIndex([]);
  };

  return (
    <ZoneStyle>

      <Box className="zone_area">
        <ZoneConfigurationHeading />
        <Box className="canvas_area">
          <Box className="canvas_left">
            <Box className="canvas_con" id="canvas_area" ref={canvas_area_ref} sx={{ '.vicanvas': { display: 'block' } }}>


              <canvas
                id="canvas"
                ref={canvasRef}
                width={width}
                height={height}
                onMouseDown={handleMDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
              />
              <canvas
                id="canvas-video-preview"
                ref={videoPreviewRef1}
              />

            </Box>

            <Box className="canvas_bot_info">
              {existingZones.map((data, index) => (
                <Box
                  className={`canvas_bot_info_con ${selectedZoneHashId === data.hash_id ? 'selected' : ''
                    }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleZoneClick(e, data.hash_id);
                  }}
                >
                  <Box className="canvas_bot_info_hold">
                    <Box
                      className="color_use"
                      style={{ backgroundColor: data.color }}
                    ></Box>
                    <Typography>{data.zone_name} </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box className="canvas_right">
            <Box
              className={
                zoneForm ? 'canvas_right_default hide' : 'canvas_right_default'
              }
            >
              <Box className="canvas_right_default_step">
                <img src="/images/zone_icon.svg" alt="" />
                <Typography component="h2">
                  No configuration data added
                </Typography>
                <Typography>
                  Please select a zone if already added or add a new one by
                  clicking the button below.
                </Typography>
                <IconButton
                  onClick={() => {
                    handleZoneForm();
                    setSelectedZoneHashId(null);
                    onRestartDrawing();
                  }}
                >
                  <AddIcon /> Create Zone
                </IconButton>
              </Box>
            </Box>
            <Box
              className={
                zoneForm ? 'canvas_right_form show' : 'canvas_right_form'
              }
            >
              <Box className="canvas_right_form_con">
                <AddZoneForm
                  handleZoneForm={handleZoneForm}
                  handleZoneSubmit={handleZoneSubmit}
                  zones={zones}
                  setZoneColor={setZoneColor}
                  analyticsFields={analyticsFields}
                  setAnalyticsFields={setAnalyticsFields}
                  selectedZone={selectedZone}
                  handleDeleteZone={handleDeleteZone}
                  cameraDetails={cameraDetails}
                />
              </Box>
              {!selectedZone && (
                <Box className="canvas_right_bot">
                  <Typography>Drawing toolbox</Typography>
                  <Box className="canvas_btn_con">
                    <Box>
                      <Button
                        className={drawingType == 'line' ? 'active' : ''}
                        onClick={() => {
                          handleDrawingType('line');
                          onRestartDrawing();
                        }}
                      >
                        <img src="/images/shape2.svg" alt="" />
                      </Button>
                    </Box>
                    <Button
                      className={drawingType == 'whole_area' ? 'active' : ''}
                      onClick={() => {
                        handleDrawingType('whole_area');
                        selectWholeArea();
                      }}
                    >
                      <img src="/images/shape3.svg" alt="" /> Whole Area
                    </Button>
                    <Button
                      className="reset_btn"
                      onClick={() => {
                        onRestartDrawing();
                        setDrawingType('');
                      }}
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <DeleteZoneDialog
        deleteIsOpen={deleteIsOpen}
        deleteOnClose={() => setDeleteIsOpen(false)}
        onDelete={handleDelete}
      />
    </ZoneStyle>
  );
};

export default ZoneConfigurationView;
