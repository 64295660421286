import { gql } from '@apollo/client';

export const GET_STATS_TYPE = gql`
  query StatsTypes {
    getAllStatsType {
      StatsTypes {
        id
        title
      }
    }
  }
`;

export const GET_ALL_DASHBOARD_SETTINGS_BY_ANALYTICS = gql`
  query GetAllDashboardSettingsByAnalytic(
    $getAllDashboardSettingsByAnalyticId: ID!
  ) {
    getAllDashboardSettingsByAnalytic(
      id: $getAllDashboardSettingsByAnalyticId
    ) {
      dashboardSettings {
        id
        analytics_id
        name
        parameter
        stats_type
        chart
        x_axis
        y_axis
        chart_parameters
        statsType {
          title
        }
      }
    }
  }
`;

export const GET_ALL_DASHBOARD_SETTINGS_BY_ANALYTICS_IDS = gql`
  query GetAllDashboardSettingsByAnalyticByIds($ids: [ID]!) {
    getAllDashboardSettingsByAnalyticIds(ids: $ids) {
      dashboardSettings {
        name
        chart_parameters
        chart
        analytics_id
        parameter
        stats_type
        x_axis
        y_axis
        statsType {
          id
          title
        }
        id
      }
    }
  }
`;

export const GET_ALL_CRITICAL_ALERT_SETTINGS = gql`
    query CriticalAlertSettings {
      getAllCriticalAlertSettings {
        criticalAlertSettings {
          id
          analytics_id
          camera_id
        }
      }
    }
`;

export const GET_ALL_ALARM_SETTINGS = gql`
    query GetAllAlarmSettings {
      getAllAlarmSettings {
        alarmSettings {
          id
          analytics_id
          camera_id
        }
      }
    }
`;

export const CREATE_DASHBOARD_SETTINGS = gql`
  mutation Mutation(
    $name: String
    $parameter: String
    $stats_type: String
    $chart: String
    $x_axis: String
    $y_axis: String
    $chart_parameters: [String]
    $analytics_id: String
  ) {
    createDashboardSettings(
      name: $name
      parameter: $parameter
      stats_type: $stats_type
      chart: $chart
      x_axis: $x_axis
      y_axis: $y_axis
      chart_parameters: $chart_parameters
      analytics_id: $analytics_id
    ) {
      success
      data {
        id
      }
    }
  }
`;

export const UPDATE_DASHBOARD_SETTINGS = gql`
  mutation Mutation(
    $id: ID!
    $name: String
    $parameter: String
    $stats_type: String
    $chart: String
    $x_axis: String
    $y_axis: String
    $chart_parameters: [String]
    $analytics_id: String
  ) {
    updateDashboardSettings(
      id: $id
      name: $name
      parameter: $parameter
      stats_type: $stats_type
      chart: $chart
      x_axis: $x_axis
      y_axis: $y_axis
      chart_parameters: $chart_parameters
      analytics_id: $analytics_id
    ) {
      success
      data {
        id
      }
    }
  }
`;

export const DELETE_DASHBOARD_SETTINGS = gql`
  mutation DeleteDashboardSettings($id: ID!) {
    deleteDashboardSettings(id: $id) {
      success
    }
  }
`;

export const CREATE_CRITICAL_ALERT_SETTINGS = gql`
  mutation Mutation($analyticsId: String, $cameraIds: [String]) {
    createCriticalAlertSetting(analytics_id: $analyticsId, camera_ids: $cameraIds) {
      success
      data {
        id
        analytics_id
        camera_id
      }
    }
  }
`;

export const UPDATE_CRITICAL_ALERT_SETTINGS = gql`
  mutation UpdateCriticalAlertSetting($updateCriticalAlertSettingId: ID!, $analyticsId: String, $cameraIds: [String]) {
    updateCriticalAlertSetting(id: $updateCriticalAlertSettingId, analytics_id: $analyticsId, camera_ids: $cameraIds) {
      success
      data {
        id
        analytics_id
        camera_id
      }
    }
  }
`;

export const DELETE_CRITICAL_ALERT_SETTINGS = gql`
  mutation DeleteCriticalAlertSetting($deleteCriticalAlertSettingId: ID!) {
    deleteCriticalAlertSetting(id: $deleteCriticalAlertSettingId) {
      success
    }
  }
`;

export const CREATE_ALARM_SETTINGS = gql`
    mutation CreateAlarmSetting($analyticsId: String, $cameraIds: [String]) {
      createAlarmSetting(analytics_id: $analyticsId, camera_ids: $cameraIds) {
        success
        data {
          id
          analytics_id
          camera_id
        }
      }
    }
`;

export const UPDATE_ALARM_SETTINGS = gql`
    mutation UpdateAlarmSetting($id: ID!, $analyticsId: String, $cameraIds: [String]) {
      updateAlarmSetting(id: $id, analytics_id: $analyticsId, camera_ids: $cameraIds) {
        success
        data {
          id
          analytics_id
          camera_id
        }
      }
    }
`;

export const DELETE_ALARM_SETTINGS = gql`
    mutation DeleteAlarmSetting($id: ID!) {
      deleteAlarmSetting(id: $id) {
        success
      }
    }
`;
