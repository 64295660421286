import axios from '../Utils/api';

const AlertsService = {
  getAlerts: async ({
    analyticsSelection,
    selectedClasses,
    cameraSelection,
    page,
    sort,
    cameraId = null,
    dateFilter,
    search,
  }) => {
    if (!page) {
      page = 1;
    }
    if (!sort) {
      sort = 'desc';
    }
    // Build the base URL
    let url = '/alerts/get-alert-nvs';

    if (analyticsSelection !== 'All' && analyticsSelection) {
      url += `/${analyticsSelection}`;
    }
    // Build query parameters based on conditions
    const queryParams = [];
    if (cameraSelection) {
      queryParams.push(`cameraSelection=${cameraSelection}`);
    }
    if (selectedClasses) {
      queryParams.push(`selectedClasses=${selectedClasses}`);
    }
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (sort) {
      queryParams.push(`sortOrder=${sort}`);
    }
    if (cameraId) {
      queryParams.push(`camera_id=${cameraId}`);
    }

    if (dateFilter) {
      queryParams.push(`dateFilter=${JSON.stringify(dateFilter)}`);
    }

    if (search) {
      queryParams.push(`search=${search}`);
    }

    // Append query parameters to the URL if there are any
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    // Make the request using the built URL
    const response = await axios.get(url);
    return response.data;
  },
  getAlertsReportsPdf: async ({
    analyticsSelection,
    selectedClasses,
    cameraSelection,
    page,
    sort,
    cameraId = null,
    dateFilter,
    search,
    filters,
    selectedColumns,
  }) => {
    if (!page) {
      page = 1;
    }
    if (!sort) {
      sort = 'desc';
    }
    // Build the base URL
    let url = `/alerts/get-alert-nvs-reports-pdf`;

    if (analyticsSelection !== 'All' && analyticsSelection) {
      url += `/${analyticsSelection}`;
    }
    // Build query parameters based on conditions
    const queryParams = [];
    if (cameraSelection) {
      queryParams.push(`cameraSelection=${cameraSelection}`);
    }
    if (selectedClasses) {
      queryParams.push(`selectedClasses=${selectedClasses}`);
    }
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (sort) {
      queryParams.push(`sortOrder=${sort}`);
    }
    if (cameraId) {
      queryParams.push(`camera_id=${cameraId}`);
    }

    if (dateFilter) {
      queryParams.push(`dateFilter=${JSON.stringify(dateFilter)}`);
    }

    if (search) {
      queryParams.push(`search=${search}`);
    }

    if (filters) {
      queryParams.push(`filters=${JSON.stringify(filters)}`);
    }

    if (selectedColumns) {
      queryParams.push(`selectedColumns=${JSON.stringify(selectedColumns)}`);
    }

    // Append query parameters to the URL if there are any
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    // Make the request using the built URL
    const response = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    return response.data;
  },
  getAlertsReportsExcel: async ({
    analyticsSelection,
    selectedClasses,
    cameraSelection,
    page,
    sort,
    cameraId = null,
    dateFilter,
    search,
    filters,
    selectedColumns,
  }) => {
    if (!page) {
      page = 1;
    }
    if (!sort) {
      sort = 'desc';
    }
    // Build the base URL
    let url = `/alerts/get-alert-nvs-reports-excel`;

    if (analyticsSelection !== 'All' && analyticsSelection) {
      url += `/${analyticsSelection}`;
    }
    // Build query parameters based on conditions
    const queryParams = [];
    if (cameraSelection) {
      queryParams.push(`cameraSelection=${cameraSelection}`);
    }
    if (selectedClasses) {
      queryParams.push(`selectedClasses=${selectedClasses}`);
    }
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (sort) {
      queryParams.push(`sortOrder=${sort}`);
    }
    if (cameraId) {
      queryParams.push(`camera_id=${cameraId}`);
    }

    if (dateFilter) {
      queryParams.push(`dateFilter=${JSON.stringify(dateFilter)}`);
    }

    if (search) {
      queryParams.push(`search=${search}`);
    }

    if (filters) {
      queryParams.push(`filters=${JSON.stringify(filters)}`);
    }

    if (selectedColumns) {
      queryParams.push(`selectedColumns=${JSON.stringify(selectedColumns)}`);
    }

    // Append query parameters to the URL if there are any
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    // Make the request using the built URL
    const response = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    return response.data;
  },

  getDashboardAlerts: async ({ analytics, range }) => {
    // Build the base URL
    let url = `/alerts/get-dashboard-alert-nvs/${analytics}`;
    // Build query parameters based on conditions
    const queryParams = [];
    if (range) {
      queryParams.push(`range=${range}`);
    }
    // Append query parameters to the URL if there are any
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    // Make the request using the built URL
    const response = await axios.get(url);
    return response.data;
  },

  getDashboardCriticalAlerts: async () => {
    // Build the base URL
    let url = `/alerts/get-dashboard-critical-alert-nvs`;
    // Build query parameters based on conditions
    const response = await axios.get(url);
    return response.data;
  },

  getDashboardAlarms: async () => {
    // Build the base URL
    let url = `/alerts/get-alarms-nvs`;
    // Build query parameters based on conditions
    const response = await axios.get(url);
    return response.data;
  },

  seenAlerts: async (alertIds) => {
    // Build the base URL
    let url = `/alerts/seen-alarms`;
    // Build query parameters based on conditions
    const response = await axios.post(url, { alertIds });
    return response.data;
  },

  getVsAlerts: async ({ page, sort, dateFilter, analytics, search }) => {
    if (!page) {
      page = 1;
    }
    if (!sort) {
      sort = 'desc';
    }
    let url = '/alerts/get-alert-vs';
    // Build query parameters based on conditions
    const queryParams = [];
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (sort) {
      queryParams.push(`sortOrder=${sort}`);
    }
    if (dateFilter) {
      queryParams.push(`dateFilter=${JSON.stringify(dateFilter)}`);
    }
    if (analytics) {
      queryParams.push(`analytics=${JSON.stringify(analytics)}`);
    }
    if (search) {
      queryParams.push(`search=${search}`);
    }

    // Append query parameters to the URL if there are any
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
    // Make the request using the built URL
    const response = await axios.get(url);
    return response.data;
  },
};

export default AlertsService;
