import axios from '../Utils/api';

const VmsThirdPartyService = {
  createVMSThirdPartyConfig: (data) => {
    return axios.post('/vmsconfig/create', data);
  },
  getVmsConfigList: () => {
    return axios.get('/vmsconfig/get-vmsconfig-list');
  },
  getVmsConfigAvailability: () => {
    return axios.get('/vmsconfig/get-vmsconfig-availability');
  },
  getVmsConfigById: (id) => {
    return axios.get(`/vmsconfig/get-vmsconfig-by-id/${id}`);
  },
  editVmsConfig: (id, data) => {
    return axios.patch(`/vmsconfig/edit/${id}`, data);
  },
  deleteVmsConfig: (id) => {
    return axios.delete(`/vmsconfig/delete/${id}`);
  },
};

export default VmsThirdPartyService;
