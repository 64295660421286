import axios from '../Utils/api';

const StreamingService = {
  getCameraPreview: (url, cameraHash) => {
    return axios.get(
      `http://mirasys_stream.localhost/dev/api/service/streaming/getCameraPreview`,
      {
        headers: {
          rtspurl: url,
          camerahash: cameraHash,
        },
      }
    );
  },
};

export default StreamingService;
