import React, { useRef } from 'react';
import BaseModal from '../Components/Modal/BaseModal';
import CameraPreview from '../Components/CameraPreview';

function CameraPreviewModal({
  camera,
  cameraPreviewModalOpen,
  setCameraPreviewModalOpen,
}) {
  const playerRef = useRef(null);

  const handleClose = () => {
    if (playerRef.current) {
      if (playerRef.current.destroy) {
        playerRef.current.destroy();
      } else {
        console.warn('Player instance does not have a destroy method');
      }
      playerRef.current = null;
    }
    setCameraPreviewModalOpen(false);
  };

  return (
    <BaseModal
      fullWidth={true}
      maxWidth="lg"
      open={cameraPreviewModalOpen}
      onClose={handleClose}
      title={`Camera Preview`}
    >
      <CameraPreview
        onClose={handleClose}
        camera={camera}
        playerRef={playerRef}
      />
    </BaseModal>
  );
}

export default CameraPreviewModal;
