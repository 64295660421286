import React, { useEffect, useRef } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { ModalLayoutStyle } from '../Layouts/style';
import { loadPlayer } from 'rtsp-relay/browser';
import StreamingService from '../RestSevices/streamService';
import { calculateHeight, formatDate } from '../Utils/commonHelper';

const CameraPreview = ({ camera, playerRef,isZoneConfig=false }) => {
  const videoPreviewRef = useRef(null);
  const wsRef = useRef(null);

  useEffect(() => {
    const width = videoPreviewRef.current.parentElement?.clientWidth;
    videoPreviewRef.current.setAttribute('width', width);
    videoPreviewRef.current.setAttribute(
      'height',
      calculateHeight(width, 16 / 9)
    );

    const url = camera.rstp_url;
    StreamingService.getCameraPreview(url, camera.hash_id);

    let stream_url = process.env.REACT_APP_STREAM_URL;
    stream_url = stream_url.replace(/^https?:\/\//, '');

    const connectPlayer = async () => {
      try {
        const playerInstance = await loadPlayer({
          url: `ws://${stream_url}/dev/api/service/streaming/feed/${camera.hash_id}`,
          canvas: videoPreviewRef.current,
          disconnectThreshold: 150000,
          onDisconnect: () => {
            console.log('Connection lost.');
          },
          onPause:()=>{
            console.log('-------------------play is paused------------------------')
          },
          onPlay:()=>{
            console.log('-------------------play is resumed------------------------')
          }
        });

        videoPreviewRef.current.addEventListener('click',()=>{
          playerInstance.paused? playerInstance.play() : playerInstance.pause()
        })

        // Store the WebSocket reference
        wsRef.current = playerInstance.ws;

        // playerRef.current = playerInstance;
        if (videoPreviewRef.current) {
          videoPreviewRef.current.style.display = 'block';
        }
      } catch (error) {
        console.error('Error connecting to player:', error);
      }
    };

    connectPlayer();

    return () => {
      // Ensure the WebSocket is closed
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
    };
  }, [camera, playerRef]);

  return (
    <ModalLayoutStyle>
      <Box className="camera-preview-body">
        <Grid container>
          <Grid className="grid-item" item xl={12}>
            <div className="canvas-parent">
              <canvas id="canvas-video-preview" ref={videoPreviewRef} />
            </div>
          </Grid>
        </Grid>

       {!isZoneConfig && <Box className="camera-infos">
          <Typography variant="h4">Camera Information</Typography>
          <Box className="camera_info">
            <Typography variant="h5">Hash id</Typography>
            <Typography>{camera?.hash_id}</Typography>
          </Box>
          <Box className="camera_info">
            <Typography variant="h5">Camera id</Typography>
            <Typography>{camera?.camera_id}</Typography>
          </Box>
          <Box className="camera_info">
            <Typography variant="h5">Camera name</Typography>
            <Typography>{camera?.camera_name}</Typography>
          </Box>
          <Box className="camera_info">
            <Typography variant="h5">Creation date</Typography>
            <Typography>{formatDate(camera?.creation_date)}</Typography>
          </Box>
        </Box>}
      </Box>
    </ModalLayoutStyle>
  );
};

export default CameraPreview;
