import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/navigation'; // If you're using navigation
import 'swiper/css/pagination'; // If you're using pagination

const AlertDetailsModal = ({ open, handleClose, alertData }) => {
  const imagePath = process.env.REACT_APP_IMAGE_URL;

  // Parsing metaData if it's a JSON string
  const metaData = alertData.metaData ? JSON.parse(alertData.metaData) : {};

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Alert Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* General Information */}
          <Grid item xs={12}>
            <Typography variant="h6">General Information</Typography>
            <Box display="flex" flexDirection="column" gap={1} mt={1}>
              <Typography>
                <strong>Zone Name:</strong> {alertData.zone_name}
              </Typography>
              <Typography>
                <strong>Camera Name:</strong> {alertData.camera_name}
              </Typography>
              <Typography>
                <strong>Analytic Type:</strong> {alertData.analytic_type}
              </Typography>
              <Typography>
                <strong>Analytic ID:</strong> {alertData.analytic_id}
              </Typography>
              <Typography>
                <strong>Zone ID:</strong> {alertData.zone_id}
              </Typography>
              <Typography>
                <strong>Timestamp:</strong>{' '}
                {new Date(alertData.timestamp * 1000).toLocaleString()}
              </Typography>
              <Typography>
                <strong>Classes:</strong> {alertData.classes}
              </Typography>
            </Box>
          </Grid>

          {/* Camera and Speed Information */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Camera & Speed Information</Typography>
            <Box display="flex" flexDirection="column" gap={1} mt={1}>
              <Typography>
                <strong>Camera ID:</strong> {alertData.camera_id}
              </Typography>
              <Typography>
                <strong>ANPR Camera:</strong> {alertData.anpr_cam}
              </Typography>
              <Typography>
                <strong>Speed:</strong> {alertData.speed} km/h
              </Typography>
            </Box>
          </Grid>

          {/* Additional Attributes */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Additional Attributes</Typography>
            <Box display="flex" flexDirection="column" gap={1} mt={1}>
              <Typography>
                <strong>OCR:</strong> {alertData.ocr}
              </Typography>
              <Typography>
                <strong>Confidence:</strong> {alertData.confidence}
              </Typography>
              <Typography>
                <strong>Attribute:</strong> {alertData.attribute}
              </Typography>
              <Typography>
                <strong>Can Generate Challan:</strong>{' '}
                {metaData.canGenerateChallan ? 'Yes' : 'No'}
              </Typography>
            </Box>
          </Grid>

          {/* Thumbnail and Image URLs */}
          <Grid item xs={12}>
            {alertData.image_url && alertData.image_url.length > 0 && (
              <Box>
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  style={{ width: '100%', height: 'auto' }}
                >
                  {alertData.image_url.map((url, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={`${imagePath}/${encodeURIComponent(url)}`}
                        alt={`Alert ${index + 1}`}
                        style={{ width: '100%', borderRadius: '8px' }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button onClick={handleClose} variant="contained" color="primary">
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

export default AlertDetailsModal;
