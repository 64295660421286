import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Box,
  Button,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const AlertDetailsModal = ({ open, handleClose, alertData }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const imagePath = process.env.REACT_APP_IMAGE_URL;

  const handlePrev = () => {
    const newIndex =
      currentImage === 0 ? alertData.image_url.length - 1 : currentImage - 1;
    setCurrentImage(newIndex);
  };

  const handleNext = () => {
    const newIndex =
      currentImage === alertData.image_url.length - 1 ? 0 : currentImage + 1;
    setCurrentImage(newIndex);
  };

  // Parsing metaData if it's a JSON string
  const metaData = alertData.metaData ? JSON.parse(alertData.metaData) : {};
  const imageUrl = alertData.image_url
    ? `${imagePath}/${encodeURIComponent(
        alertData.image_url[currentImage].trim()
      )}`
    : '';
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle style={{ fontSize: '25px' }}>Alert Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* General Information */}
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontSize: '19px' }}>
              General Information
            </Typography>
            <Box
              display="flex"
              sx={{
                flexWrap: 'wrap',
                padding: '20px 25px',
                background: 'rgba(0, 0, 0, 0.12)',
                borderRadius: '10px',
                justifyContent: 'space-between',
              }}
              gap={1}
              mt={1}
            >
              <Typography style={{ width: '49%', wordBreak: 'break-all' }}>
                <strong>Zone Name:</strong> {alertData.zone_name}
              </Typography>
              <Typography style={{ width: '49%', wordBreak: 'break-all' }}>
                <strong>Camera Name:</strong> {alertData.camera_name}
              </Typography>
              <Typography style={{ width: '49%', wordBreak: 'break-all' }}>
                <strong>Analytic Type:</strong> {alertData.analytic_type}
              </Typography>
              <Typography style={{ width: '49%', wordBreak: 'break-all' }}>
                <strong>Analytic ID:</strong> {alertData.analytic_id}
              </Typography>
              <Typography style={{ width: '49%', wordBreak: 'break-all' }}>
                <strong>Zone ID:</strong> {alertData.zone_id}
              </Typography>
              <Typography style={{ width: '49%', wordBreak: 'break-all' }}>
                <strong>Timestamp:</strong>{' '}
                {new Date(alertData.timestamp * 1000).toLocaleString()}
              </Typography>
              <Typography style={{ width: '49%', wordBreak: 'break-all' }}>
                <strong>Classes:</strong> {alertData.classes}
              </Typography>
            </Box>
          </Grid>

          {/* Camera and Speed Information */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{ fontSize: '19px' }}>
              Camera & Speed Information
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              mt={1}
              sx={{
                padding: '20px 25px',
                background: 'rgba(0, 0, 0, 0.12)',
                borderRadius: '10px',
              }}
            >
              <Typography>
                <strong>Camera ID:</strong> {alertData.camera_id}
              </Typography>
              <Typography>
                <strong>ANPR Camera:</strong> {alertData.anpr_cam}
              </Typography>
              <Typography>
                <strong>Speed:</strong> {alertData.speed} km/h
              </Typography>
            </Box>
          </Grid>

          {/* Additional Attributes */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{ fontSize: '19px' }}>
              Additional Attributes
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              mt={1}
              sx={{
                padding: '20px 25px',
                background: 'rgba(0, 0, 0, 0.12)',
                borderRadius: '10px',
              }}
            >
              <Typography>
                <strong>OCR:</strong> {alertData.ocr}
              </Typography>
              <Typography>
                <strong>Confidence:</strong> {alertData.confidence}
              </Typography>
              <Typography>
                <strong>Attribute:</strong> {alertData.attribute}
              </Typography>
              <Typography>
                <strong>Can Generate Challan:</strong>{' '}
                {metaData.canGenerateChallan ? 'Yes' : 'No'}
              </Typography>
            </Box>
          </Grid>

          {/* Thumbnail and Image URLs */}
          <Grid item xs={12}>
            {/* {alertData.image_url && alertData.image_url.length > 0 && ( */}
            <Box>
              {/* <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  style={{ width: '100%', height: 'auto' }}
                >
                  {alertData.image_url.map((url, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={`${imagePath}/${encodeURIComponent(url.trim())}`}
                        alt={`Alert ${index + 1}`}
                        style={{ width: '100%', borderRadius: '8px' }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper> */}
              <div
                className="image-gallery-container"
                style={{
                  width: '100%',
                  height: '100%',
                  margin: '0 auto',
                  position: 'relative',
                  maxWidth: 'unset',
                }}
              >
                {alertData.image_url && alertData.image_url.length > 1 && (
                  <button className="prev-btn" onClick={handlePrev}>
                    <ArrowBackIosNewIcon />
                  </button>
                )}
                <div
                  style={{
                    width: '100%',
                    overflow: 'auto',
                    height: '100%',
                  }}
                >
                  <div
                    className="image-container"
                    style={{ width: '100%', height: '100%' }}
                  >
                    <img
                      src={imageUrl}
                      alt=""
                      className="current-image"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                </div>

                {alertData.image_url && alertData.image_url.length > 1 && (
                  <button className="next-btn" onClick={handleNext}>
                    <ArrowForwardIosIcon />
                  </button>
                )}
              </div>
            </Box>
            {/* // )} */}
          </Grid>
        </Grid>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button onClick={handleClose} variant="contained" color="primary">
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

export default AlertDetailsModal;
