import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Chip,
  Link,
  ButtonGroup,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import moment from 'moment';
import BaseModal from '../Modal/BaseModal';
import ImageGalleryModal from '../ImageGallery';
import TimestampToDate from '../TimestampToDate';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { checkPermission } from '../../Utils/checkPermission';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AlertDetailsModal from '../Modal/AlertDetailsModal';

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow className="table_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.id === 'thumbnailUrl' ? 'Image' : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const AlertsDataTable = ({
  rows,
  headCells,
  columns,
  analyticsSelection,
  data = null,
  isViolation = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [alertClick, setAlertClick] = useState({});
  const [selectedAlert, setSelectedAlert] = useState({});
  const [open, setOpen] = useState(false);
  const rowsFetched = analyticsSelection ? rows : [];
  const visibleRows = rowsFetched;
  const imagePath = process.env.REACT_APP_IMAGE_URL;
  const generateChallan = (e, row) => {
    e.preventDefault();
    const doc = new jsPDF();
    const pdfContent = `
      Traffic E-Challan

      Challan Number: ${Math.floor(Math.random() * 900000000) + 100000000}
      Date: ${moment().format('YYYY-MM-DD HH:mm:ss')}
      Vehicle Number: ${row.ocr}
      Driver Name: John Doe
      License Number: DL-1234567890
      Violation: Over-speeding
      Fine Amount: 500 INR
      Issued By: Traffic Police Officer
      Payment Due Date: ${moment().add(48, 'h').format('YYYY-MM-DD HH:mm:ss')}
    `;

    const lines = pdfContent.split('\n');
    lines.forEach((line, index) => {
      doc.text(line, 10, 10 + index * 10);
    });

    // Save the PDF
    doc.save('generated_traffic_echallan.pdf');
  };

  const getStatusBatch = (row) => {
    if (row.metaData) {
      const meta = JSON.parse(row?.metaData);
      if (meta && meta.isCritical) {
        // returh blacklist chip
        return <Chip label="Blacklist" color="error" />;
      } else if (!meta.canGenerateChallan) {
        // return whitelist chip
        return <Chip label="Whitelist" color="success" />;
      }
    }
  };

  const handleClickOpen = (alert) => {
    setSelectedAlert(alert);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableContainer sx={{ maxHeight: '36rem' }}>
      {rowsFetched.length === 0 ? (
        <Typography
          variant="h4"
          component="div"
          style={{ textAlign: 'center' }}
        >
          {!analyticsSelection
            ? 'Please select an analytics'
            : 'No alerts found'}
        </Typography>
      ) : (
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
          className="datatable_common"
        >
          <EnhancedTableHead rowCount={rows.length} headCells={headCells} />
          <TableBody>
            {visibleRows.map((row) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row._id}
                  sx={{ cursor: 'pointer' }}
                  className={data?._id === row._id ? 'selected_row' : ''}
                >
                  {columns.map((col, index) => (
                    <TableCell
                      align={index === 0 ? 'left' : 'right'}
                      key={`${col}-${index}`}
                      sx={{ minWidth: '220px' }}
                    >
                      {col === 'thumbnailUrl' ? (
                        <Box className="datatable_thumbnail">
                          <IconButton
                            sx={{ position: 'relative' }}
                            onClick={() => {
                              setAlertClick(row);
                              setModalIsOpen(true);
                            }}
                          >
                            <img
                              src={`${imagePath}/${encodeURIComponent(
                                row.thumb_url[0]
                              )}`}
                            />
                            {row.image_url.length > 1 && (
                              <Typography
                                sx={(theme) => ({
                                  color: 'aquamarine',
                                  // position: 'absolute',
                                  // top: '45%',
                                  // right: '0.7rem',
                                  marginLeft: '0.5rem',
                                  width: '1.8rem',
                                  height: '1.8rem',
                                  borderRadius: '100%',
                                  backgroundColor: theme.colors.color.color3,
                                  color: theme.colors.invertColor.color1,
                                })}
                              >{`+${row.image_url.length - 1}`}</Typography>
                            )}
                          </IconButton>
                          {/* <IconButton>{row[col]}</IconButton> */}
                        </Box>
                      ) : col === 'video_url' ? (
                        <Typography>
                          <Link
                            href={row.video_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            See Video
                          </Link>
                        </Typography>
                      ) : col === 'cameraName' ? (
                        <Box>
                          <Typography>{row.camera_name}</Typography>
                        </Box>
                      ) : col === 'zoneName' ? (
                        <Box>
                          <Typography>{row.zone_name}</Typography>
                        </Box>
                      ) : col === 'timestamp' ? (
                        <Box>
                          <Typography>
                            <TimestampToDate timestamp={row[col]} />
                          </Typography>
                        </Box>
                      ) : col === 'ocr' && isViolation ? (
                        <Box>
                          <Box sx={{ textAlign: 'center' }}>
                            {getStatusBatch(row)}
                          </Box>
                          {row[col]}
                        </Box>
                      ) : col === 'action' ? (
                        <>
                          <ButtonGroup
                            variant="contained"
                            aria-label="Basic button group"
                          >
                            {isViolation &&
                              JSON.parse(row?.metaData)?.canGenerateChallan &&
                              checkPermission('generate_challan') && (
                                <Tooltip title="Generate Challan">
                                  <Button
                                    onClick={(e) => generateChallan(e, row)}
                                  >
                                    <ReceiptLongIcon />
                                  </Button>
                                </Tooltip>
                              )}
                            <Tooltip title="View Alert">
                              <Button onClick={() => handleClickOpen(row)}>
                                {' '}
                                <RemoveRedEyeIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Edit Alert">
                              <Button disabled>
                                <EditIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Reject Challan Genenration">
                              <Button disabled>
                                <CancelPresentationIcon />
                              </Button>
                            </Tooltip>
                          </ButtonGroup>
                        </>
                      ) : (
                        row[col]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <ImageGalleryModal
        alert={alertClick}
        open={modalIsOpen}
        expand={expand}
        className="alert_modal"
        onClose={() => {
          setModalIsOpen(false);
          setExpand(false);
        }}
        onExpand={() => setExpand(!expand)}
        images={alertClick?.image_url}
      />
      <AlertDetailsModal
        open={open}
        handleClose={() => setOpen(false)}
        alertData={selectedAlert}
      />
    </TableContainer>
  );
};

export default AlertsDataTable;
