import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { LeftArrow } from '../../../Icons/SVGContainer';
import { PermissionStyle } from '../../../Layouts/style';
import PermissionDataTable from '../../../Components/DataTable/PermissionDatatable';
import CameraService from '../../../RestSevices/cameraService';
import { getAllPermissions, getRole } from '../../../graphQL/Role/query';
import { useNavigate, useParams } from 'react-router-dom';
import { assignPermission } from '../../../graphQL/Role/mutation';
import ApplicationService from '../../../RestSevices/applicationService';
import { type } from '@testing-library/user-event/dist/type';
import useAuth from '../../../Redux/Actions/authActions';
import { toast } from 'react-toastify';
import AnalyticsService from '../../../RestSevices/analyticsService';

const PermissionView = () => {
  const { id } = useParams();

  const [role, setRole] = useState(null);
  const [cameraList, setCameraList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [analyticsList, setAnalyticsList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const fetchCamera = () => {
    CameraService.getAllCameraList()
      .then((response) => {
        setCameraList(response.data);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const fetchApplication = () => {
    ApplicationService.getAllApplicationsList()
      .then((response) => {
        setApplicationList(response.data);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  const fetchAnalytics = () => {
    AnalyticsService.getAllAnalyticsList()
      .then((response) => {
        const allAnalytics = response.data;
        if (allAnalytics?.length) {
          setAnalyticsList(
            allAnalytics.map((analytics) => {
              return {
                ...analytics,
                id: analytics.analytic_id,
              };
            })
          );
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  const fetchPermissions = async () => {
    try {
      const resp = await getAllPermissions();
      setPermissionList(resp.data.getAllPermissions.permissions);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchRole = async () => {
    try {
      const resp = await getRole(id);
      setRole(resp.data.getRole);
      const allPermissions = [];
      resp.data.getRole.rolePermissions.forEach((rolePermission) => {
        let newPermission = {
          permissionId: rolePermission.permission.id,
          objectId: null,
          objectType: null,
        };
        if (rolePermission.rolePermissionObjects) {
          newPermission.objectId =
            rolePermission.rolePermissionObjects.objectId;
          newPermission.objectType =
            rolePermission.rolePermissionObjects.objectType;
        }
        allPermissions.push(newPermission);
      });
      setPermissions(allPermissions);
    } catch (error) {
      console.log(error);
    }
  };

  const checkIfChecked = (obj) => {
    for (let i = 0; i < permissions.length; i++) {
      if (
        Object.keys(permissions[i]).every(
          (key) => permissions[i][key] == obj[key]
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const handleAddCheckboxClick = (
    permissionId,
    objectId = null,
    objectType = null
  ) => {
    let selectedPermission = { permissionId, objectId, objectType };
    let index = -1;

    for (let i = 0; i < permissions.length; i++) {
      if (
        Object.keys(permissions[i]).every(
          (key) => permissions[i][key] == selectedPermission[key]
        )
      ) {
        index = i;
        break;
      }
    }

    if (index !== -1) {
      let updatedPermissions = [...permissions];
      updatedPermissions = updatedPermissions.filter((value, ind) => {
        return ind !== index;
      });
      setPermissions(updatedPermissions);
    } else {
      setPermissions([...permissions, selectedPermission]);
    }
  };

  const filterByIndex = (permissions, index) =>
    permissions.filter((_, ind) => !index.includes(ind));

  const filterByObjectIdAndType = (permissions, objectId, objectType) =>
    permissions.filter(
      (value) => value.objectId !== objectId || value.objectType !== objectType
    );

  const filterBySlug = (permissions, permissionList) => {
    const userEditDeleteIdArr = permissionList
      .filter(
        (value) => value.slug === 'edit_user' || value.slug === 'delete_user'
      )
      .map((val) => val.id);

    return permissions.filter(
      (value) =>
        (value.objectId && value.objectType) ||
        !userEditDeleteIdArr.includes(value.permissionId)
    );
  };

  const isViewPermission = (slug) =>
    ['view_camera', 'view_details', 'view_user', 'view_analytic'].includes(
      slug
    );

  const findPermissionIndex = (permissions, selectedPermission) => {
    return permissions.reduce((indices, perm, i) => {
      if (
        Object.keys(perm).every((key) => perm[key] == selectedPermission[key])
      ) {
        indices.push(i);
      }
      return indices;
    }, []);
  };

  const handleCheckboxClick = (
    permissionId,
    objectId = null,
    objectType = null
  ) => {
    const selectedPermission = { permissionId, objectId, objectType };

    const index = getMatchingIndices(permissions, selectedPermission);
    const isViewSelected = checkIsViewSelected(permissionId);

    if (index.length) {
      handleExistingPermission(index, objectId, objectType, isViewSelected);
    } else {
      handleNewPermission(
        selectedPermission,
        objectId,
        objectType,
        isViewSelected
      );
    }
  };

  const getMatchingIndices = (permissions, selectedPermission) => {
    return permissions.reduce((indices, permission, i) => {
      if (
        Object.keys(permission).every(
          (key) => permission[key] == selectedPermission[key]
        )
      ) {
        indices.push(i);
      }
      return indices;
    }, []);
  };

  const checkIsViewSelected = (permissionId) => {
    const selected = permissionList.find(
      (permission) => permission.id == permissionId
    );
    return [
      'view_camera',
      'view_details',
      'view_user',
      'view_analytic',
    ].includes(selected.slug);
  };

  const handleExistingPermission = (
    index,
    objectId,
    objectType,
    isViewSelected
  ) => {
    let updatedPermissions = filterByIndex([...permissions], index);

    if (objectType) {
      if (isViewSelected) {
        updatedPermissions = filterByObjectIdAndType(
          updatedPermissions,
          objectId,
          objectType
        );
      }
    } else if (isViewSelected) {
      updatedPermissions = filterBySlug(updatedPermissions, permissionList);
    }

    setPermissions(updatedPermissions);
  };

  const handleNewPermission = (
    selectedPermission,
    objectId,
    objectType,
    isViewSelected
  ) => {
    if (!isViewSelected) {
      const viewPermission = getViewPermission(objectType);
      const newPermission = {
        permissionId: viewPermission.id,
        objectId,
        objectType,
      };

      if (!isPermissionExist(newPermission)) {
        setPermissions([...permissions, selectedPermission, newPermission]);
      } else {
        setPermissions([...permissions, selectedPermission]);
      }
    } else {
      setPermissions([...permissions, selectedPermission]);
    }
  };

  const getViewPermission = (objectType) => {
    return permissionList.find((permission) => {
      switch (objectType) {
        case 'camera':
          return permission.slug == 'view_camera';
        case 'application':
          return permission.slug == 'view_details';
        case 'analytic':
          return permission.slug == 'view_analytic';
        default:
          return permission.slug == 'view_user';
      }
    });
  };

  const isPermissionExist = (newPermission) => {
    return permissions.some((permission) => {
      return Object.keys(permission).every(
        (key) => permission[key] == newPermission[key]
      );
    });
  };

  const getPermissionsByObjectType = (selectedPermission, objectType) => {
    let permissions = [];
    if (objectType === 'camera') {
      permissions = cameraList.map((camera) => ({
        permissionId: selectedPermission.id,
        objectId: camera.id,
        objectType: 'camera',
      }));
    } else if (objectType === 'application') {
      permissions = applicationList.map((application) => ({
        permissionId: selectedPermission.id,
        objectId: application.id,
        objectType: 'application',
      }));
    } else if (objectType === 'analytic') {
      permissions = analyticsList.map((analytic) => ({
        permissionId: selectedPermission.id,
        objectId: analytic.id,
        objectType: 'analytic',
      }));
    }
    return permissions;
  };

  const checkPermissionsExistence = (allPermissions, permissions) => {
    return allPermissions.every((perm) =>
      permissions.some(
        (p) =>
          p.permissionId === perm.permissionId && p.objectId === perm.objectId
      )
    );
  };

  const checkIfAllSelected = (
    permissionId,
    objectType = null,
    allPermissions = []
  ) => {
    const selectedPermission = permissionList.find(
      (permission) => permission.id === permissionId
    );
    if (!selectedPermission) return false;

    if (allPermissions.length === 0) {
      allPermissions = getPermissionsByObjectType(
        selectedPermission,
        objectType
      );
    }

    return checkPermissionsExistence(allPermissions, permissions);
  };

  const handleAllCheckboxClick = (permissionId, objectType = null) => {
    const selectedPermission = getPermissionById(permissionId);
    let allPermissions = generateAllPermissions(objectType, selectedPermission);

    let allSelected = checkIfAllSelected(
      permissionId,
      objectType,
      allPermissions
    );

    if (!allSelected) {
      handlePermissionsAddition(allPermissions, objectType, permissionId);
    } else {
      handlePermissionsRemoval(allPermissions, permissionId, objectType);
    }
  };

  const getPermissionById = (permissionId) => {
    return permissionList.find((permission) => permission.id == permissionId);
  };

  const generateAllPermissions = (objectType, selectedPermission) => {
    let allPermissions = [];
    const objectList = getObjectListByType(objectType);

    objectList.forEach((object) => {
      allPermissions.push({
        permissionId: selectedPermission.id,
        objectId: object.id,
        objectType,
      });
    });

    return allPermissions;
  };

  const getObjectListByType = (objectType) => {
    switch (objectType) {
      case 'camera':
        return cameraList;
      case 'application':
        return applicationList;
      case 'analytic':
        return analyticsList;
      default:
        return [];
    }
  };

  const handlePermissionsAddition = (
    allPermissions,
    objectType,
    permissionId
  ) => {
    let selectedPermissions = [...permissions];
    allPermissions.forEach((permission) => {
      if (!isPermissionExist(permission, selectedPermissions)) {
        selectedPermissions.push(permission);
      }
    });

    const viewPermission = getViewPermission(objectType);
    let allViewPermissions = generateAllPermissions(objectType, viewPermission);

    allViewPermissions.forEach((permission) => {
      if (!isPermissionExist(permission, selectedPermissions)) {
        selectedPermissions.push(permission);
      }
    });

    setPermissions(selectedPermissions);
  };

  const handlePermissionsRemoval = (
    allPermissions,
    permissionId,
    objectType
  ) => {
    let selectedPermissions = [...permissions];
    const indexes = getIndexesToRemove(allPermissions, selectedPermissions);

    selectedPermissions = selectedPermissions.filter(
      (_, index) => !indexes.includes(index)
    );

    if (isViewSelected(permissionId)) {
      selectedPermissions = selectedPermissions.filter(
        (permission) => permission.objectType != objectType
      );
    }

    setPermissions(selectedPermissions);
  };

  const getIndexesToRemove = (allPermissions, selectedPermissions) => {
    let indexes = [];
    allPermissions.forEach((permission) => {
      selectedPermissions.forEach((existingPermission, index) => {
        if (
          existingPermission.permissionId == permission.permissionId &&
          existingPermission.objectId == permission.objectId
        ) {
          indexes.push(index);
        }
      });
    });
    return indexes;
  };

  const isViewSelected = (permissionId) => {
    const selected = permissionList.find(
      (permission) => permission.id == permissionId
    );
    return [
      'view_camera',
      'view_details',
      'view_analytic',
      'view_user',
    ].includes(selected.slug);
  };

  const handleSubmit = async (e) => {
    const resp = await assignPermission({
      roleId: id,
      permissions: permissions,
    });
    if (resp) {
      navigate(`/role-list`, { replace: true });
    }
  };

  useEffect(() => {
    fetchPermissions();
    fetchCamera();
    fetchApplication();
    fetchAnalytics();
    fetchRole();
  }, []);
  return (
    <PermissionStyle>
      <Box className="page_heading">
        <IconButton onClick={() => navigate(-1)}>
          <LeftArrow /> Permission
        </IconButton>
      </Box>
      <Box className="permission_heading">
        <Box className="permission_name_hold">
          <Typography component="h3">Role Name</Typography>
        </Box>
        <Box className="permission_name_hold_right">
          <Typography component="h3">{role?.title}</Typography>
        </Box>
      </Box>
      <Box className="permission_box_con">
        <Box className="permission_name">
          <Typography component="h3">User Management</Typography>
        </Box>
        <Box className="permission_box">
          <Box className="permission_box_con">
            <FormGroup>
              {permissionList.map(
                (permission) =>
                  permission.type == 'user' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() =>
                            permission.slug == 'add_user'
                              ? handleAddCheckboxClick(permission.id)
                              : handleCheckboxClick(permission.id)
                          }
                          checked={checkIfChecked({
                            permissionId: permission.id,
                            objectId: null,
                            objectType: null,
                          })}
                        />
                      }
                      label={permission.title}
                    />
                  )
              )}
              {/* <FormControlLabel control={<Checkbox />} label="Add Camera" />
              <FormControlLabel control={<Checkbox />} label="Edit Camera" />
              <FormControlLabel control={<Checkbox />} label="Delete Camera" /> */}
            </FormGroup>
          </Box>
        </Box>
      </Box>
      <Box className="permission_box_con">
        <Box className="permission_name">
          <Typography component="h3">Dashboard</Typography>
        </Box>
        <Box className="permission_box">
          <Box className="permission_box_con">
            <FormGroup>
              {permissionList.map(
                (permission) =>
                  permission.type == 'dashboard' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleAddCheckboxClick(permission.id)}
                          checked={checkIfChecked({
                            permissionId: permission.id,
                            objectId: null,
                            objectType: null,
                          })}
                        />
                      }
                      label={permission.title}
                    />
                  )
              )}
              {/* <FormControlLabel control={<Checkbox />} label="Add Camera" />
              <FormControlLabel control={<Checkbox />} label="Edit Camera" />
              <FormControlLabel control={<Checkbox />} label="Delete Camera" /> */}
            </FormGroup>
          </Box>
        </Box>
      </Box>

      <Box className="permission_box_con">
        <Box className="permission_name">
          <Typography component="h3">Camera</Typography>
        </Box>
        <Box className="permission_box camera_datatable">
          <Box className="permission_box_con">
            <FormGroup>
              {permissionList.map(
                (permission) =>
                  permission.type == 'camera' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleAddCheckboxClick(permission.id)}
                          checked={checkIfChecked({
                            permissionId: permission.id,
                            objectId: null,
                            objectType: null,
                          })}
                        />
                      }
                      label={permission.title}
                    />
                  )
              )}
            </FormGroup>
          </Box>
          <PermissionDataTable
            rows={cameraList}
            columns={permissionList}
            handleAllCheckboxClick={handleAllCheckboxClick}
            handleCheckboxClick={handleCheckboxClick}
            checkIfChecked={checkIfChecked}
            checkIfAllSelected={checkIfAllSelected}
            type="camera_actions"
            objectType="camera"
          />
        </Box>
      </Box>
      <Box className="permission_box_con">
        <Box className="permission_name">
          <Typography component="h3">Application</Typography>
        </Box>
        <Box className="permission_box">
          <PermissionDataTable
            rows={applicationList}
            columns={permissionList}
            handleAllCheckboxClick={handleAllCheckboxClick}
            handleCheckboxClick={handleCheckboxClick}
            checkIfChecked={checkIfChecked}
            checkIfAllSelected={checkIfAllSelected}
            type="application"
            objectType="application"
          />
        </Box>
      </Box>

      <Box className="permission_box_con">
        <Box className="permission_name">
          <Typography component="h3">Analytics</Typography>
        </Box>
        <Box className="permission_box">
          <PermissionDataTable
            rows={analyticsList}
            columns={permissionList}
            handleAllCheckboxClick={handleAllCheckboxClick}
            handleCheckboxClick={handleCheckboxClick}
            checkIfChecked={checkIfChecked}
            checkIfAllSelected={checkIfAllSelected}
            type="analytic"
            objectType="analytic"
          />
        </Box>
      </Box>

      <Box className="permission_box_con">
        <Box className="permission_name">
          <Typography component="h3">Alert</Typography>
        </Box>
        <Box className="permission_box">
          <Box className="permission_box_con">
            <FormGroup>
              {permissionList.map(
                (permission) =>
                  permission.type == 'alert' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleAddCheckboxClick(permission.id)}
                          checked={checkIfChecked({
                            permissionId: permission.id,
                            objectId: null,
                            objectType: null,
                          })}
                        />
                      }
                      label={permission.title}
                    />
                  )
              )}
              {/* <FormControlLabel control={<Checkbox />} label="Add Camera" />
              <FormControlLabel control={<Checkbox />} label="Edit Camera" />
              <FormControlLabel control={<Checkbox />} label="Delete Camera" /> */}
            </FormGroup>
          </Box>
        </Box>
      </Box>

      <Box className="permission_box_con">
        <Box className="permission_name">
          <Typography component="h3">Report</Typography>
        </Box>
        <Box className="permission_box">
          <Box className="permission_box_con">
            <FormGroup>
              {permissionList.map(
                (permission) =>
                  permission.type == 'report' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleAddCheckboxClick(permission.id)}
                          checked={checkIfChecked({
                            permissionId: permission.id,
                            objectId: null,
                            objectType: null,
                          })}
                        />
                      }
                      label={permission.title}
                    />
                  )
              )}
              {/* <FormControlLabel control={<Checkbox />} label="Add Camera" />
              <FormControlLabel control={<Checkbox />} label="Edit Camera" />
              <FormControlLabel control={<Checkbox />} label="Delete Camera" /> */}
            </FormGroup>
          </Box>
        </Box>
      </Box>

      <Box className="permission_box_con">
        <Box className="permission_name">
          <Typography component="h3">Challan</Typography>
        </Box>
        <Box className="permission_box">
          <Box className="permission_box_con">
            <FormGroup>
              {permissionList.map(
                (permission) =>
                  permission.type == 'challan' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => handleAddCheckboxClick(permission.id)}
                          checked={checkIfChecked({
                            permissionId: permission.id,
                            objectId: null,
                            objectType: null,
                          })}
                        />
                      }
                      label={permission.title}
                    />
                  )
              )}
              {/* <FormControlLabel control={<Checkbox />} label="Add Camera" />
              <FormControlLabel control={<Checkbox />} label="Edit Camera" />
              <FormControlLabel control={<Checkbox />} label="Delete Camera" /> */}
            </FormGroup>
          </Box>
        </Box>
      </Box>

      <Box className="add_btn">
        <Button type="submit" onClick={handleSubmit}>
          Save
        </Button>
      </Box>
    </PermissionStyle>
  );
};

export default PermissionView;
