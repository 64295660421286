import React, { useEffect, useState, useRef } from 'react';

import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Pagination, Stack } from '@mui/material';
import dayjs from 'dayjs';
import io from 'socket.io-client';
import { toast } from 'react-toastify';

import { UserStyle } from '../../../Layouts/style';
import AlertHeading from './AlertsHeading';
import AlertsDataTable from '../../../Components/DataTable/AlertsDataTable';
import AnalyticsService from '../../../RestSevices/analyticsService';
import CameraService from '../../../RestSevices/cameraService';
import AlertsService from '../../../RestSevices/alertService';
import useAuth from '../../../Redux/Actions/authActions';
import capitalizeFirstLetter from '../../../Utils/utilities';
import { useLocation } from 'react-router-dom';

const Alerts = () => {
  const { user, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [analyticsList, setAnalyticsList] = useState([]);
  const [analyticsSelection, SetAnalyticsSelection] = useState('');
  const analyticsSelectionRef = useRef();
  const [alerts, setAlerts] = useState([]);
  const [page, setPage] = useState(1);
  const pageRef = useRef(1);
  const [totalPages, setTotalPages] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterDateStart, setFilterDateStart] = useState(null);
  const filterDateStartRef = useRef(null);
  const [filterDateEnd, setFilterDateEnd] = useState(null);
  const filterDateEndRef = useRef(null);
  const [serachTerm, setSearchTerm] = useState('');
  const searchTermRef = useRef();
  const [displayFields, setDisplayFields] = useState([]);
  const [camerasByAnalytics, setCamerasByAnalytics] = useState([]);
  const [cameraSelection, setCameraSelection] = useState([]);
  const cameraSelectionRef = useRef();
  const [selectedClasses, setSelectedClasses] = useState([]);
  const selectedClassesRef = useRef();
  const location = useLocation();
  const [data, setData] = useState(location.state);
  const classSelectionRef = useRef();
  const [isViolation, setIsViolation] = useState(false);
  const [isAutoRefresh, setIsAutoRefresh] = useState(() => {
    const storedValue = localStorage.getItem(`autoRefreshStatus_${user.id}`);
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const autoRefreshRef = useRef(isAutoRefresh);
  console.log('111searchTerm111', serachTerm);
  console.log('111searchTermRef.current111', searchTermRef.current);

  console.log('111user111', user);

  useEffect(() => {
    autoRefreshRef.current = isAutoRefresh;
    localStorage.setItem(
      `autoRefreshStatus_${user.id}`,
      JSON.stringify(isAutoRefresh)
    );
    // localStorage.setItem(`autoRefreshStatus_${user.id}`, autoRefreshStatus);
    // autoRefreshStatusRef.current = autoRefreshStatus;
  }, [isAutoRefresh]);
  useEffect(() => {
    setSelectedClasses([]);
    // localStorage.setItem('selectedClasses', []);
  }, [analyticsSelection]);

  useEffect(() => {
    selectedClassesRef.current = selectedClasses;
  }, [selectedClasses]);

  useEffect(() => {
    console.log('test');
    setPage(1);
  }, [selectedClasses, cameraSelection, toDate, fromDate]);

  useEffect(() => {
    analyticsSelectionRef.current = analyticsSelection;
  }, [analyticsSelection]);

  useEffect(() => {
    classSelectionRef.current = selectedClasses;
  }, [selectedClasses]);

  useEffect(() => {
    console.log('updated');
    cameraSelectionRef.current = cameraSelection;
  }, [cameraSelection]);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  useEffect(() => {
    searchTermRef.current = serachTerm;
  }, [serachTerm]);

  useEffect(() => {
    filterDateStartRef.current = filterDateStart;
  }, [filterDateStart]);

  useEffect(() => {
    filterDateEndRef.current = filterDateEnd;
  }, [filterDateEnd]);

  // console.log('autoRefreshStatusRef.current', autoRefreshStatusRef.current)
  // console.log('autoRefreshStatus', autoRefreshStatus)

  const refreshStatus = localStorage.getItem(`autoRefreshStatus_${user.id}`);
  console.log('refreshStatus', refreshStatus);

  useEffect(() => {
    console.log('checking_data_while_socket', autoRefreshRef.current);
    if (autoRefreshRef.current) {
      console.log('socketUrl', process.env.REACT_APP_API_SOCKET_URL);
      const socketUrl = process.env.REACT_APP_API_SOCKET_URL;
      const socket = io(socketUrl);

      // Handle socket connection
      socket.on('connect', () => {
        console.log('Socket connected');
      });

      // Handle socket disconnection
      socket.on('disconnect', () => {
        console.log('Socket disconnected');
      });

      // Handle socket errors
      socket.on('error', (error) => {
        console.error('Socket error:', error);
      });

      socket.on('alert_created', (data) => {
        // if (
        //   pageRef.current != 1 ||
        //   searchTermRef.current ||
        //   filterDateStartRef.current ||
        //   filterDateEndRef.current
        // ) {
        //   return;
        // }
        SetAnalyticsSelection(analyticsSelectionRef.current);
        // setPage(1);
        // setFromDate(null);
        // setToDate(null);
        // setSearchTerm('');
        console.log(
          'cameraSelectionRef.current222',
          cameraSelectionRef.current
        );
        console.log('classSelectionRef.current', classSelectionRef.current);
        // if (
        //   classSelectionRef.current &&
        //   classSelectionRef.current.length <= 0 &&
        //   cameraSelectionRef.current &&
        //   cameraSelectionRef.current.length <= 0 &&
        //   !filterDateStartRef.current &&
        //   !filterDateEndRef.current &&
        //   !serachTerm
        // ) {
        console.log('test_socket');
        fetchAlerts();
        // }
      });

      return () => {
        console.log('Socket disconnect');
        socket.disconnect();
      };
    }
  });

  useEffect(() => {
    fetchAnalytics();
    // get autorefresh data from localstorage for the current user
    // const autoRefreshData = localStorage.getItem(`autoRefreshStatus_${user.id}`);
    // console.log('autoRefreshData111', autoRefreshData)
    // if (autoRefreshData) {
    //   console.log('autorfffff', autoRefreshData)
    //   // setAutoRefreshStatus(autoRefreshData);
    //   autoRefreshRef.current = autoRefreshData;
    // } else {
    //   localStorage.setItem(`autoRefreshStatus_${user.id}`, false);
    // }
  }, []);

  useEffect(() => {
    if (filterDateStart && filterDateEnd) {
      const startDateObj = new Date(filterDateStart);
      const startTimestamp = startDateObj.getTime();
      const endDateObj = new Date(filterDateEnd);
      const endTimestamp = endDateObj.getTime();
      if (startTimestamp > endTimestamp) {
        toast.error('Start date cannot be greater than end date');
        setFilterDateEnd(null);
        return;
      }
    }
    fetchAlerts();
  }, [
    analyticsSelection,
    selectedClasses,
    cameraSelection,
    page,
    serachTerm,
    filterDateStart,
    filterDateEnd,
  ]);

  const handleAnalyticsSelection = (event, value) => {
    const analyticName = value;
    if (analyticName) {
      const analytic = analyticsList.find(
        (analytic) => analytic.analytic_name === analyticName
      );
      setPage(1);
      SetAnalyticsSelection(analyticName);
      analyticsSelectionRef.current = analyticName;

      AnalyticsService.getAnalyticsPostFields(analytic.analytic_id).then(
        (resp) => {
          setDisplayFields(resp.data.data);
        }
      );

      AnalyticsService.checkAnalyticsViolation(analytic.analytic_id).then(
        (resp) => {
          setIsViolation(resp.data.hasViolation);
        }
      );
      // localStorage.setItem('analyticsSelection', analyticName);
      CameraService.getAllMappedCameraListByAnalytics(
        analytic.analytic_id
      ).then((resp) => {
        setCamerasByAnalytics(resp.data);
      });
    } else {
      SetAnalyticsSelection('');
      analyticsSelectionRef.current = '';
      setAlerts([]);
      setTotalPages(0);
      setDisplayFields([]);
      localStorage.removeItem('analyticsSelection');
    }
  };

  const getTimestamp = (date) => {
    const dateObj = new Date(date);

    // Check if dateObj is a valid date
    if (isNaN(dateObj.getTime())) {
      throw new Error('Invalid date');
    }
    const gmtDate = new Date(
      Date.UTC(
        dateObj.getFullYear(),
        dateObj.getMonth(),
        dateObj.getDate(),
        dateObj.getHours(),
        dateObj.getMinutes(),
        dateObj.getSeconds(),
        dateObj.getMilliseconds()
      )
    );

    // Convert to Unix timestamp in seconds
    return Math.floor(gmtDate.getTime() / 1000);
  };

  const fetchAlerts = () => {
    const selectedAnalytics =
      analyticsSelection || analyticsSelectionRef.current;
    const selectedCamera = cameraSelectionRef.current.length
      ? cameraSelectionRef.current
      : cameraSelection;
    const searchApplied = searchTermRef.current
      ? searchTermRef.current
      : serachTerm;
    console.log('searchTermRef333', searchTermRef);
    console.log('searchTermRef222', searchTermRef.current);
    console.log('111searchTermRef', serachTerm);
    const classSelected = selectedClassesRef.current
      ? selectedClassesRef.current
      : selectedClasses;
    if (!filterDateStart && !filterDateEnd && selectedAnalytics) {
      // SetAnalyticsSelection(selectedAnalytics);
      const dateFilter = null;
      let search = null;
      if (searchApplied) {
        search = searchApplied;
      }
      AlertsService.getAlerts({
        analyticsSelection: selectedAnalytics,
        selectedClasses: classSelected,
        cameraSelection: selectedCamera,
        page,
        dateFilter: dateFilter,
        search,
      })
        .then((resp) => {
          setAlerts(resp.data);
          setTotalPages(resp.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching alerts:', error);
          setLoading(false);
        });
    }
    if (
      filterDateStart &&
      filterDateEnd &&
      dayjs(filterDateStart).isBefore(filterDateEnd) &&
      selectedAnalytics
    ) {
      const dateFilter = {
        start: filterDateStart ? new Date(filterDateStart).getTime() : null,
        end: filterDateEnd ? new Date(filterDateEnd).getTime() : null,
      };
      let search = null;
      if (serachTerm) {
        search = serachTerm;
      }
      setLoading(true);
      AlertsService.getAlerts({
        analyticsSelection: selectedAnalytics,
        selectedClasses,
        cameraSelection,
        page,
        search,
        dateFilter,
      })
        .then((resp) => {
          setAlerts(resp.data);
          setTotalPages(resp.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching alerts:', error);
          setLoading(false);
        });
    }
  };

  const handleTimeFilter = (data) => {
    const timestamp = data.timestamp;

    // Convert the timestamp to a dayjs object
    const date = dayjs.unix(timestamp);

    // Calculate the start and end times
    const startTime = date.subtract(30, 'minute');
    const endTime = date.add(30, 'minute');

    // Format the start and end times
    const formattedStartTime = startTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    const formattedEndTime = endTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    setFromDate(startTime);
    setFilterDateStart(formattedStartTime);
    setToDate(endTime);
    setFilterDateEnd(formattedEndTime);
  };

  const fetchAnalytics = () => {
    AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setAnalyticsList(resp.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : null;
    setFilterDateStart(formattedDate);
    setToDate(null);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : null;
    setFilterDateEnd(formattedDate);
  };

  const handleOnClickSearch = () => {
    // setPage(1);
  };

  const handleSearch = (event) => {
    setPage(1);
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (analyticsList.length > 0 && data) {
      handleTimeFilter(data);
      handleAnalyticsSelection(null, data.analytic_type);
    }
  }, [analyticsList, data]);

  const resetFilter = () => {
    setSelectedClasses([]);
    setToDate(null);
    setFromDate(null);
    setFilterDateStart(null);
    setFilterDateEnd(null);
    setSearchTerm('');
    searchTermRef.current = '';
    cameraSelectionRef.current = [];
    selectedClassesRef.current = [];
    setCameraSelection([]);
    fetchAlerts();
    setData(null);
  };

  const handleToggleAutoRefresh = () => {
    setIsAutoRefresh((prev) => !prev);
  };

  const columns = ['thumbnailUrl', 'cameraName', 'zoneName'];
  const columnsToDisplay = columns;
  displayFields.forEach((field) => {
    if (
      field.field_name !== 'thumb_url' &&
      field.field_name !== 'image_url' &&
      field.is_displayed === 1
    ) {
      columnsToDisplay.push(field.field_name);
    }
  });
  const headCells = [
    {
      id: 'thumbnailUrl',
      numeric: false,
      disablePadding: true,
      label: 'Thumbnail URL',
    },
    {
      id: 'cameraName',
      numeric: false,
      disablePadding: true,
      label: 'Camera Name',
    },
    {
      id: 'zoneName',
      numeric: false,
      disablePadding: true,
      label: 'Zone Name',
    },
  ];

  const columnHeads = headCells;
  displayFields.forEach((field) => {
    if (
      field.field_name !== 'thumb_url' &&
      field.field_name !== 'image_url' &&
      field.is_displayed === 1
    ) {
      columnHeads.push({
        id: field.field_name,
        numeric: false,
        disablePadding: true,
        label: capitalizeFirstLetter(field.field_name),
      });
    }
  });

  if (isViolation) {
    headCells.push({
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    });
    columnsToDisplay.push('action');
  }
  return (
    <UserStyle>
      <Helmet>
        <title>Mirasys: Alerts</title>
      </Helmet>
      <Box className="user_area">
        <AlertHeading
          analytics={analyticsList}
          handleAnalyticsSelection={handleAnalyticsSelection}
          analyticsSelection={analyticsSelection}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          fromDate={fromDate}
          toDate={toDate}
          serachTerm={serachTerm}
          handleSearch={handleSearch}
          resetFilter={resetFilter}
          handleOnClickSearch={handleOnClickSearch}
          camerasByAnalytics={camerasByAnalytics}
          setCameraSelection={setCameraSelection}
          cameraSelection={cameraSelection}
          setSelectedClasses={setSelectedClasses}
          selectedClasses={selectedClasses}
          autoRefreshStatus={isAutoRefresh}
          setAutoRefreshStatus={handleToggleAutoRefresh}
        />
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <AlertsDataTable
            rows={alerts}
            headCells={columnHeads}
            columns={columnsToDisplay}
            analyticsSelection={analyticsSelection}
            data={data}
            isViolation={isViolation}
          />
        )}
        {analyticsSelection && (
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        )}
      </Box>
    </UserStyle>
  );
};

export default Alerts;
