import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import DashboardSettings from './DashboardSetting';
import CriticalAlertSettings from './CriticalAlertSettings';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{py: '1.5rem'}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function SimpleTabs() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Dashboard Settings" {...a11yProps(0)} />
                <Tab label="Critical Alert Settings" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <DashboardSettings />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CriticalAlertSettings />
            </TabPanel>
        </div>
    );
}

export default SimpleTabs;
