// @ts-check

import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { DialogStyle } from '../../Layouts/style';
import { CloseIcon, CollapseIcon, ExpandIcon } from '../../Icons/SVGContainer';

/**
 * @typedef {import('@mui/material/Dialog').DialogProps} DialogProps
 * @typedef {Object} CustomDialogProps
 * @property {boolean} open
 * @property {boolean} expand
 * @property {React.ReactNode} [children]
 * @property {() => void} onClose
 * @property {() => void} onExpand
 * @property {number} [width]
 * @property {number} [height]
 * @property {string} [title]
 * @property {Object} [sx]
 */

/**
 * @param {CustomDialogProps} props
 */
function BaseModal(props) {
  const { open, expand, onClose, onExpand, children, width, height, title, sx, ...rest } = props;
  const [dialogHeight] = useState(height);
  const [dialogWidth] = useState(width ? width : '600px');

  return (
    <DialogStyle
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: expand ? 'calc(100vw - 4rem)' : 'inherit',
          height: expand ? 'calc(100vh - 4rem)' : dialogHeight,
          minWidth: dialogWidth,
          overflow: 'hidden',
          borderRadius: '20px',
          boxShadow: '0px 4px 40px 5px rgba(0, 0, 0, 0.13)',
        },
      }}
      sx={{
        '.MuiPaper-root': {
          width: '100%',
          minWidth: '700px !important',
        },
        '@media(max-width:1099px)': {
          '.MuiPaper-root': {
            minWidth: '60% !important',
          },
        },
        '@media(max-width:767px)': {
          '.MuiPaper-root': {
            minWidth: '90% !important',
          },
        },
        ...sx,
      }}
      {...rest}
    >
      <Box className="modal-head">
        <Box>
          {title && (
            <Typography className="global_modal_title">{title}</Typography>
          )}
        </Box>
        <Box className="btn_sec">
          <IconButton className='expand_btn' onClick={onExpand}>
            {
              expand ? 
              <CollapseIcon color="#889198" />
              :
              <ExpandIcon color="#889198" />
            }
          </IconButton>
          <IconButton onClick={onClose}>
            <CloseIcon color="#889198" />
          </IconButton>
        </Box>
      </Box>

      <Box className="modal-body">{children}</Box>
    </DialogStyle>
  );
}

export default BaseModal;
