import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ModalLayoutStyle } from '../../Layouts/style';

import ApplicationService from '../../RestSevices/applicationService';
import AnalyticsService from '../../RestSevices/analyticsService';
import { addZoneValidator } from '../../Utils/validators';
import { Formik } from 'formik';
import useAuth from '../../Redux/Actions/authActions';
import { toast } from 'react-toastify';
import { checkHasObjPermission } from '../../Utils/checkPermission';

const AddZoneForm = ({
  handleZoneForm,
  handleZoneSubmit,
  zones,
  setZoneColor,
  setAnalyticsFields,
  analyticsFields,
  selectedZone,
  handleDeleteZone,
  cameraDetails,
}) => {
  const [applicationList, setApplicationList] = useState([]);
  const [analyticsList, setAnanlyticsList] = useState([]);
  const { logout } = useAuth();
  const [addZoneState, setAddZoneState] = useState({
    zone_name: '',
    color: '#000000',
    application_id: '',
    analytics_id: '',
  });

  useEffect(() => {
    ApplicationService.getAllApplicationsList()
      .then((resp) => {
        setApplicationList([...resp.data]);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
        }
      });
    AnalyticsService.getAllAnalyticsList('global')
      .then((resp) => {
        setAnanlyticsList(resp.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          logout();
        }
      });
  }, []);

  useEffect(() => {
    if (selectedZone) {
      setAddZoneState({
        zone_name: selectedZone.zone_name,
        color: selectedZone.color,
        application_id: selectedZone.application_id,
        analytics_id: selectedZone.analytics_id,
      });

      setAnalyticsFields(
        selectedZone?.analytics_field ? JSON.parse(
          JSON.stringify(
            selectedZone?.analytics_field?.map((field) => {
              if (field.field_type === 'json') {
                return {
                  ...field,
                  field_value: JSON.stringify(field.field_value),
                };
              }
              return field;
            })
          )
        ) : []
      );
    } else {
      setAddZoneState({
        zone_name: '',
        color: '#000000',
        application_id: '',
        analytics_id: '',
      });
      setAnalyticsFields([]);
    }
  }, [selectedZone]);

  const handleSubmit = (values, { resetForm }) => {
    if (zones.length == 0 && !selectedZone) {
      toast.error('Please draw a zone!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let hasUnstructuredData = false;
    analyticsFields.forEach((field) => {
      if (field.field_type === 'json') {
        try {
          JSON.parse(field.field_value);
        } catch (error) {
          hasUnstructuredData = true;
          toast.error(`Invalid json value in ${field.field_name} field!`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
    if (hasUnstructuredData) {
      return;
    }
    if (zones.length == 0 && !selectedZone) {
      toast.error('Please draw a zone!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    handleZoneSubmit(values);
    resetForm();
    setAnalyticsFields([]);
  };

  const handleAnalyticsFieldChange = (e, index) => {
    const updatedFields = [...analyticsFields]; // Create a copy of the analyticsFields array
    if (index >= 0 && index < updatedFields.length) {
      // Check if the index is valid
      const field = updatedFields[index]; // Get the field at the specified index
      let value = e.target.value;

      if (field.field_type === 'string' && value) {
        // Remove any non-numeric characters
        if (value.length > 100) {
          return;
        }
      }

      if (field.field_type === 'integer' && value) {
        // Remove any non-numeric characters
        value = value.replace(/[^\d]/g, '');
        if (value.length > 9) {
          return;
        }
        value = parseInt(value, 10);
        if (isNaN(value)) {
          value = field.field_value; // Retain the previous value if not valid
        }
      }

      if (field.field_type === 'float') {
        // Allow digits and a single decimal point
        value = value.replace(/[^\d.]/g, '');

        // Ensure there's only one decimal point
        const decimalPointCount = (value.match(/\./g) || []).length;
        if (decimalPointCount > 1) {
          return;
        }

        // Check the length of the integer and fractional parts
        const parts = value.split('.');
        const integerPart = parts[0];
        const fractionalPart = parts[1] || '';

        if (integerPart.length > 3 || fractionalPart.length > 2) {
          return;
        }
      }

      if (field.field_type === 'array' && value) {
        value = value.replace(/[^\w,]/g, '');
        if (value.length > 200) {
          return;
        }
        value = value.split(','); // Parse the value as an array
      }

      if (field.field_type === 'obj' && value) {
        if (value.length > 300) {
          return;
        } // Parse the value as an array
      }

      field.field_value = value; // Update the field's value

      updatedFields[index] = field; // Put the updated field back into the array

      setAnalyticsFields(updatedFields); // Update the state with the modified array
    }
  };

  const handleAnalyticsChange = (e) => {
    const hash = e.target.value;

    if (hash) {
      analyticsList.forEach((analytics) => {
        if (analytics.hash_id == hash) {
          console.log(analytics, "analytics")
          return setAnalyticsFields(
            analytics.analyticfields ? analytics.analyticfields.map(
              ({ field_name, field_value, field_type }) => {
                return { field_name, field_value, field_type };
              }
            ) : []
          );
        }
      });
    } else {
      setAnalyticsFields([]);
    }
  };

  const getFieldValue = (field) => {
    if (field.field_type === 'boolean') {
      return field.field_value ? 'True' : 'False';
    } else if (
      field.field_type === 'json' &&
      typeof field.field_value == 'object'
    ) {
      return JSON.stringify(field.field_value);
    } else {
      return field.field_value;
    }
  };

  return (
    <ModalLayoutStyle>
      {cameraDetails && (
        <Box className="modal_form_body">
          <Formik
            initialValues={addZoneState}
            validationSchema={addZoneValidator}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box
                  className="form_field_area zone_form_area"
                  sx={{
                    '&&&': {
                      mt: 0,
                      pt: 0,
                    },
                  }}
                >
                  <Box className="form_box_hold full_width">
                    <Box className="form_box_con full_width">
                      <Typography component="label">Name</Typography>
                      <TextField
                        name="zone_name"
                        type="text"
                        value={props.values.zone_name}
                        onChange={props.handleChange}
                        error={
                          props.touched.zone_name && !!props.errors.zone_name
                        }
                        helperText={
                          props.touched.zone_name ? props.errors.zone_name : ''
                        }
                      />
                    </Box>
                  </Box>
                  {selectedZone && (
                    <Box className="form_box_hold full_width">
                      <Box className="form_box_con full_width">
                        <Typography component="label">Zone Id</Typography>
                        <TextField
                          type="text"
                          value={selectedZone.zone_id}
                          disabled={true}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box className="form_box_hold full_width">
                    <Box className="form_box_con">
                      <Typography component="label">Color</Typography>
                      <Box className="color_area">
                        <TextField
                          name="color"
                          type="color"
                          value={props.values.color}
                          onChange={(e) => {
                            props.handleChange(e);
                            setZoneColor(e.target.value);
                          }}
                          error={props.touched.color && !!props.errors.color}
                          helperText={
                            props.touched.color ? props.errors.color : ''
                          }
                        />
                        {/* <Box className="color_field_area">
                        <Box className="color_field">
                          <Box className="color_name">
                            <Select
                              // name="color_select"
                              // value={color}
                              // onChange={handleColorChange}
                              displayEmpty
                            >
                              <MenuItem value="">R G B</MenuItem>
                              <MenuItem value={'CMYK'}>C M Y K</MenuItem>
                            </Select>
                          </Box>
                          <Box className="color_input">
                            <Box>
                              <TextField name="" type="text" />
                            </Box>
                            <Box>
                              <TextField name="" type="text" />
                            </Box>
                            <Box>
                              <TextField name="" type="text" />
                            </Box>
                          </Box>
                        </Box>
                        <IconButton>
                          <img src="/images/eye_dropper_icon.svg" alt="" />
                        </IconButton>
                      </Box> */}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="form_box_hold full_width">
                    <Box className="form_box_con">
                      <Typography component="label">Application</Typography>
                      <Select
                        name="application_id"
                        value={props.values.application_id}
                        onChange={props.handleChange}
                        error={
                          props.touched.application_id &&
                          !!props.errors.application_id
                        }
                        displayEmpty
                      >
                        <MenuItem value="">Select</MenuItem>
                        {applicationList.map((application) => (
                          <MenuItem
                            key={application.hash_id}
                            value={application.hash_id}
                          >
                            {application.application_name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        error={
                          props.touched.application_id &&
                          !!props.errors.application_id
                        }
                      >
                        {props.touched.application_id
                          ? props.errors.application_id
                          : ''}
                      </FormHelperText>
                    </Box>
                  </Box>
                  <Box className="form_box_hold full_width">
                    <Box className="form_box_con">
                      <Typography component="label">Analytic</Typography>
                      <Select
                        name="analytics_id"
                        value={props.values.analytics_id}
                        onChange={(e) => {
                          props.handleChange(e);
                          handleAnalyticsChange(e);
                        }}
                        error={
                          props.touched.analytics_id &&
                          !!props.errors.analytics_id
                        }
                        displayEmpty
                      >
                        <MenuItem value="">Select</MenuItem>
                        {analyticsList.map((analytics) => (
                          <MenuItem
                            key={analytics.hash_id}
                            value={analytics.hash_id}
                          >
                            {analytics.analytic_name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        error={
                          props.touched.analytics_id &&
                          !!props.errors.analytics_id
                        }
                      >
                        {props.touched.analytics_id
                          ? props.errors.analytics_id
                          : ''}
                      </FormHelperText>
                    </Box>
                  </Box>
                  {analyticsFields && analyticsFields.map((field, index) => {
                    return (
                      <Box className="form_box_hold" key={field.hash_id}>
                        {/* <Box className="form_box_con">
                          <Typography component="label">Field Name</Typography>
                          <TextField
                            name="field"
                            type="text"
                            value={field.field_name}
                            disabled={true}
                          />
                        </Box> */}
                        <Box className="form_box_con">
                          <Typography component="label">{field.field_name}</Typography>
                          <TextField
                            name="field"
                            type="text"
                            onChange={(e) =>
                              handleAnalyticsFieldChange(e, index)
                            }
                            value={getFieldValue(field)}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box className="add_btn zone_btn_sec">
                  <Button className="cancel_btn" onClick={handleZoneForm}>
                    Cancel
                  </Button>
                  {selectedZone &&
                    checkHasObjPermission(
                      cameraDetails,
                      'camera',
                      'delete_zone'
                    ) && (
                      <Button
                        className="cancel_btn"
                        onClick={handleDeleteZone}
                      >
                        Delete
                      </Button>
                    )}
                  {checkHasObjPermission(
                    cameraDetails,
                    'camera',
                    'edit_zone'
                  ) &&
                    selectedZone && <Button type="submit">Update</Button>}
                  {checkHasObjPermission(
                    cameraDetails,
                    'camera',
                    'create_zone'
                  ) &&
                    !selectedZone && <Button type="submit">Create</Button>}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}
    </ModalLayoutStyle>
  );
};

export default AddZoneForm;
