import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Container, Typography } from '@mui/material';
// import Sidebar from './Sidebar';
import ReportForm from './ReportForm';
import ReportTable from './ReportTable'; // Component to display the report data

const ReportPage = () => {
  const [reportData, setReportData] = useState(null);

  const handleReportGenerate = (data) => {
    // Generate report logic here
    // For now, just log the data
    console.log(data);
    setReportData(data);
  };

  return (
    <Container>
      {/* <Sidebar
                items={['Report 1', 'Report 2', 'Report 3']}
                onSelect={(item) => console.log('Selected:', item)}
            /> */}
      <Card>
        <CardHeader title={<Typography variant="h4" gutterBottom>
          Generate Report
        </Typography>} />
        <CardContent>
          <ReportForm onSubmit={handleReportGenerate} />
          {reportData && <ReportTable data={reportData} />}
        </CardContent>
      </Card>
    </Container>
  );
};

export default ReportPage;
