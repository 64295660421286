import { apolloClient } from '../apolloClient';
import { GET_ALL_ALARM_SETTINGS, GET_ALL_CRITICAL_ALERT_SETTINGS, GET_ALL_DASHBOARD_SETTINGS_BY_ANALYTICS, GET_ALL_DASHBOARD_SETTINGS_BY_ANALYTICS_IDS, GET_STATS_TYPE } from '../dashboard';

export const getAllStatsType = async () => {
  try {
    const response = await apolloClient.query({
      query: GET_STATS_TYPE,
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};

export const getAllSettingsByAnalytics = async (id) => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_DASHBOARD_SETTINGS_BY_ANALYTICS,
      variables:{getAllDashboardSettingsByAnalyticId:id}
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};

export const getAllSettingsByAnalyticIds = async (id) => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_DASHBOARD_SETTINGS_BY_ANALYTICS_IDS,
      variables:{ids:id}
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};

export const getAllCriticalAlertSettings = async () => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_CRITICAL_ALERT_SETTINGS,
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};

export const getAllAlarmSettings = async () => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_ALARM_SETTINGS,
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};
