import { toast } from 'react-toastify';
import { apolloClient } from '../apolloClient';
import {
  CREATE_ALARM_SETTINGS,
  CREATE_CRITICAL_ALERT_SETTINGS,
  CREATE_DASHBOARD_SETTINGS,
  DELETE_ALARM_SETTINGS,
  DELETE_CRITICAL_ALERT_SETTINGS,
  DELETE_DASHBOARD_SETTINGS,
  UPDATE_ALARM_SETTINGS,
  UPDATE_CRITICAL_ALERT_SETTINGS,
  UPDATE_DASHBOARD_SETTINGS,
} from '../dashboard';

export const createDashboardSettings = async ({
  name,
  parameter,
  stats_type,
  chart,
  x_axis,
  y_axis,
  chart_parameters,
  analytics_id,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_DASHBOARD_SETTINGS,
      variables: {
        name,
        parameter,
        stats_type,
        chart,
        x_axis,
        y_axis,
        chart_parameters,
        analytics_id,
      },
    });
    toast.success('Settings created successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const updateDashboardSettings = async ({
  id,
  name,
  parameter,
  stats_type,
  chart,
  x_axis,
  y_axis,
  chart_parameters,
  analytics_id,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_DASHBOARD_SETTINGS,
      variables: {
        id,
        name,
        parameter,
        stats_type,
        chart,
        x_axis,
        y_axis,
        chart_parameters,
        analytics_id,
      },
    });
    toast.success('Settings updated successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const deleteDashboardSettings = async (id) => {
  try {
    const response = await apolloClient.mutate({
      mutation: DELETE_DASHBOARD_SETTINGS,
      variables: {
        id,
      },
    });
    toast.success('Settings deleted successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const createCriticalAlertSetting = async ({
  analytics_id,
  camera_ids,
}) => {
  console.log(analytics_id,
    camera_ids, "analytics_id,camera_ids")
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_CRITICAL_ALERT_SETTINGS,
      variables: {
        analyticsId: analytics_id.toString(),
        cameraIds: camera_ids
      },
    });
    toast.success('Settings created successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const updateCriticalAlertSetting = async ({
  id,
  analytics_id,
  camera_ids,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_CRITICAL_ALERT_SETTINGS,
      variables: {
        updateCriticalAlertSettingId: id,
        analyticsId: analytics_id.toString(),
        cameraIds: camera_ids
      },
    });
    toast.success('Settings updated successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const deleteCriticalAlertSetting = async (
  id
) => {
  console.log(id, "id")
  try {
    const response = await apolloClient.mutate({
      mutation: DELETE_CRITICAL_ALERT_SETTINGS,
      variables: {
        deleteCriticalAlertSettingId: id
      },
    });
    toast.success('Settings deleted successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};



export const createAlarmSetting = async ({
  analytics_id,
  camera_ids,
}) => {
  console.log(analytics_id,
    camera_ids, "analytics_id,camera_ids")
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_ALARM_SETTINGS,
      variables: {
        analyticsId: analytics_id.toString(),
        cameraIds: camera_ids
      },
    });
    toast.success('Settings created successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const updateAlarmSetting = async ({
  id,
  analytics_id,
  camera_ids,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_ALARM_SETTINGS,
      variables: {
        id: id,
        analyticsId: analytics_id.toString(),
        cameraIds: camera_ids
      },
    });
    toast.success('Settings updated successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const deleteAlarmSetting = async (
  id
) => {
  try {
    const response = await apolloClient.mutate({
      mutation: DELETE_ALARM_SETTINGS,
      variables: {
        id
      },
    });
    toast.success('Settings deleted successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

