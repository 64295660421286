import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Grid,
  MenuItem,
  TextField,
  Button,
} from '@mui/material';

import AnalyticsService from '../../../RestSevices/analyticsService';
import VmsThirdPartyService from '../../../RestSevices/vmsThirdPartyService';
import useAuth from '../../../Redux/Actions/authActions';

const connectionTypeOptions = [
  { value: 'socket', label: 'Socket' },
  { value: 'rest-api', label: 'Rest API' },
];

const vmsSchema = Yup.object().shape({
  ip: Yup.string().required('IP address is required'),
  port: Yup.number().required('Port is required'),
  content: Yup.string().required('Content is required'),
});

const restApiSchema = Yup.object().shape({
  loginUrl: Yup.string()
    .url('Invalid URL format')
    .required('Login URL is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  authType: Yup.string().required('Auth Type is required'),
  postUrl: Yup.string()
    .url('Invalid URL format')
    .required('Post URL is required'),
  content: Yup.string().required('Content is required'),
});

export default function VmsAndThirdParty() {
  const [analyticsList, setAnalyticsList] = useState([]);
  const [initialValues, setInitialValues] = useState({
    ip: '',
    port: '',
    content: '',
    loginUrl: '',
    username: '',
    password: '',
    authType: '',
    postUrl: '',
    analytics: '',
    connectionType: '',
  });

  const [configAvailability, setConfigAvailability] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { logout } = useAuth();

  const fetchData = async () => {
    await AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setAnalyticsList(resp.data || []);
      })
      .catch((err) => {
        if (err.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        console.log(err);
      });
  };

  const fetchConfigsData = async () => {
    const configs = await VmsThirdPartyService.getVmsConfigAvailability();
    const data = configs?.data?.result;
    data.forEach((obj) => {
      obj.groupedData = obj.groupedData.split(',');
    });
    setConfigAvailability(data);
  };

  const fetchVmsConfigById = async () => {
    if (id) {
      try {
        const configs = await VmsThirdPartyService.getVmsConfigById(id);
        const data = configs?.data?.result;
        const { analyticId, content, ...rest } = data;
        const values = {
          ...rest,
          analytics: analyticId,
          content: JSON.parse(content),
        };
        setInitialValues(values);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const formSubmit = async (values, { setSubmitting }) => {
    console.log(values);
    try {
      const commonData = {
        analytics_id: values.analytics,
        connection_type: values.connectionType,
        content: JSON.stringify(values.content),
      };
      const data =
        values.connectionType === 'socket'
          ? { ...commonData, ip: values.ip, port: values.port }
          : {
              ...commonData,
              loginUrl: values.loginUrl,
              username: values.username,
              password: values.password,
              authType: values.authType,
              postUrl: values.postUrl,
            };

      if (!!values.id) {
        VmsThirdPartyService.editVmsConfig(values.id, data);
      } else {
        await VmsThirdPartyService.createVMSThirdPartyConfig(data);
      }
      setSubmitting(false);
      navigate('/vms-and-third-party-integration');
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    fetchData();
    fetchConfigsData();
    fetchVmsConfigById();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Mirasys: VMS-ThirdParty-Configs: {!!initialValues.id ? 'Edit' : 'Add'}
        </title>
      </Helmet>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={(values) => {
          const schema =
            values.connectionType === 'socket' ? vmsSchema : restApiSchema;
          try {
            schema.validateSync(values, { abortEarly: false });
          } catch (err) {
            return err.inner.reduce((errors, validationError) => {
              return {
                ...errors,
                [validationError.path]: validationError.message,
              };
            }, {});
          }
          return {};
        }}
        onSubmit={formSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          setValues,
          isSubmitting,
        }) => (
          <Form>
            <Card>
              <CardHeader
                title={
                  <Box>
                    <Grid
                      container
                      spacing={3}
                      justifyContent={'space-between'}
                    >
                      <Grid item md={6}>
                        <Typography variant="h4">
                          VMS & Third Party Integration
                        </Typography>
                      </Grid>
                      <Grid item md={6} className="vms-add-btn">
                        <Button
                          component={Link}
                          to="/vms-and-third-party-integration"
                          variant="contained"
                          color="primary"
                        >
                          List
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
              <CardContent>
                <Box
                  maxWidth="md"
                  sx={{
                    padding: '1rem',
                    borderRadius: '1rem',
                    border: '1px solid gray',
                  }}
                >
                  <Box>
                    <Field
                      name="analytics"
                      as={TextField}
                      select
                      fullWidth
                      variant="outlined"
                      label="Select Analytics"
                      value={values.analytics}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('connectionType', '');
                      }}
                      error={Boolean(errors.analytics && touched.analytics)}
                      helperText={touched.analytics && errors.analytics}
                    >
                      {analyticsList.map((analytic) => {
                        const isDisabled = configAvailability.some(
                          (obj) =>
                            obj.analyticId === analytic.analytic_id &&
                            obj.groupedData.length === 2
                        );
                        return (
                          <MenuItem
                            key={analytic.hash_id}
                            value={analytic.analytic_id}
                            disabled={isDisabled}
                          >
                            {analytic.analytic_name}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Box>
                  <Box>
                    <Field
                      name="connectionType"
                      as={TextField}
                      label="Connection Type"
                      select
                      fullWidth
                      margin="normal"
                      value={values.connectionType}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      error={Boolean(
                        errors.connectionType && touched.connectionType
                      )}
                      helperText={
                        touched.connectionType && errors.connectionType
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Connection Type
                      </MenuItem>
                      {connectionTypeOptions.map((option) => {
                        const isDisabled = configAvailability.some(
                          (obj) =>
                            obj.analyticId === values.analytics &&
                            obj.groupedData.includes(option.value)
                        );
                        return (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            disabled={isDisabled || !values.analytics}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Box>
                  {values.connectionType === 'socket' && (
                    <>
                      <Field
                        name="ip"
                        as={TextField}
                        label="IP Address"
                        fullWidth
                        margin="normal"
                        value={values.ip}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.ip && touched.ip)}
                        helperText={touched.ip && errors.ip}
                      />
                      <Field
                        name="port"
                        as={TextField}
                        label="Port"
                        fullWidth
                        margin="normal"
                        type="number"
                        value={values.port}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.port && touched.port)}
                        helperText={touched.port && errors.port}
                      />
                    </>
                  )}
                  {values.connectionType === 'rest-api' && (
                    <>
                      <Field
                        name="loginUrl"
                        as={TextField}
                        label="Login URL"
                        fullWidth
                        margin="normal"
                        value={values.loginUrl}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.loginUrl && touched.loginUrl)}
                        helperText={errors.loginUrl && touched.loginUrl}
                      />
                      <Field
                        name="username"
                        as={TextField}
                        label="Username"
                        fullWidth
                        margin="normal"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.username && touched.username)}
                        helperText={errors.username && touched.username}
                      />
                      <Field
                        name="password"
                        as={TextField}
                        label="Password"
                        fullWidth
                        margin="normal"
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.password && touched.password)}
                        helperText={errors.password && touched.password}
                      />
                      <Field
                        name="authType"
                        as={TextField}
                        select
                        label="Auth Type"
                        fullWidth
                        margin="normal"
                        value={values.authType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.authType && touched.authType)}
                        helperText={errors.authType && touched.authType}
                      >
                        {[
                          { value: 'basic', label: 'Basic' },
                          { value: 'token', label: 'Token' },
                        ].map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                      <Field
                        name="postUrl"
                        as={TextField}
                        label="Post URL"
                        fullWidth
                        margin="normal"
                        value={values.postUrl}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.postUrl && touched.postUrl)}
                        helperText={errors.postUrl && touched.postUrl}
                      />
                    </>
                  )}
                  <Field
                    name="content"
                    as={TextField}
                    label="Content (JSON)"
                    multiline
                    rows={4}
                    fullWidth
                    margin="normal"
                    value={values.content}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.content && touched.content)}
                    helperText={touched.content && errors.content}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    style={{ marginTop: '1rem' }}
                  >
                    {!!values.id ? 'Update' : 'Submit'}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
}
