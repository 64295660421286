import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import dayjs from 'dayjs';
import {
  Box,
  CircularProgress,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import io from 'socket.io-client';
import { UserStyle } from '../../../Layouts/style';
import BaseModal from '../../../Components/Modal/BaseModal';
import VideoSummarizationHeading from './VideoSummarizationHeading';
import VideoSummarizationDataTable from '../../../Components/DataTable/VideoSummarizationDatatable';
import AlertsService from '../../../RestSevices/alertService';
import { toast } from 'react-toastify';

const VideoSummarizationAlerts = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterDateStart, setFilterDateStart] = useState(null);
  const [filterDateEnd, setFilterDateEnd] = useState(null);
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [tick, setTick] = useState(0);

  const imagePath = process.env.REACT_APP_IMAGE_URL;

  const forceUpdate = () => {
    setTick((tick) => tick + 1);
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_SOCKET_URL);

    // Handle socket connection
    socket.on('connect', () => {
      console.log('Socket connected');
    });

    // Handle socket disconnection
    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    // Handle socket errors
    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    socket.on('alert_created', (resp) => {
      forceUpdate();
    });
    return () => {
      console.log('socket disconnect');
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (filterDateStart && filterDateEnd) {
      const startDateObj = new Date(filterDateStart);
      const startTimestamp = startDateObj.getTime();
      const endDateObj = new Date(filterDateEnd);
      const endTimestamp = endDateObj.getTime();
      if (startTimestamp > endTimestamp) {
        toast.error('Start date cannot be greater than end date');
        setFilterDateEnd(null);
        return;
      }
    }
    fetchAlerts();
  }, [tick, page, filterDateStart, filterDateEnd, analytics]);

  const fetchAlerts = () => {
    if (!filterDateStart && !filterDateEnd) {
      const dateFilter = null;
      setLoading(true);
      AlertsService.getVsAlerts({
        page,
        dateFilter: dateFilter,
        search: searchTerm,
        analytics,
      })
        .then((resp) => {
          setAlerts(resp.data);
          setTotalPages(resp.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching alerts:', error);
          setLoading(false);
        });
    }
    if (
      filterDateStart &&
      filterDateEnd &&
      dayjs(filterDateStart).isBefore(dayjs(filterDateEnd))
    ) {
      const dateFilter = {
        start: filterDateStart ? new Date(filterDateStart).getTime() : null,
        end: filterDateEnd ? new Date(filterDateEnd).getTime() : null,
      };
      setLoading(true);
      AlertsService.getVsAlerts({
        page,
        dateFilter,
        search: searchTerm,
        analytics,
      })
        .then((resp) => {
          setAlerts(resp.data);
          setTotalPages(resp.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching alerts:', error);
          setLoading(false);
        });
    }
  };
  const handleFromDateChange = (date) => {
    setFromDate(new Date(date));
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : null;
    setFilterDateStart(formattedDate);
    setToDate(null);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : null;
    setFilterDateEnd(formattedDate);
  };

  const resetFilter = () => {
    setToDate(null);
    setFromDate(null);
    setFilterDateStart(null);
    setFilterDateEnd(null);
    setSearchTerm('');
    fetchAlerts();
  };

  const columns = [
    'thumbnailUrl',
    'junctionName',
    'videoName',
    'classes',
    'startTime',
    'endTime',
  ];
  const headCells = [
    {
      id: 'thumbnailUrl',
      numeric: false,
      disablePadding: true,
      label: 'Thumbnail URL',
    },
    {
      id: 'junctionName',
      numeric: false,
      disablePadding: true,
      label: 'Junction Name',
    },
    {
      id: 'videoName',
      numeric: false,
      disablePadding: true,
      label: 'Video Name',
    },
    {
      id: 'classes',
      numeric: false,
      disablePadding: true,
      label: 'Classes',
    },
    {
      id: 'startTime',
      numeric: false,
      disablePadding: true,
      label: 'Start Time',
    },
    {
      id: 'endTime',
      numeric: false,
      disablePadding: true,
      label: 'End Time',
    },
  ];

  const rows = alerts.map((item) => {
    return {
      ...item,
      thumbnailUrl: (
        <img src={`${imagePath}/${encodeURIComponent(item.thumb_url[0])}`} />
      ),
      dateTime: item.timestamp,
      junctionName: item.junction_name,
      cameraName: item.camera_name,
      classes: item.classes,
      startTime: item.start_time,
      endTime: item.end_time,
      image_url: item.image_url,
      videoName: item.video_name,
    };
  });
  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    fetchAlerts();
  };
  return (
    <UserStyle>
      <Helmet>
        <title>Mirasys: Users</title>
      </Helmet>
      <Box className="user_area">
        <VideoSummarizationHeading
          analytics={analytics}
          setAnalytics={setAnalytics}
          fromDate={fromDate}
          toDate={toDate}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          resetFilter={resetFilter}
          searchVSAlertTerm={searchTerm}
          handleSearchTerm={(e) => setSearchTerm(e.target.value)}
          handleVSAlertSearch={handleSearch}
        />
        {loading ? (
          <CircularProgress color="success" />
        ) : alerts.length === 0 ? (
          <Typography
            variant="h4"
            component="div"
            style={{ textAlign: 'center' }}
          >
            No Alerts Found!
          </Typography>
        ) : (
          <VideoSummarizationDataTable
            rows={rows}
            headCells={headCells}
            columns={columns}
            onClick={() => setModalOpen(true)}
          />
        )}
        <BaseModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title={`Video`}
        >
          <Box className="video_modal" sx={{ padding: '15px' }}>
            <Box
              className="video_modal"
              sx={{ borderRadius: '15px', overflow: 'hidden' }}
            >
              <iframe
                width="100%"
                height="315"
                src="//www.youtube.com/embed/58-atNakMWw"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </Box>
          </Box>
        </BaseModal>
        {alerts.length > 0 && (
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        )}
      </Box>
    </UserStyle>
  );
};

export default VideoSummarizationAlerts;
