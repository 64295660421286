import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { DashboardStyle } from '../../../Layouts/style';
import {
  MaximizeIcon,
  MaximumPeopleIcon,
  MinimizeIcon,
  RefreshIcon,
  TimeDurationIcon,
  TotalPeopleIcon,
} from '../../../Icons/SVGContainer';
import BarChart from '../../../Components/Chart/BarChart';
import AnalyticsService from '../../../RestSevices/analyticsService';
import { getAllSettingsByAnalyticIds } from '../../../graphQL/Dashboard/query';
import AlertsService from '../../../RestSevices/alertService';
import ChartModal from '../../../Components/Modal/ChartModal';
import {
  dateRange,
  formatBarChart,
  formatNochart,
  formatLineChart,
  formatPieChart,
  getRandomColor,
  formatTimestamp,
} from '../../../Utils/dashboardHelper';
import LineChart from '../../../Components/Chart/LineChart';
import PieChart from '../../../Components/Chart/PieChart';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../Redux/Actions/authActions';
import { toast } from 'react-toastify';
import DashboardService from '../../../RestSevices/dashboardService';
import { checkPermission } from '../../../Utils/checkPermission';
import io from 'socket.io-client';
import debounce from 'lodash/debounce';

const DashboardView = () => {
  const [analytics, setAnalytics] = useState([]);
  const [analyticsList, setAnalyticsList] = useState([]);
  const [settingsList, setSettingsList] = useState([]);
  // const [alertsList, setAlertsList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [range, setRange] = useState('24hours');
  const [selectedChartData, setSelectedChartData] = useState({});
  const [openChartModal, setOpenChartModal] = useState(false);
  const [criticalAlertsList, setCriticalAlertsList] = useState([]);
  const [alarmList, setAlarmList] = useState([]);
  const [fetchTimeout, setFetchTimeout] = useState(null); // State to store the timeout reference
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingAnalytics, setIsFetchingAnalytics] = useState(false);
  const { logout, user } = useAuth();

  const navigate = useNavigate();

  const handleAnalyticsChange = debounce((event) => {
    const selectedAnalytics = event.target.value;
    setAnalytics(selectedAnalytics);
    localStorage.setItem(`analyticsSelection_${user.id}`, JSON.stringify(selectedAnalytics));
  }, 200);

  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };

  const goToSettings = () => {
    navigate('/dashboard-settings');
  };

  const handleMinimizeChart = (id) => {
    const index = dataList.findIndex((data) => data.id == id);
    const newData = { ...dataList[index] };
    newData.minimizeChart = true;
    dataList[index] = newData; // Update dataList with modified data
    setDataList([...dataList]); // Update state
  };
  const handleMaximizeChart = (id) => {
    const index = dataList.findIndex((data) => data.id == id);
    const newData = { ...dataList[index] };
    newData.minimizeChart = false;
    dataList[index] = newData; // Update dataList with modified data
    setDataList([...dataList]); // Update state
  };

  const handleOpenChartModal = (type, data) => {
    setSelectedChartData({ type, data });
    setOpenChartModal(true);
  };

  const fetchData = async () => {
    setIsFetchingAnalytics(true);
    await AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setIsFetchingAnalytics(false);
        setAnalyticsList(resp.data || []);
        setTimeout(() => {
          if (localStorage.getItem(`analyticsSelection_${user.id}`)) {
            setAnalytics(JSON.parse(localStorage.getItem(`analyticsSelection_${user.id}`)));
          } else {
            setAnalytics([resp.data[0]?.hash_id]);
          }
        }, 500);
      })
      .catch((err) => {
        setIsFetchingAnalytics(false);
        if (err?.response?.status === 401) {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        console.log(err);
      });
  };
  //test
  const fetchAllSettings = async () => {
    try {
      const settingsResp = await getAllSettingsByAnalyticIds(analytics);
      if ('errors' in settingsResp) {
        if (settingsResp?.errors[0].message == 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
      const settingsData = settingsResp?.data?.getAllDashboardSettingsByAnalyticIds?.dashboardSettings?.map(
        (forms) => ({
          ...forms,
          chart_parameters: forms.chart_parameters?.split(',') || [],
          isLoading: true,
        })
      );

      setSettingsList(settingsData || []);
      setDataList(settingsData || []);
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchAlerts = async () => {
  //   try {
  //     let selectedAnalyctis;
  //     analyticsList.forEach((data) => {
  //       if (data.hash_id == analytics) {
  //         selectedAnalyctis = data.analytic_name;
  //       }
  //       return null;
  //     });
  //     const alertsListResp = await AlertsService.getDashboardAlerts({
  //       analytics: selectedAnalyctis,
  //       range,
  //     });
  //     setAlertsList(alertsListResp.data);
  //   } catch (error) {
  //     if (error.response?.data?.error == 'Authentication failed') {
  //       logout();
  //       toast.error('Session Expired!', {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //     console.log(error);
  //   }
  // };


  const fetchCriticalAlerts = async () => {
    try {
      const alertsListResp = await AlertsService.getDashboardCriticalAlerts({
        range
      });
      setCriticalAlertsList(alertsListResp.data);
    } catch (error) {
      if (error.response?.data?.error == 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      console.log(error);
    }
  }

  const fetchAlarms = async () => {
    try {
      const alertsListResp = await AlertsService.getDashboardAlarms();
      setAlarmList(alertsListResp.data);
    } catch (error) {
      if (error.response?.data?.error == 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      console.log(error);
    }
  }

  useEffect(() => {
    formatChartData();
  }, [settingsList]);

  useEffect(() => {
    const interval = setInterval(() => {
      formatChartData(true);
    }, 600000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [dataList, range]);

  const formatChartData = async (refresh = false) => {
    const fetchPromises = settingsList.map(async (setting) => {
      const data = await DashboardService.getChartDataById(setting.id, range);
      return { ...setting, data, isLoading: false };
    });
    const data = await Promise.all(fetchPromises);
    setDataList(data);


    // for (let i = 0; i < dataList.length; i++) {
    //   const data = dataList[i];
    //   if (data.isLoading === true || refresh) {
    //     const resp = await DashboardService.getChartDataById(data.id, range);
    //     const newData = { ...data };
    //     // Modify the clone
    //     newData.data = resp;
    //     newData.isLoading = false;
    //     dataList[i] = newData; // Update dataList with modified data
    //     setDataList([...dataList]); // Update state
    //   }
    // }
  };

  const refreshChart = async (data) => {
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i].id == data.id) {
        const newData = { ...dataList[i] };
        newData.isLoading = true;
        dataList[i] = newData; // Update dataList with modified data
        setDataList([...dataList]); // Update state
        const resp = await DashboardService.getChartDataById(
          data.id,
          range,
          true
        );
        newData.data = resp;
        newData.isLoading = false;
        dataList[i] = newData; // Update dataList with modified data
        setDataList([...dataList]); // Update state
      }
      // Modify the clone
    }
  };

  const alarmSeen = () => {
    const alertIds = alarmList?.map((data) => data._id);
    AlertsService.seenAlerts(alertIds).then((res) => {

    }).catch((err) => {
      console.log(err);
    })
  }

  const alertDetails = (data) => {
    navigate('/alerts', { state: data });
    alarmSeen();
  };

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_API_SOCKET_URL;
    const socket = io(socketUrl);

    // Handle socket connection
    socket.on('connect', () => {
      console.log('Socket connected');
    });

    // Handle socket disconnection
    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    // Handle socket errors
    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    socket.on('alert_created', (data) => {
      if (!isFetching) {
        setIsFetching(true); // Set the flag to true
        const timeout = setTimeout(() => {
          fetchCriticalAlerts();
          fetchAlarms();
          setIsFetching(false); // Reset the flag after fetching
        }, 5000); // Wait for 5 seconds before fetching
        setFetchTimeout(timeout); // Store the timeout reference
      } else {
        clearTimeout(fetchTimeout); // Clear the previous timeout
        const timeout = setTimeout(() => {
          fetchCriticalAlerts();
          fetchAlarms();
          setIsFetching(false); // Reset the flag after fetching
        }, 5000); // Wait for 5 seconds before fetching
        setFetchTimeout(timeout); // Store the timeout reference
      }
    });

    return () => {
      console.log('Socket disconnect');
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchData();
    fetchCriticalAlerts();
    fetchAlarms();
  }, []);

  useEffect(() => {
    if (analytics.length) {
      fetchAllSettings();
      // fetchAlerts();
    } else {
      setDataList([]);
    }
  }, [analytics]);

  useEffect(() => {
    if (analytics.length) {
      fetchAllSettings();
    }
  }, [range]);


  return (
    <DashboardStyle>
      <Box className="dashboard_area">
        <Box className="dashboard_left">
          <Box className="dashboard_heading">
            <Typography component="h2">Dashboard</Typography>
            <Box className="dash_select_area">
              <Select
                value={analytics}
                onChange={handleAnalyticsChange}
                displayEmpty
                multiple={true}
              >
                {analyticsList.map((analytic) => {
                  return (
                    <MenuItem value={analytic.hash_id} key={analytic.hash_id}>
                      {analytic.analytic_name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select value={range} onChange={handleRangeChange} displayEmpty>
                {dateRange.map((data) => {
                  return <MenuItem value={data.value} key={data.value}>{data.label}</MenuItem>;
                })}
              </Select>
              {checkPermission('edit_dashboard') && (
                <IconButton onClick={goToSettings}>
                  <img src="/images/settings_icon.svg" alt="" />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box className="dashboard_top_info">
            {dataList.length > 0 ? (dataList.map((data) => (
              data.chart === 'no_chart' && !data.isLoading && (
                <Box className="dashboard_top_info_box" key={data.id}>
                  <Box>
                    <Typography>{data.name}</Typography>
                    <Typography component="h2">{data.data?.data}</Typography>
                  </Box>
                  <Box className="dashboard_top_info_icon">
                    {data.statsType?.title === 'Average' ? (
                      <TimeDurationIcon />
                    ) : (
                      <TotalPeopleIcon />
                    )}
                  </Box>
                </Box>
              )
            ))) : null}

          </Box>
          <Grid container spacing={2} className="dashboard_chart_area">
            {dataList.map((data) => (
              <React.Fragment key={data.id}>
                {data.isLoading ? (
                  // Display loader if data is loading
                  <Grid item lg={6} md={12}>
                    <div className="dashboard_chart_con">
                      <CircularProgress />
                    </div>
                  </Grid>
                ) : (
                  <React.Fragment>
                    {data.chart === 'bar_chart' && (
                      <Grid item lg={6} md={12}>
                        <div className="dashboard_chart_con">
                          <Box className="dashboard_chart_head">
                            <Typography component="h3">
                              {data.name.toUpperCase()}
                              <Box className="dashboard_chart_head_btn">
                                <Tooltip
                                  title="Refresh"
                                  onClick={() => refreshChart(data)}
                                >
                                  <IconButton>
                                    <RefreshIcon />
                                  </IconButton>
                                </Tooltip>
                                {data.minimizeChart ? (
                                  <Tooltip
                                    title="Maximize Chart"
                                    onClick={() => handleMaximizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MaximizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Minimize Chart"
                                    onClick={() => handleMinimizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MinimizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Expand Chart">
                                  <IconButton
                                    onClick={() =>
                                      handleOpenChartModal('bar', data)
                                    }
                                  >
                                    <img src="/images/expand_icon.svg" alt="" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          </Box>
                          <Box
                            className={
                              data.minimizeChart
                                ? 'chart_con hide'
                                : 'chart_con'
                            }
                          >
                            <BarChart configData={data.data} type="bar" />
                          </Box>
                        </div>
                      </Grid>
                    )}
                    {data.chart === 'line_chart' && (
                      <Grid item lg={6} md={12}>
                        <div className="dashboard_chart_con">
                          <Box className="dashboard_chart_head">
                            <Typography component="h3">
                              {data.name.toUpperCase()}
                              <Box className="dashboard_chart_head_btn">
                                <Tooltip
                                  title="Refresh"
                                  onClick={() => refreshChart(data)}
                                >
                                  <IconButton>
                                    <RefreshIcon />
                                  </IconButton>
                                </Tooltip>
                                {data.minimizeChart ? (
                                  <Tooltip
                                    title="Maximize Chart"
                                    onClick={() => handleMaximizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MaximizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Minimize Chart"
                                    onClick={() => handleMinimizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MinimizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Expand Chart">
                                  <IconButton
                                    onClick={() =>
                                      handleOpenChartModal('line', data)
                                    }
                                  >
                                    <img src="/images/expand_icon.svg" alt="" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          </Box>
                          <Box
                            className={
                              data.minimizeChart
                                ? 'chart_con hide'
                                : 'chart_con'
                            }
                          >
                            <LineChart configData={data.data} type="line" />
                          </Box>
                        </div>
                      </Grid>
                    )}
                    {data.chart === 'pie_chart' && (
                      <Grid item lg={6} md={12}>
                        <div className="dashboard_chart_con">
                          <Box className="dashboard_chart_head">
                            <Typography component="h3">
                              {data.name.toUpperCase()}
                              <Box className="dashboard_chart_head_btn">
                                <Tooltip
                                  title="Refresh"
                                  onClick={() => refreshChart(data)}
                                >
                                  <IconButton>
                                    <RefreshIcon />
                                  </IconButton>
                                </Tooltip>
                                {data.minimizeChart ? (
                                  <Tooltip
                                    title="Maximize Chart"
                                    onClick={() => handleMaximizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MaximizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Minimize Chart"
                                    onClick={() => handleMinimizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MinimizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Expand Chart">
                                  <IconButton
                                    onClick={() =>
                                      handleOpenChartModal('pie', data)
                                    }
                                  >
                                    <img src="/images/expand_icon.svg" alt="" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          </Box>
                          <Box
                            className={
                              data.minimizeChart
                                ? 'chart_con hide'
                                : 'chart_con'
                            }
                          >
                            <PieChart configData={data.data} type="pie" />
                          </Box>
                        </div>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </Grid>
          {/* <Box className="dashboard_camera_pic">
            <Box>
              <Typography component="h3">Camera pictures</Typography>
              <img src="/images/cam_pic.png" alt="" />
            </Box>
            <Box>
              <Typography component="h3">Camera paths</Typography>
              <img src="/images/cam_pic.png" alt="" />
            </Box>
          </Box> */}
        </Box>
        <Box className="dashboard_right">
          <Typography component="h3">Alerts</Typography>
          <Box component="ul">
            {alarmList.map((data, index) => (
              <Typography component="li" key={index} onClick={() => alertDetails(data)} className="critical_alert_li">
                Alert in {data.analytic_type} at {data.camera.camera_name} on {formatTimestamp(data.timestamp)}
              </Typography>
            ))}
            {criticalAlertsList.map((data, index) => (
              <Typography component="li" key={index} onClick={() => alertDetails(data)} className="critical_alert_li">
                Alert in {data.analytic_type} at {data.camera.camera_name} on {formatTimestamp(data.timestamp)}
              </Typography>
            ))}
            {alarmList.length === 0 && criticalAlertsList.length === 0 && (
              <Typography component="li">No alerts</Typography>
            )}

          </Box>
        </Box>
        <ChartModal
          open={openChartModal}
          onClose={() => {
            setOpenChartModal(false);
            setSelectedChartData({});
          }}
          data={selectedChartData.data?.data}
          type={selectedChartData.type}
          title={selectedChartData.data?.name.toUpperCase()}
          sx={{ minWidth: '1000px !important' }}
        >
          <Box>chart here</Box>
        </ChartModal>
      </Box>
    </DashboardStyle>
  );
};

export default DashboardView;
