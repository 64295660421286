import React, { useEffect, useState } from 'react';
import CriticalAlertSettingsForm from './CriticalAlertSettingsForm';
import { Box, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '../../../Layouts/style';
import AnalyticsService from '../../../RestSevices/analyticsService';
import CameraService from '../../../RestSevices/cameraService';
import { createCriticalAlertSetting, deleteCriticalAlertSetting, updateCriticalAlertSetting } from '../../../graphQL/Dashboard/mutation';
import { getAllCriticalAlertSettings } from '../../../graphQL/Dashboard/query';
import DeleteDialog from '../../../Components/deleteDialog';
export default function CriticalAlertSettings() {
  const [analyticsList, setAnalyticsList] = useState([]);
  const [settingsList, setSettingsList] = useState([]);
  const [availableAnalyticsList, setAvailableAnalyticsList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [settingsId, setSettingsId] = useState(false);
  const [forms, setForms] = useState([
    {
      id: '',
      analytics_id: '',
      camera_ids: []
    }
  ]);


  const fetchAnalyticsList = () => {
    AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setAnalyticsList(resp.data || []);
        setAvailableAnalyticsList(resp.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchSettingsList = () => {
    getAllCriticalAlertSettings()
      .then((resp) => {
        setSettingsList(resp.data.getAllCriticalAlertSettings.criticalAlertSettings || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    fetchAnalyticsList();
    fetchSettingsList();
  }, []);


  useEffect(() => {
    const newArr = settingsList.map((settings) => {
      return { id: settings.id, analytics_id: settings.analytics_id, camera_ids: settings.camera_id.split(",") }
    });
    setForms(newArr.length ? newArr : [{
      id: '',
      analytics_id: '',
      camera_ids: []
    }]);
  }, [settingsList]);
  const handleAnalyticsChange = (id, value) => {
    setForms(forms.map(form => form.id === id ? { ...form, analytics: value } : form));
  };
  const handleSubmit = (values, { resetForm }, ind) => {
    if (values.id) {
      return updateCriticalAlertSetting(values)
        .then((resp) => {
          console.log(resp, "resp")
          // Handle successful response if needed
        })
        .catch((err) => {
          console.log(err);
          throw err; // Ensure the error is propagated
        });
    } else {
      return createCriticalAlertSetting({
        analytics_id: values.analytics_id,
        camera_ids: values.camera_ids
      })
        .then((resp) => {
          if (resp.data?.createCriticalAlertSetting?.success) {
            setForms(
              forms.map((form, index) => {
                if (index === ind) {
                  return {
                    ...form,
                    id: resp.data?.createCriticalAlertSetting?.data?.id,
                    analytics_id: values.analytics_id,
                    camera_ids: values.camera_ids
                  };
                }
                return form;
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          throw err; // Ensure the error is propagated
        });
    }
  };


  const handleAddMore = () => {
    setForms([...forms, { id: "", analytics_id: '', camera_ids: [] }]);
  };


  const handleDeleteConfirm = (id) => {
    setIsOpen(true);
    setSettingsId(id);
  };


  const handleDelete = () => {
    deleteCriticalAlertSetting(settingsId)
      .then((resp) => {
        setForms(forms.filter((form) => form.id !== settingsId));
        setSettingsId(false);
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setIsOpen(false);
    setSettingsId(false);
  };

  const checkIfDisabled = () => {
    return forms.some((form) => form.id === '');
  };

  return (
    <Box className="space-y-4">
      {forms.map(({ id, component }, index) => (
        <Card sx={{ border: '0 !important', mb: '1rem' }} key={id}>
          <CardHeader title={<Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography>Settings #{index + 1}</Typography>
          </Box>} />
          <CardContent>
            <CriticalAlertSettingsForm form={forms[index]} forms={forms} index={index} analyticsList={analyticsList} handleSubmit={handleSubmit} handleDeleteConfirm={handleDeleteConfirm} />
          </CardContent>
        </Card>
      ))}

      <DeleteDialog
        title={'Delete settings?'}
        message={'Are you sure you want to delete this settings?'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={handleClose}
        onDelete={handleDelete}
      />

      <Button
        variant="contained"
        onClick={handleAddMore}
        className={`mt-4 ${checkIfDisabled()? "disabled" : ""}`}
        disabled={checkIfDisabled()}
      >
        Add More +
      </Button>
    </Box>
  );
}