import { common } from '@mui/material/colors';
import { padding } from '@mui/system';

const Styles = (theme) => {
  return {
    html: {
      fontSize: '14px', // Root font size is 16px
      '@media(min-width: 1921px)': {
        fontSize: '18px', // 20px converted to rem
      },
    },

    'html,body': {
      padding: 0,
      margin: 0,
      boxSizing: 'border-box',
    },

    '*': {
      boxSizing: 'border-box',
    },

    body: {
      color: theme.colors.invertColor.color1,
      fontFamily: `'Mulish', sans-serif`,
      fontSize: '1rem',

      '.alert_modal': {
        bottom: 'inherit',
        left: 'inherit',

        '.MuiModal-backdrop': {
          bottom: 'inherit',
          left: 'inherit',
        }
      }
    },


    '.MuiInputBase-root': {
      overflow: 'hidden',

      '.MuiSelect-select::after': {
        content: `''`,
        position: 'absolute',
        top: '1px',
        right: '0',
        width: '2rem',
        height: 'calc(100% - 2px)',
        borderRadius: 'inherit',
        backgroundColor: theme.colors.color.color6
      },

      '&.invert-border .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.colors.invertColor.color2
      }
    },



    '.color-primary-hover:hover': {
      color: `${theme.colors?.palatte?.primary} !important`,
    },

    '.bg-primary-hover:hover': {
      backgroundColor: `${theme.colors?.palatte?.primary} !important`,
      color: `${theme?.palette?.common?.white} !important`,
    },

    '::-webkit-scrollbar': {
      width: '0.75rem', // 12px converted to rem
    },

    '::-webkit-scrollbar-thumb': {
      background: theme.colors.color.color6,
      borderRadius: '0.9375rem', // 15px converted to rem
      border: '0.00625rem solid #606060', // 0.1px converted to rem
    },

    '::-webkit-scrollbar-track': {
      borderRadius: '0.625rem', // 10px converted to rem
      background: theme.colors.invertColor.color3,
    },

    '.table_head': {
      background: theme.colors.color.color7,
      '& th': {
        padding: '1.25rem', // 20px converted to rem
        borderRight: 'solid 0.0625rem', // 1px converted to rem
        borderRightColor: theme.colors.invertColor.color3,
        borderBottom: 'none',
        fontSize: '1.125rem', // 18px converted to rem
        fontWeight: 700,
        cursor: 'initial',
        background: theme.colors.color.color6,
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    '.datatable_common': {
      '& tr': {
        background: theme.colors.color.color7,
        '&:nth-of-type(1)': {
          '& td': {
            borderTop: 'solid 0.9375rem', // 15px converted to rem
            borderTopColor: theme.colors.color.color3,
          },
        },
        '& td': {
          padding: '0.8rem 1.2rem', // 20px converted to rem
          textAlign: 'left',
          borderRight: 'solid 0.0625rem', // 1px converted to rem
          borderTop: 'solid 0.0625rem', // 1px converted to rem
          borderBottom: 'none',
          fontSize: '1.125rem', // 18px converted to rem
          borderColor: theme.colors.color.color16,
          cursor: 'initial',
          '&:last-child': {
            borderRight: 'none',
          },
          '.task_name': {
            display: 'flex',
            alignItems: 'center',
            gap: '0.625rem', // 10px converted to rem
          },
          '.active_data': {
            background: theme.colors.activeBgColor,
            color: theme.colors.activeTextColor,
            padding: '0.375rem 0.625rem', // 6px 10px converted to rem
          },
          '.action_icon': {
            display: 'flex',
            alignItems: 'center',
            gap: '0.625rem', // 10px converted to rem
          },
          '.datatable_thumbnail': {
            borderRadius: '0.3125rem', // 5px converted to rem
            position: 'relative',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            
            'button': {
              padding: '0'
            },
            '& img': {
              width: 'auto',
              height: '50px', // 54px converted to rem
              // objectFit: 'cover',
            },
            '.play_con': {
              position: 'absolute',
              top: '15%',
              left: '50%',
              transform: 'translate(-50%)',
            },
          },
        },
      },
      '.selected_row': {
        background: theme.colors.color.color9,
      }
    },
    '.profile_toggle': {
      '& a': {
        width: '100%',
        color: theme.colors.invertColor.color1,
        textDecoration: 'none',
      },
      '.MuiPaper-elevation': {
        right: '1.875rem !important', // 30px converted to rem
        top: '4.375rem !important', // 70px converted to rem
        width: '11.25rem !important', // 180px converted to rem
        background: theme.colors.color.color6,
        '& li': {
          fontSize: '1rem', // 16px converted to rem
        },
      },
    },
    '.apply_box_menu': {
      '& a': {
        width: '100%',
        color: theme.colors.invertColor.color1,
        textDecoration: 'none',
      },
      '.MuiPaper-elevation': {
        right: '1.875rem !important', // 30px converted to rem
        width: '11.25rem !important', // 180px converted to rem
        background: theme.colors.color.color13,
        padding: '1.875rem 0.3125rem', // 30px 5px converted to rem
        '& li': {
          fontSize: '1rem', // 16px converted to rem
          padding: 0,
          '& a': {
            padding: '0.625rem 1.25rem', // 10px 20px converted to rem
            borderRadius: '0.3125rem', // 5px converted to rem
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& svg': {
              width: '0.875rem', // 14px converted to rem
            },
            '&:hover': {
              background: theme.colors.color.color6,
            },
          },
        },
      },
    },
    '.MuiPagination-root': {
      display: 'flex',
      justifyContent: 'end',
      padding: '1.5625rem 0', // 25px converted to rem
      '& ul': {
        '& li': {
          '.Mui-selected': {
            color: theme.colors.color.color3,
            background: theme.colors.invertColor.color1,
          },
        },
      },
    },
    '.MuiBackdrop-root': {
      backgroundColor: '#0000000d !important',
    },

    '.MuiAvatarGroup-root .MuiAvatar-root': {
      border: 'none !important',
      margin: '0 0.5rem 0 0 !important',
      minWidth: '2.625rem',
      width: 'auto',
      borderRadius: '3.125rem',
      background: theme.colors.color.color3,
      padding: '0.3125rem 0.9375rem',
      '&.last': {
        background: '#1651EB',
      },
    },
    '.MuiButtonBase-root-MuiCheckbox-root': {
      color: `${theme.colors.invertColor.color1} !important`,
    },
    '.text_truncate': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.disabled': {
      backgroundColor: 'grey !important',
      cursor: 'not-allowed',
    },
    '.tab_area': {
      marginLeft: '-1.5rem',
      width: 'calc(100% + 3rem)',
      paddingBottom: '2.5rem',
      '.MuiTabs-indicator': {
        display: 'none',
      },
      '.MuiTabs-flexContainer': {
        padding: '0 0 0 1.5rem',
        justifyContent: 'end',
      },
      '& td': {
        maxWidth: '12.5rem',
        wordWrap: 'break-word',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '.tab_btn': {
        border: `solid 0.0625rem ${theme.colors.invertColor.color1}`,
        color: theme.colors.invertColor.color1,
        minWidth: '3.75rem',
        height: '3.125rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '3.125rem',
        '&.sort_tab_btn': {
          '& svg': {
            marginTop: '0.625rem',
          },
        },
        '& svg': {
          filter: 'grayscale(1) invert(1)',
        },
        '&.Mui-selected': {
          color: theme.colors.color.color18,
          background: theme.colors.invertColor.color1,
          '& svg': {
            filter: 'inherit',
          },
        },
      },
    },
    '.permission_box': {
      '& table': {
        '& th': {
          whiteSpace: 'nowrap',
          '.MuiCheckbox-root': {
            paddingLeft: 0,
          },
        },
        '& td': {
          maxWidth: '15rem',
        },
      },
    },
    '.camera_datatable': {
      '& table': {
        minWidth: '112.5rem',
      },
    },
    'label.Mui-focused': {
      width: 'auto !important',
      background: 'none !important',
    },
    'label.MuiFormLabel-filled': {
      width: 'auto !important',
      background: 'none !important',
    },
    '.image-gallery-container': {
      display: 'flex',
      alignItems: 'center', /* Vertically center the buttons and image */
      justifyContent: 'center'
    },

    '.prev-btn,.next-btn': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      background: 'transparent',
      border: 'none',
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      cursor: 'pointer',
      padding: '0',
      background: "#1f311e",
      border: "2px solid #fff",

      'svg': {
        width: "1rem",
        height: "auto",
        fill: "#fff"
      }
    },
    '.prev-btn': {
      left: '0.625rem',
    },
    '.next-btn': {
      right: '0.625rem',
    },

    '.image-container': {
      flex: 1 /* Take up remaining space */,
      display: 'flex',
      justifyContent: 'center' /* Horizontally center the image */,
    },

    '.select': {
      position: 'relative',
      width: '100%',
      height: '3.125rem',
      border: '0.125rem solid',
      borderColor: theme.colors.color.color14,
      background: theme.colors.color.color14,
      borderRadius: '0.5rem !important',

      svg: {
        color: theme.colors.invertColor.color1,
      },
    },
  };

};

export default Styles;
