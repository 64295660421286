export const calculateHeight = (width, aspectRatio) => {
  return width / aspectRatio;
};


export const  formatDate = (isoString) => {
  const date = new Date(isoString);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const month = monthsOfYear[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${dayOfWeek} ${month} ${String(day).padStart(2, '0')} ${year}`;
}