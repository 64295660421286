import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tabs,
  Tab,
  Stack,
  Pagination,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { toast } from 'react-toastify';
import { ApplicationStyle } from '../../../Layouts/style';
import {
  DropAnalyticsIcon,
  DropConfigureIcon,
  DropDeleteIcon,
  DropEditIcon,
  DropPreviewIcon,
  DropStopIcon,
  GridIcon,
  ListIcon,
  VerticalDotIcon,
  SearchIcon,
  CloseIcon,
  AscSortIcon,
  DescSortIcon,
} from '../../../Icons/SVGContainer';
import BaseModal from '../../../Components/Modal/BaseModal';
import EditCameraForm from '../../../Components/Form/EditCameraForm';
import CameraDetails from './CameraDetails';
import CameraService from '../../../RestSevices/cameraService';
import ApplicationService from '../../../RestSevices/applicationService';
import AnalyticsService from '../../../RestSevices/analyticsService';
import CameraListDataTable from '../../../Components/DataTable/CameraListDatatable';
import {
  checkAddPermission,
  checkHasObjPermission,
} from '../../../Utils/checkPermission';
import CameraAnalytics from './CameraAnalytics';
import useAuth from '../../../Redux/Actions/authActions';
import { useNavigate } from 'react-router-dom';
import CameraPreviewModal from '../../../modals/CameraPreviewModal';

function DeleteUserDialog({ deleteIsOpen, deleteOnClose, onDelete }) {
  return (
    <Dialog
      open={deleteIsOpen}
      onClose={deleteOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Delete Camera?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this camera?
          <br />
          *** Deleting Camera would also delete all the zones associated with
          it!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteOnClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function sortProps(index) {
  return {
    id: `sort-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CameraListView = () => {
  const [applyDropdown, setApplyDropdown] = React.useState(null);
  const [clickedCamera, setClickedCamera] = useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [cameraDetailsModalOpen, setCameraDetailsModalOpen] =
    React.useState(false);
  const [cameraList, setCameraList] = useState([]);
  const [cameraForEdit, setCameraForEdit] = useState({});
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState('one');
  const [sortValue, setSortValue] = React.useState('asc');
  const [cameraPreviewModalOpen, setCameraPreviewModalOpen] = useState(false);
  const [cameraAnalyticModalOpen, setCameraAnalyticModalOpen] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [analyticsFilter, setAnalyticsFilter] = useState(null);
  const [applicationFilter, setApplicationFilter] = useState(null);
  const [applicationList, setApplicationList] = useState([]);
  const [analyticsList, setAnalyticsList] = useState([]);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleOptionClick = (event, cameraHashId) => {
    setApplyDropdown(cameraHashId);
    setClickedCamera(cameraHashId);
  };
  const handleClose = () => {
    setApplyDropdown(null);
  };

  const fetchApplicationList = async () => {
    try {
      const cameras = await ApplicationService.getAllApplicationsList();
      setApplicationList(cameras?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAnalyticsList = async () => {
    try {
      const analytics = await AnalyticsService.getAllAnalyticsList();
      setAnalyticsList(analytics?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchApplicationList();
    fetchAnalyticsList();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        applyDropdown &&
        event.target.closest('.applydropbox') === null &&
        event.target.closest('.application_menu') === null
      ) {
        setApplyDropdown(null);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [applyDropdown]);

  const fetchData = () => {
    setLoading(true);
    CameraService.getCameraList(
      page,
      searchTerm,
      sortValue,
      analyticsFilter,
      applicationFilter
    )
      .then((response) => {
        setCameraList(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  useEffect(() => {
    fetchData();
  }, [page, searchTerm, sortValue]);

  const handleOpenForm = () => {
    setCameraForEdit({});
    setModalOpen(true);
    handleClose();
  };

  const handleEdit = (hash_id) => {
    const selectedCamera = cameraList.find(
      (camera) => camera.hash_id === hash_id
    );
    setCameraForEdit(selectedCamera);
    setModalOpen(true);
    handleClose();
  };

  const handleConfigure = (hash_id) => {
    navigate(`/zone-configuration/${hash_id}`);
  };

  const handleDetailsForm = (hash_id) => {
    const selectedCamera = cameraList.find(
      (camera) => camera.hash_id === hash_id
    );
    setCameraForEdit(selectedCamera);
    setCameraDetailsModalOpen(true);
    handleClose();
  };

  const handleDeleteConfirm = () => {
    setDeleteIsOpen(true);
    handleClose();
  };

  const handleDelete = async () => {
    try {
      await CameraService.deleteCamera(clickedCamera)
        .then(() => {
          setDeleteIsOpen(false);
          toast.success('Camera deleted successfully!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchData();
        })
        .catch((error) => {
          if (error.response?.data?.error === 'Authentication failed') {
            logout();
            toast.error('Session Expired!', {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (
            error.response?.data?.message.includes('There are associated zones')
          ) {
            setDeleteIsOpen(false);
            toast.error(error.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (err) {
      console.log(err.message);
      toast.error('Error in deleting camera', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSort = (event, newValue) => {
    setSortValue(newValue);
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const clearSearchTerm = () => {
    const clearedSearchTerm = '';
    setSearchTerm(clearedSearchTerm);
  };
  const handlePreviewChange = () => {
    setCameraPreviewModalOpen(!cameraPreviewModalOpen);
  };
  const handleAnalyticChange = () => {
    setCameraAnalyticModalOpen(!cameraAnalyticModalOpen);
  };

  const handleChangeAnalyticsFilter = (event) => {
    setAnalyticsFilter(event.target.value);
  };
  const handleChangeApplicationFilter = (event) => {
    setApplicationFilter(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, [analyticsFilter, applicationFilter]);

  const columns = [
    'cameraName',
    'cameraId',
    'fps',
    'location',
    'created_by_name',
    'action',
  ];
  const headCells = [
    {
      id: 'cameraName',
      numeric: false,
      disablePadding: true,
      label: 'Camera Name',
    },
    {
      id: 'cameraId',
      numeric: true,
      disablePadding: true,
      label: 'Camera Id',
    },
    {
      id: 'fps',
      numeric: true,
      disablePadding: true,
      label: 'FPS',
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: true,
      label: 'Location',
    },
    {
      id: 'created_by',
      numeric: false,
      disablePadding: true,
      label: 'Created By',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];
  const rows = [
    ...cameraList.map((camera) => {
      return {
        cameraName: camera.camera_name,
        cameraId: camera.camera_id,
        id: camera.id,
        fps: camera.fps,
        location: camera.location,
        ...camera,
      };
    }),
  ];

  return (
    <ApplicationStyle>
      <Box
        className="application_page_heading"
        display="flex"
        justifyContent="space-between"
      >
        <Typography component="h2">Cameras</Typography>
        {checkAddPermission('camera', 'add_camera') && (
          <Button onClick={handleOpenForm}>Add Camera</Button>
        )}
      </Box>
      <Box className="tab_area">
        <Box className="tab_toggle_area">
          <Box className="page_search">
            <TextField
              type="text"
              placeholder="Searching for....."
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: searchTerm && (
                  <IconButton onClick={clearSearchTerm}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Box>
              <FormControl sx={{ minWidth: '10rem', mr: '1rem' }}>
                <InputLabel id="analytics-label">Analytics</InputLabel>
                <Select
                  labelId="analytics-label"
                  id="analytics"
                  value={analyticsFilter}
                  label="Analytics"
                  onChange={handleChangeAnalyticsFilter}
                >
                  {analyticsList &&
                    analyticsList.map((analytics) => (
                      <MenuItem value={analytics.analytic_id}>
                        {analytics.analytic_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl sx={{ minWidth: '10rem', mr: '1rem' }}>
                <InputLabel id="application-label">Application</InputLabel>
                <Select
                  labelId="application-label"
                  id="application"
                  value={applicationFilter}
                  label="Application"
                  onChange={handleChangeApplicationFilter}
                >
                  {applicationList &&
                    applicationList.map((app) => (
                      <MenuItem value={app.id}>{app.application_name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Button
              sx={{ height: '3.2rem' }}
              onClick={() => {
                setAnalyticsFilter(null);
                setApplicationFilter(null);
              }}
            >
              Reset
            </Button>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Tabs
              value={sortValue}
              onChange={handleChangeSort}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="asc"
                label={<AscSortIcon />}
                {...sortProps('asc')}
                className="tab_btn sort_tab_btn"
              />
              <Tab
                value="desc"
                label={<DescSortIcon />}
                {...sortProps('desc')}
                className="tab_btn sort_tab_btn"
              />
            </Tabs>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="one"
                label={<GridIcon />}
                {...a11yProps('one')}
                className="tab_btn"
              />
              <Tab
                value="two"
                label={<ListIcon />}
                {...a11yProps('two')}
                className="tab_btn"
              />
            </Tabs>
          </Box>
        </Box>
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <>
            {cameraList.length === 0 ? (
              <Typography
                variant="h4"
                component="div"
                style={{ textAlign: 'center' }}
              >
                No camera found
              </Typography>
            ) : (
              <>
                <TabPanel value={value} index="one">
                  <Box className="apply_box_area">
                    {cameraList.map((camera, index) => (
                      <Box className="apply_box" key={camera.hash_id}>
                        <Box className="apply_box_heading">
                          <Typography component="h4">Camera name</Typography>
                          <Typography component="h3" className="text_truncate">
                            {camera.camera_name}
                          </Typography>
                          <IconButton
                            className="application_menu"
                            onClick={(event) =>
                              handleOptionClick(event, camera.hash_id)
                            }
                          >
                            <VerticalDotIcon />
                          </IconButton>
                          <Box
                            className={
                              applyDropdown === camera.hash_id
                                ? 'applydropbox show'
                                : 'applydropbox'
                            }
                          >
                            {checkHasObjPermission(
                              camera,
                              'camera',
                              'view_camera'
                            ) && (
                              <Button
                                onClick={() =>
                                  handleDetailsForm(camera.hash_id)
                                }
                              >
                                Details <DropStopIcon />
                              </Button>
                            )}
                            {checkHasObjPermission(
                              camera,
                              'camera',
                              'edit_camera'
                            ) && (
                              <Button
                                onClick={() => handleEdit(camera.hash_id)}
                              >
                                Edit <DropEditIcon />
                              </Button>
                            )}
                            {(checkHasObjPermission(
                              camera,
                              'camera',
                              'create_zone'
                            ) ||
                              checkHasObjPermission(
                                camera,
                                'camera',
                                'edit_zone'
                              ) ||
                              checkHasObjPermission(
                                camera,
                                'camera',
                                'delete_zone'
                              )) && (
                              <Button
                                onClick={() => handleConfigure(camera.hash_id)}
                              >
                                Configure <DropConfigureIcon />
                              </Button>
                            )}
                            <Button onClick={handleAnalyticChange}>
                              Analytics <DropAnalyticsIcon />
                            </Button>
                            <Button
                              onClick={() => {
                                setSelectedCamera(camera);
                                handlePreviewChange();
                              }}
                              // disabled={true}
                            >
                               Preview <DropPreviewIcon />
                            </Button>
                            {checkHasObjPermission(
                              camera,
                              'camera',
                              'delete_camera'
                            ) && (
                              <Button onClick={handleDeleteConfirm}>
                                Delete <DropDeleteIcon />
                              </Button>
                            )}
                          </Box>
                        </Box>
                        <Box className="apply_box_info">
                          <Box className="apply_box_info_con">
                            <Typography component="h4">Camera id</Typography>
                            <Typography
                              component="h3"
                              className="text_truncate"
                            >
                              {camera.camera_id}
                            </Typography>
                          </Box>
                          <Box className="apply_box_info_con">
                            <Typography component="h4">FPS</Typography>
                            <Typography
                              component="h3"
                              className="text_truncate"
                            >
                              {camera.fps}
                            </Typography>
                          </Box>
                          <Box className="apply_box_info_con">
                            <Typography component="h4">location</Typography>
                            <Typography
                              component="h3"
                              className="text_truncate"
                            >
                              {camera.location}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </TabPanel>
                <TabPanel value={value} index="two">
                  <CameraListDataTable
                    rows={rows}
                    headCells={headCells}
                    columns={columns}
                    handleEdit={handleEdit}
                    handleDetailsForm={handleDetailsForm}
                    setClickedCamera={setClickedCamera}
                    handleConfigure={handleConfigure}
                    handleAnalyticChange={handleAnalyticChange}
                    handleClose={handleClose}
                    handleDeleteConfirm={handleDeleteConfirm}
                    handlePreviewChange={handlePreviewChange}
                  />
                </TabPanel>
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </>
            )}
          </>
        )}
      </Box>
      <BaseModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={cameraForEdit?.hash_id ? 'Edit Camera' : 'Add Camera'}
      >
        <EditCameraForm
          onClose={() => setModalOpen(false)}
          cameraForEdit={cameraForEdit}
          fetchData={fetchData}
        />
      </BaseModal>
      <BaseModal
        open={cameraDetailsModalOpen}
        onClose={() => setCameraDetailsModalOpen(false)}
        title={`Camera details`}
      >
        <CameraDetails
          onClose={() => setCameraDetailsModalOpen(false)}
          camera={cameraForEdit}
        />
      </BaseModal>
      <DeleteUserDialog
        deleteIsOpen={deleteIsOpen}
        deleteOnClose={() => setDeleteIsOpen(false)}
        onDelete={handleDelete}
      />
      {/* <BaseModal
        open={cameraPreviewModalOpen}
        onClose={() => setCameraPreviewModalOpen(false)}
        title={`Camera Preview`}
      >
        <CameraPreview onClose={() => setCameraPreviewModalOpen(false)} />
      </BaseModal> */}
       <CameraPreviewModal
        camera={selectedCamera}
        cameraPreviewModalOpen={cameraPreviewModalOpen}
        setCameraPreviewModalOpen={setCameraPreviewModalOpen}
      />
      <BaseModal
        open={cameraAnalyticModalOpen}
        onClose={() => setCameraAnalyticModalOpen(false)}
        title={`Analytics`}
      >
        <CameraAnalytics onClose={() => setCameraAnalyticModalOpen(false)} />
      </BaseModal>
    </ApplicationStyle>
  );
};

export default CameraListView;
