import React, { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import {
  Box,
  TextField,
  Typography,
  Button,
  Autocomplete,
  Switch,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { TableBox } from './style';
import { DatatableHeadingStyle } from '../../../Layouts/style';
import { ResetIcon, SearchIcon } from '../../../Icons/SVGContainer';

const AnalyticsClassSelectionDropdown = ({
  analytics,
  handleAnalyticsSelection,
  analyticsSelection,
  setSelectedClasses,
  selectedClasses,
}) => {
  const [classesOptions, setClassesOptions] = useState([]);

  // Update classes dropdown options based on selected analytics
  useEffect(() => {
    if (analyticsSelection) {
      const selectedAnalytic = analytics.find(
        (analytic) => analytic.analytic_name === analyticsSelection
      );
      if (selectedAnalytic) {
        console.log('selectedAnalytic', selectedAnalytic);
        const classField = selectedAnalytic.analyticfields.find(
          (field) => field.field_name === 'frontend_classes'
        );
        if (classField) {
          setClassesOptions(classField.field_value);
        } else {
          setClassesOptions([]);
        }
      }
    } else {
      setClassesOptions([]);
    }
  }, [analyticsSelection, analytics]);
  // Icons for checkboxes
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <>
      <Autocomplete
        options={analytics.map((analytic) => analytic.analytic_name)}
        value={analyticsSelection}
        onChange={(event, value) => handleAnalyticsSelection(event, value)}
        renderInput={(params) => (
          <TextField {...params} label="Select Analytics" variant="outlined" />
        )}
      />
      <Autocomplete
        multiple
        options={classesOptions}
        value={selectedClasses}
        onChange={(event, value) => setSelectedClasses(value)}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Select Classes" variant="outlined" />
        )}
        disabled={!analyticsSelection}
      />
    </>
  );
};

const AlertsHeading = ({
  analytics,
  handleAnalyticsSelection,
  analyticsSelection,
  fromDate,
  toDate,
  handleFromDateChange,
  handleToDateChange,
  serachTerm,
  handleSearch,
  resetFilter,
  handleOnClickSearch,
  camerasByAnalytics,
  cameraSelection,
  setCameraSelection,
  setSelectedClasses,
  selectedClasses,
  autoRefreshStatus,
  setAutoRefreshStatus,
}) => {
  useEffect(() => {
    if (!analyticsSelection) {
      setCameraSelection([]);
    }
  }, [analyticsSelection]);

  const handleCameraSearchChange = (value) => {
    setCameraSelection(value);
  };
  console.log('analyticsSelection', analyticsSelection);
  return (
    <>
      <DatatableHeadingStyle>
        <Box className="user_heading business_heading">
          <Typography component="h2">
            {`Alerts: ${analyticsSelection}` || 'Alerts'}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={autoRefreshStatus}
                onChange={(e) => setAutoRefreshStatus(e.target.checked)}
                name="auto_refresh"
              />
            }
            label="Auto Refresh"
          />
        </Box>
        <TableBox>
          <Box className="object_table_head_left">
            <AnalyticsClassSelectionDropdown
              analytics={analytics}
              handleAnalyticsSelection={handleAnalyticsSelection}
              analyticsSelection={analyticsSelection}
              selectedClasses={selectedClasses}
              setSelectedClasses={setSelectedClasses}
            />
            <Autocomplete
              multiple
              value={cameraSelection}
              defaultValue={null}
              onChange={(event, value) => handleCameraSearchChange(value)}
              options={camerasByAnalytics.map((item) => item.camera_name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Camera"
                  variant="outlined"
                />
              )}
              disabled={!analyticsSelection}
            />
            <Box className="res_from_date_picker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    label="From Date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    disabled={!analyticsSelection}
                    disableFuture
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box className="res_to_date_picker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    label="To Date"
                    value={toDate}
                    onChange={handleToDateChange}
                    minDate={fromDate ? dayjs(fromDate) : null}
                    disabled={!fromDate}
                    disableFuture
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Box>
          <Box className="object_table_head_right">
            <TextField
              className="search"
              type="text"
              placeholder="Searching for....."
              value={serachTerm}
              onChange={handleSearch}
              onClick={handleOnClickSearch}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              disabled={!analyticsSelection}
            />
            <Box className="from_date_picker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    label="From Date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    disabled={!analyticsSelection}
                    disableFuture
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>

            <Box className="to_date_picker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    label="To Date"
                    value={toDate}
                    onChange={handleToDateChange}
                    minDate={fromDate ? dayjs(fromDate) : null}
                    disabled={!fromDate}
                    disableFuture
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>

            <Button
              onClick={resetFilter}
              disabled={
                !fromDate &&
                !toDate &&
                !serachTerm &&
                !cameraSelection.length &&
                !selectedClasses.length
              }
            >
              <ResetIcon />
            </Button>
          </Box>
        </TableBox>
      </DatatableHeadingStyle>
    </>
  );
};

export default AlertsHeading;
