import { styled } from '@mui/material';
import { Box } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

export const TableBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '25px',
    flexWrap: 'wrap',

    '.object_table_head_left': {
        width: 'max-content',

        '.alert_search_classes': {
            '.MuiFormControl-root': {
                '.MuiInputBase-root': {
                    '.MuiInputAdornment-root': {
                        marginLeft: 'auto',
                    }
                }
            },

            '.dropdown': {
                position: 'absolute',
                top: '100%',
                left: '0',
                zIndex: '1',
                width: '100%',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                background: theme.colors.color.color6,
                borderRadius: '0.8rem',

                'ul': {
                    position: 'relative',
                    width: '100%',
                    padding: '0.6rem',
                    display: 'flex',
                    flexDirection: 'column',

                    'li': {
                        position: 'relative',
                        width: '100%',

                        '.MuiTreeItem-content': {
                            padding: '0.3rem 0.5rem',
                            borderRadius: '0.4rem'
                        },

                        'ul': {
                            marginLeft: '0',
                            maxHeight: '200px',
                            overflowY: 'auto',

                            'li': {
                                '.MuiTreeItem-label': {
                                    'span': {
                                        padding: '6px',
                                        marginRight: '4px'
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },

        '.MuiAutocomplete-root': {
            '.MuiInputBase-root':{
                padding: '6px',

                '.MuiInputAdornment-root': {
                    marginLeft: 'auto'
                }
            }
        }
    },

    '.object_table_head_right': {
        width: 'max-content',

        '.MuiStack-root': {
            paddingTop: '0',
        },

        'button': {
            height: '3.5625rem',
            padding: '6px 20px',
        }
    },

    '@media (max-width: 1440px)': {
        '.object_table_head_right': {
            width: '100%',
            marginTop: '5px',
            flexWrap: 'wrap',

            '.MuiTextField-root.search': {
                width: '25rem',
                marginRight: 'auto'
            }
        },
    },

    '@media (max-width: 1250px)': {
        '.object_table_head_right': {

            '.MuiTextField-root.search': {
                width: '100%',
            }
        },
    },

    '@media (max-width: 850px)': {
        '.object_table_head_right': {
            justifyContent: 'space-between',

            '.MuiStack-root ': {
                width: '48.5%',

                '.MuiFormControl-root': {
                    minWidth: '0'
                },
                'input': {
                    width: '100%'
                }
            },

            'button': {
                marginTop: '10px',
                marginLeft: 'auto'
            }
        },
    },

    '@media (max-width: 575px)': {
        '.object_table_head_right': {

            '.MuiStack-root ': {
                width: '100%',

                'input': {
                    width: '100%'
                }
            }
        },
    }
}))
