import axios from '../Utils/api';

const CSVService = {
  uploadListCSV: (formData) => {
    return axios.post('/csv/upload-list', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type for file upload
      },
      // onUploadProgress: (progressEvent) => {
      //   const percentCompleted = Math.round(
      //     (progressEvent.loaded * 100) / progressEvent.total
      //   );
      //   setUploadProgress(percentCompleted);
      // },
    });
  },
  downloadListCSV: (type) => {
    return axios.get(`/csv/download-list?type=${type}`);
  },
  downloadSampleCSV: (type) => {
    return axios.get(`/csv/download-sample`);
  },
};

export default CSVService;
