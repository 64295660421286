import React, { useRef } from 'react';
import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup'; // Import Yup for form validation
import axios from 'axios';
import CSVService from '../../../RestSevices/CSVService';
import { toast } from 'react-toastify';

function CsvUpload() {
  const [formData, setFormData] = React.useState({
    type: '',
    file: null,
  });
  const [downloadType, setDownloadType] = React.useState("");
  const fileInputRef = useRef();

  const types = [
    { label: 'White List', value: 'whitelist' },
    { label: 'Black List', value: 'blacklist' },
  ];


  const downloadCsv = async (type) => {
    try {
      const response = await CSVService.downloadListCSV(type);
      console.log(response);
      if (response.data && response.data.data) {
        // Convert the base64 string to a Uint8Array
        const base64Data = response.data.data;
        const binaryString = atob(base64Data);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([bytes], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a temporary anchor element and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${type}.xlsx`); // Adjust the filename and extension as needed
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.error('Excel data not found in response');
      }
    } catch (error) { }
  };

  const downloadSampleCsv = async (type) => {
    try {
      const response = await CSVService.downloadSampleCSV();
      if (response.data && response.data.data) {
        // Convert the base64 string to a Uint8Array
        const base64Data = response.data.data;
        const binaryString = atob(base64Data);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([bytes], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a temporary anchor element and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sample.xlsx'); // Set the filename and extension correctly
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.error('Something went wrong, please try again!');
      }
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };


  const handleSubmit = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append('type', values.type);
      formData.append('file', values.file);
      const response = await CSVService.uploadListCSV(formData);
      toast.success(`${response.data.data.inserted_rows} rows inserted out of ${response.data.data.total_rows}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      resetForm();
      fileInputRef.current.value = null; // Reset the form after successful submission
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Formik
      initialValues={formData}
      validationSchema={Yup.object().shape({
        type: Yup.string().required('Type is required'),
        file: Yup.mixed().required('File is required')
          .test('fileFormat', 'Unsupported file format. Only .csv, .xls, and .xlsx files are allowed',
            value => !value || (value && ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'].includes(value.type))
          ),
      })}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} encType="multipart/form-data">
          <Box className="page_heading">
            <Typography component="h3">White List / Black List</Typography>
            <Box className="csv_export">
              <Typography component="label">Export CSV :</Typography>
              <Select
                sx={{ fieldset: { borderColor: 'white' } }}
                name="downloadType"
                value={downloadType}
                onChange={(e) => setDownloadType(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Select</MenuItem>
                {types.map((type) => (
                  <MenuItem
                    value={type.value}
                    key={type.value}
                    onClick={() => downloadCsv(type.value)}
                  >
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {/* <Button onClick={downloadCsv}>Download CSV</Button> */}
          </Box>
          <Box className="form_field_area">
            <Box display="flex" alignItems="center">
              <Typography sx={{ mb: '0 !important', mr: '3rem' }} component="h5">Upload CSV</Typography>
              <Typography sx={{ cursor: 'pointer', fontSize: '0.7rem' }} onClick={() => downloadSampleCsv(props.values.type)}>Download Sample CSV</Typography>

            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con full_width">
                <Typography component="label">Type</Typography>
                <Select
                  sx={{ fieldset: { borderColor: 'white' } }}
                  className='select'
                  name="type"
                  value={props.values.type}
                  onChange={props.handleChange}
                  error={props.touched.type && !!props.errors.type}
                  displayEmpty
                >
                  <MenuItem value="">Select</MenuItem>
                  {types.map((type) => (
                    <MenuItem value={type.value} key={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={props.touched.type && !!props.errors.type}
                >
                  {props.touched.type ? props.errors.type : ''}
                </FormHelperText>
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con full_width">
                <TextField
                  sx={{ fieldset: { borderColor: 'white' } }}
                  inputRef={fileInputRef}
                  name="file"
                  type="file"
                  id="file_input"
                  onChange={(event) => props.setFieldValue('file', event.target.files[0])}
                  error={props.touched.file && Boolean(props.errors.file)}
                  helperText={props.touched.file && props.errors.file}
                  inputProps={{ accept: '.csv, .xls, .xlsx' }}
                />
              </Box>
              <Button type="submit" variant="contained" color="primary">
                Upload
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default CsvUpload;
